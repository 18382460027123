import { Component, Injector, OnInit } from '@angular/core';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DashBoardCards } from 'src/app/models/dashboard-cards';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-dashboard-sidemenu',
  templateUrl: './dashboard-sidemenu.component.html',
  styleUrls: ['./dashboard-sidemenu.component.scss'],
  standalone: true,
  imports:[CommonModule,RouterModule]
})
export class DashboardSidemenuComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  classApplied = false;
  @Select(SnappstayState.GetDashBoardCards)
  dashBoardCards$!: Observable<DashBoardCards[]>;
  dashBoardCards: DashBoardCards[] = [];
  isListingDropdownOpen: boolean = false;
  isUsersDropdownOpen: boolean = false;

  constructor(inject: Injector) {
    super(inject);
  }
  ngOnInit(): void {
    this.dashBoardCards$.subscribe((value) => {
      if (value) {
        this.dashBoardCards = value;
      } else {
        this.dashBoardCards = [];
      }
    });
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();  // Prevents click event from closing the dropdown
    this.isListingDropdownOpen = !this.isListingDropdownOpen;
    this.isUsersDropdownOpen = false;
  }

  toggleUserDropdown(event: Event) {
    event.stopPropagation();  // Prevents click event from closing the dropdown
    this.isUsersDropdownOpen = !this.isUsersDropdownOpen;
    this.isListingDropdownOpen = false;
  }
  getCount(name: string): number {
    return this.dashBoardCards.find((x) => x.name == name).count;
  }


  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  isRouteExactActive(route: string): boolean {
    return this.router.url === route;
  }
}
