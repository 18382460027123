import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TeamComponent } from '../../common/team/team.component';
import { HowItWorksComponent } from '../../common/how-it-works/how-it-works.component';
import { PartnerComponent } from '../../common/partner/partner.component';
import { FeedbackStyleOneComponent } from '../../common/feedback-style-one/feedback-style-one.component';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { FunfactsComponent } from '../../common/funfacts/funfacts.component';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        NavbarStyleTwoComponent,
        FunfactsComponent,
        TeamComponent,
        HowItWorksComponent,
        PartnerComponent,
        FeedbackStyleOneComponent,
        FooterStyleTwoComponent
    ]
})
export class AboutUsComponent implements OnInit {

        

    ngOnInit(): void {
    }
    snappstayEmail = "info@snappstay.com";
    pageTitleContent = [
        {
            title: 'About Us',
            backgroundImage: 'assets/images/about-us.jpeg'
        }
    ]
    aboutContent = [
        {
            title: 'How We Were Established',
            subTitle: 'Check video presentation to find out more about us.',
            paragraph: [
                {
                    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
                },
                {
                    text: "Every month they moved their money the old way – which wasted their time and money. So they invented a beautifully simple workaround that became a billion-dollar business."
                }
            ]
        }
    ]
    aboutImage = [
        {
            img: 'assets/img/about-img.jpg',
            videoLink: 'https://www.youtube.com/watch?v=bk7McNUjWgw'
        }
    ]

}