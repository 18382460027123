import { AsyncPipe, CommonModule } from '@angular/common';

import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';

import { CityName } from 'src/app/models/lookup/city-names';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Select } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { PropertyType } from 'src/app/models/lookup/property-type';
import { Amenity } from 'src/app/models/listing-model/amenity';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OpeningHour } from 'src/app/models/listing-model/openingHour';
import { Location } from 'src/app/models/listing-model/location';
import {
  AmenityDetails,
  CreateListing,
} from 'src/app/models/request-models/creatListing';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { MapComponent } from 'src/app/components/shared/map/map-component/map-component.component';
import { MapMarker } from 'src/app/components/shared/map/map-marker';
import { SinglePropertyResponse } from 'src/app/models/listing-model/single-property.model';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule,
} from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Injector,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-dashboard-add-listings',
  templateUrl: './dashboard-add-listings.component.html',
  styleUrls: ['./dashboard-add-listings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    ReactiveFormsModule,
    AsyncPipe,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    ScrollingModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MapComponent,
    SelectDropDownModule,
    NgSelectModule,
    MatChipsModule,
  ],
})
export class DashboardAddListingsComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  @Select(SnappstayState.PropertyType)
  propertyType$!: Observable<PropertyType[]>;

  @Select(SnappstayState.Amenities)
  amenities$!: Observable<Amenity[]>;
  backendImages: string[] = [];
  listingFormGroup!: FormGroup;
  filteredCities: CityName[] = [];
  amenities: Amenity[] = [];
  isAvailable: boolean = false;
  selectedFiles: File[] = [];
  selectedImageUrls: string[] = [];
  isShowMap: boolean = false;
  markers: MapMarker[] = [];
  bounds: any[] = [];
  isReadOnly: boolean = false;
  isUpdateMapButton: boolean = false;
  singleListing: SinglePropertyResponse;
  config: any = {
    displayKey: 'name',
    search: true,
    height: '300px',
  };
  propertyId: number | undefined;

  get openingHoursDays(): FormArray {
    return this.listingFormGroup.controls['openingHours'] as FormArray;
  }
  weekDays = [
    { name: 'Sunday', id: 0 },
    { name: 'Monday', id: 1 },
    { name: 'Tuesday', id: 2 },
    { name: 'Wednesday', id: 3 },
    { name: 'Thursday', id: 4 },
    { name: 'Friday', id: 5 },
    { name: 'Saturday', id: 6 },
  ];
  @ViewChild('dropdownElement') dropdownElement: ElementRef;
  get openingHours(): FormArray {
    return this.listingFormGroup.controls['openingHours'] as FormArray;
  }
  keywords: any[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(
    inject: Injector,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super(inject);
  }
  ngOnInit(): void {
    this.initFormGroup();
    this.getPropertyTypes();
    this.getAmenity();
    this.cityFormControlSub();
    this.amenitiesSub();
    this.subscribeToRouteParams();
    this.subscribeToSingleListing();
  }

  subscribeToRouteParams(): void {
    const getJobId = this.activatedRoute.paramMap.subscribe((params: any) => {
      if (params.has('id')) {
        this.propertyId = params.params.id;
        this.getPropertyProfileById(this.propertyId).then((x) => {
          this.patchValuesToForm();
        });
      }
    });
    this.subscriptions.push(getJobId);
  }
  subscribeToSingleListing(): void {
    const singleListingSub = this.store
      .select(SnappstayState.SingleListing)
      .subscribe((value) => {
        if (value) {
          this.singleListing = value;
          this.singleListing.amenityDetails.forEach((x) => {
            const foundAmenity = this.amenities.find(
              (amenity) => amenity.id === x.id
            );

            if (foundAmenity) {
              this.amenities.find((amenity) => amenity.id === x.id).isChecked =
                true;
            }
          });
        }
      });
    this.subscriptions.push(singleListingSub);
  }
  patchValuesToForm() {
    const keyWords = this.singleListing.keyWords
      ? this.singleListing.keyWords.split(',').map((word) => word.trim())
      : [];
    if (this.singleListing) {
      this.listingFormGroup.patchValue({
        title: this.singleListing.title,
        description: this.singleListing.description,
        keyWords: this.singleListing.keyWords,
        pricePerNight: this.singleListing.pricePerNight,
        maxGuests: this.singleListing.maxGuests,
        propertyType: this.singleListing.propertyCategory,
        isAvailable: this.singleListing.isAvailable,
        city: this.getCityFromList(this.singleListing.city),
        address: this.singleListing.address,
        zipCode: this.singleListing.zipCode,
        lat: this.singleListing.latitude,
        long: this.singleListing.longitude,
        amenities: this.singleListing.amenityDetails.map((x) => x.amenityId),
        phoneNumber: this.singleListing.phoneNumber,
      });
      if (keyWords && keyWords.length > 0) {
        this.keywords = keyWords;
      }
      this.singleListing.propertyTimingInfo.forEach((timing: OpeningHour) => {
        const foundControl = this.openingHours.controls.find((x) => {
          return x.get('id')?.value === timing.dayOfWeek;
        }) as FormGroup;
        if (foundControl) {
          foundControl.controls['dayOfWeek'].patchValue(timing.dayOfWeek);
          foundControl.controls['openTime'].patchValue(
            this.convertToAmPm(timing.openTime)
          );
          foundControl.controls['closeTime'].patchValue(
            this.convertToAmPm(timing.closeTime)
          );
        }
      });
      this.singleListing.propertyPicture.forEach((image) => {
        this.backendImages.push(`${this.environmentVar.blobUrl}` + image.path);
      });
      const marker: MapMarker = {
        latLng: {
          latitude: this.singleListing.latitude,
          longitude: this.singleListing.longitude,
        },
      };
      this.bounds = [];
      this.markers = [];
      this.bounds.push({
        lat: this.singleListing.latitude,
        lng: this.singleListing.longitude,
      });
      this.markers.push(marker);
    }
  }

  // Remove an image from the backend images array
  removeBackendImage(index: number) {
    this.backendImages.splice(index, 1);
    this.cdr.detectChanges();
  }

  convertToAmPm(time) {
    // Split the time string into hours, minutes, and seconds
    let [hours, minutes] = time.split(':').map(Number);

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12; // 0 or 12 is treated as 12 for 12-hour clock

    // Return the formatted time
    var result = `${hours} ${ampm}`;
    return result;
  }

  getCityFromList(cityName: string) {
    return this.cities.find((x) => x.name == cityName);
  }

  amenitiesSub() {
    const AmenitiesSub = this.amenities$.subscribe((x) => {
      if (x) {
        this.amenities = x;
      } else {
        this.amenities = [];
      }
    });
    this.subscriptions.push(AmenitiesSub);
  }

  onAmenityChange(event: Event, amenity: Amenity) {
    event.preventDefault();
    amenity.isChecked = !amenity.isChecked;
  }
  initFormGroup() {
    this.listingFormGroup = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      keyWords: [null],
      pricePerNight: [null, [Validators.required]],
      maxGuests: [null, [Validators.required]],
      propertyType: [null, [Validators.required]],
      isAvailable: [null, [Validators.required]],
      city: [null, [Validators.required]],
      regionOrState: [{ value: '', disabled: true }, [Validators.required]],
      country: [{ value: '', disabled: true }, [Validators.required]],
      address: [null, [Validators.required]],
      zipCode: [null, [Validators.required]],
      lat: [null, [Validators.required]],
      long: [null, [Validators.required]],
      amenities: [null],
      phoneNumber: [null, [Validators.required]],
      // openingHours: this.fb.array(
      //   this.weekDays.map((day) => this.getopeningHoursFormGroup(day))
      // ),
    });
  }
  getopeningHoursFormGroup(weekDay: any): FormGroup {
    return this.fb.group({
      id: [weekDay.id],
      dayOfWeek: [weekDay.name, [Validators.required]],
      openTime: [null, [Validators.required]],
      closeTime: [null, [Validators.required]],
    });
  }
  closeMap(event: any): void {
    if (event && event.action) {
      this.isShowMap = false;
      this.isUpdateMapButton = true;
    }
  }
  openMap() {
    if (this.isUpdateMapButton) {
      const marker: MapMarker = {
        latLng: {
          latitude: this.listingFormGroup.controls['lat'].value,
          longitude: this.listingFormGroup.controls['long'].value,
        },
      };
      this.bounds = [];
      this.markers = [];
      this.bounds.push({
        lat: this.listingFormGroup.controls['lat'].value,
        lng: this.listingFormGroup.controls['long'].value,
      });
      this.markers.push(marker);
    }
    this.isShowMap = true;
  }

  onMapClick(event: any) {
    console.log('Map clicked at:', event);
    this.listingFormGroup.controls['lat'].patchValue(event.latitude);
    this.listingFormGroup.controls['long'].patchValue(event.longitude);
    console.log('Form value:', this.listingFormGroup);
  }

  getDayName(index: number): string {
    return this.weekDays.find((x) => x.id == index).name;
  }

  getFormGroupName(index: number): string | undefined {
    const formGroup = this.openingHoursDays.controls.find(
      (group: FormGroup) => group.get('id')?.value === index
    );

    if (formGroup) {
      const idx = this.openingHoursDays.controls.indexOf(formGroup);
      return `FormGroup at index ${idx}`;
    }

    return undefined;
  }

  cityFormControlSub() {
    const citySub = this.listingFormGroup.controls[
      'city'
    ].valueChanges.subscribe((value) => {
      if (value) {
        if (typeof value == 'object' && value !== null) {
          const country = this.countries.find(
            (x) => x.isoCode == value.countryCode
          );
          const state = this.states.find(
            (x) =>
              x.isoCode == value.stateCode && x.countryCode == country.isoCode
          );
          const zip = this.getZipCodeByCityAndStateCode(
            value.name,
            state.isoCode
          );
          this.listingFormGroup.controls['country'].patchValue(country.name);
          this.listingFormGroup.controls['regionOrState'].patchValue(
            state.name
          );
          this.listingFormGroup.controls['zipCode'].patchValue(zip);
        }
        this.filteredCities = this._filter(value || '');
      } else {
        this.filteredCities = this.cities;
        this.listingFormGroup.controls['country'].patchValue(null);
        this.listingFormGroup.controls['regionOrState'].patchValue(null);
        this.listingFormGroup.controls['zipCode'].patchValue(null);
      }
    });
    this.subscriptions.push(citySub);
  }
  citySelected(selectedCity: any): void {
    console.log('Selected city:', selectedCity);
    const marker: MapMarker = {
      latLng: {
        latitude: selectedCity.latitude,
        longitude: selectedCity.longitude,
      },
    };
    this.bounds.push({
      lat: selectedCity.latitude,
      lng: selectedCity.longitude,
    });

    this.markers.push(marker);
  }
  private _filter(value: any): CityName[] {
    let filterValue;
    if (typeof value == 'object' && value !== null) {
      filterValue = value.name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }
    return filterValue != ''
      ? this.cities.filter((city) =>
          city.name.toLowerCase().includes(filterValue)
        )
      : this.cities;
  }

  displayCity(city: any): string {
    return city && city.name ? city.name : '';
  }

  onDropSuccess(evt: any) {
    evt.preventDefault();

    const fileInputEvent = {
      target: {
        files: evt.dataTransfer.files,
      },
    } as unknown as Event;

    this.onFileSelected(fileInputEvent);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      // Convert FileList to an array and map new files to their URLs
      const newFiles = Array.from(input.files);
      const newImageUrls = newFiles.map((file) => URL.createObjectURL(file));

      // Append new files and URLs to existing arrays
      this.selectedFiles = [...this.selectedFiles, ...newFiles];
      this.selectedImageUrls = [...this.selectedImageUrls, ...newImageUrls];

      // Trigger change detection manually if needed
      this.cdr.detectChanges();
    }
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.cdr.detectChanges();
  }

  createImageUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  // Function to fetch an image as a Blob using XMLHttpRequest.
  fetchImageAsBlob(imageUrl) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', imageUrl, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(
            new Error(
              `Failed to fetch image from ${imageUrl}. Status: ${xhr.status}`
            )
          );
        }
      };

      xhr.onerror = () =>
        reject(new Error(`Network error fetching image from ${imageUrl}`));
      xhr.send();
    });
  }

  // Converts a list of image URLs to files and stores them in `selectedFiles`.
  async convertBackendUrlsToFiles(imageUrls) {
    const filePromises = imageUrls.map(async (url) => {
      try {
        const file = await this.urlToFile(url);
        return file;
      } catch (error) {
        console.error(`Failed to convert URL to file: ${url}`, error);
        return null; // Skip failed fetches by returning null
      }
    });

    const files = await Promise.all(filePromises);
    this.selectedFiles.push(...files.filter((file) => file !== null));
  }

  // Converts a single image URL to a File object using XMLHttpRequest.
  async urlToFile(imageUrl) {
    try {
      const blob = (await this.fetchImageAsBlob(imageUrl)) as any;
      const fileName = imageUrl.split('/').pop() || 'image.jpg';

      // Create a File object from the Blob
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error(`Error converting URL to file for ${imageUrl}:`, error);
      throw error; // Rethrow or handle the error as needed
    }
  }

  async addListing() {
    console.log('Form', this.listingFormGroup);
    if (this.listingFormGroup.valid) {
      const dataFromForm = this.listingFormGroup.getRawValue();
      const createListing: CreateListing = {
        id: this.propertyId ? this.propertyId : 0,
        title: dataFromForm.title,
        address: dataFromForm.address,
        description: dataFromForm.description,
        keyWords: dataFromForm.keyWords,
        pricePerNight: dataFromForm.pricePerNight,
        maxGuests: dataFromForm.maxGuests,
        propertyCategory: dataFromForm.propertyType,
        isAvailable: dataFromForm.isAvailable,
        phoneNumber: dataFromForm.phoneNumber,
        latitude: dataFromForm.lat,
        longitude: dataFromForm.long,
        location: this.getCreateLocationrequest(dataFromForm),
        amenityDetails: this.amenities.filter((x) =>
          dataFromForm.amenities.includes(x.amenityId)
        ),
      };
      const formData = new FormData();
      formData.append('obj', JSON.stringify(createListing).toString() ?? '');
      this.convertBackendUrlsToFiles(this.backendImages).then(async (x) => {
        this.selectedFiles.forEach((file, index) => {
          formData.append('files', file, file.name);
        });
        try {
          if (this.propertyId) {
            const editListing$ = this.listingApiService.EditListing(formData);
            const editListing = await lastValueFrom(editListing$, {
              defaultValue: undefined,
            });
            if (editListing) {
              this.toastr.success(
                'Property edit successfully',
                'Property will go live after approval',
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
              this.moveToMylistings('Pending');
            }
          } else {
            const addListing$ = this.listingApiService.AddListing(formData);
            const addListing = await lastValueFrom(addListing$, {
              defaultValue: undefined,
            });
            if (addListing) {
              this.toastr.success(
                'Property added successfully',
                'Registration Successfull',
                {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                }
              );
              this.moveToMylistings('Pending');
            }
          }
        } catch (e: any) {
          let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
          if (e?.error) {
            message = e.error.message ? e.error.message : e.error.errors[0];
            this.toastr.error(message, 'Please try again later', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
          }
        } finally {
        }
      });
    }
  }

  getCreateLocationrequest(formData: any): Location {
    const location: Location = {
      city: formData.city.name,
      country: formData.country,
      region: formData.regionOrState,
      address: formData.address,
      zipCode: formData.zipCode,
    };
    return location;
  }

  getAmenityCreateRequest(): AmenityDetails[] {
    return this.amenities
      .filter((amenity) => amenity.isChecked === true)
      .map((x) => ({
        amenityId: x.amenityId,
        propertyProfileId: null,
        id: null,
      }));
  }

  getOpeningHoursForCreateRequest(formData: any): OpeningHour[] {
    let openinghours: OpeningHour[] = [];
    formData.openingHours.forEach((openingHour: any) => {
      const openinghour: OpeningHour = {
        dayOfWeek: openingHour.dayOfWeek,
        openTime:
          openingHour.openTime != 'Closed'
            ? this.convertTime12toTimeSpan(openingHour.openTime)
            : null,
        closeTime:
          openingHour.closeTime != 'Closed'
            ? this.convertTime12toTimeSpan(openingHour.closeTime)
            : null,
        isOpen: openingHour.openTime != 'Closed' ? true : false,
      };
      openinghours.push(openinghour);
    });
    return openinghours;
  }

  convertTime12toTimeSpan(time: string): any {
    const [hourStr, period] = time.split(' ');

    let hour = parseInt(hourStr, 10);
    let minutes = '00';

    if (period.toLowerCase() === 'pm' && hour !== 12) {
      hour += 12;
    } else if (period.toLowerCase() === 'am' && hour === 12) {
      hour = 0;
    }
    const hourFormatted = hour.toString().padStart(2, '0');
    // return this.timeSpanToDuration(`${hourFormatted}:${minutes}:00`);
    return `${hourFormatted}:${minutes}:00`;
  }

  timeSpanToDuration(timeSpan: string): number {
    const [hoursStr, minutesStr, secondsStr] = timeSpan.split(':');

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);

    // Convert the entire time into seconds
    const durationInSeconds = hours * 3600 + minutes * 60 + seconds;

    return durationInSeconds;
  }

  breadcrumb = [
    {
      title: 'Add Listings',
      subTitle: 'Dashboard',
    },
  ];

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.keywords.push(value);
    }
    event.chipInput!.clear();
    this.listingFormGroup.get('keyWords')?.patchValue(this.keywords.join(', '));
  }

  removeTag(tag: any): void {
    const index = this.keywords.indexOf(tag);
    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
    this.listingFormGroup.get('keyWords')?.patchValue(this.keywords.join(', '));
  }

  editTag(tag: any, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.removeTag(tag);
      return;
    }
    const index = this.keywords.indexOf(tag);
    if (index >= 0) {
      this.keywords[index].name = value;
    }
    this.listingFormGroup.get('keyWords')?.patchValue(this.keywords.join(', '));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
