<!-- Start Footer Area -->
<footer class="footer-area" style="background-color: #1B1D21;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="footer__title">
                  <img src="assets/images/footer/logo-footer.png" class="img-fluid" />
                </div>
                <div class="footer__menu">
                  <ul>
                      <li>
                        <a href="#" style="color:white;">
                            © 2024 Snappstay All rights reserved. Discover Unique Stays and Unforgettable Experiences—Where Every Trip Becomes a Memory!
                        </a>
                      </li>
                  </ul>
                </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 style="color: azure;">Quick Links</h3>

                    <ul class="link-list">
                        <li><a routerLink="/about"><i class="flaticon-left-chevron"></i> About Snappstay</a></li>
                        <li><a routerLink="/how-it-works"><i class="flaticon-left-chevron"></i> How it works</a></li>
                        <li><a routerLink="/privacy-policy"><i class="flaticon-left-chevron"></i> Privacy Policy</a></li>
                        <li><a routerLink="/term-of-services"><i class="flaticon-left-chevron"></i> Terms of Service</a></li>
              
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 style="color: azure;">Pages</h3>

                    <ul class="link-list">
                        <li (click)="moveToSnappystayLuxury()"><a ><i class="flaticon-left-chevron"></i> Snappystay Luxury</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 style="color: azure;">Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="bx bx-map"></i>175 5th Ave Premium Area, New York, NY 10010, United States</li>
                        <li><i class="bx bx-phone-call"></i><a href="tel:+11234567890">+1 (123) 456 7890</a></li>
                        <li><i class="bx bx-envelope"></i><a href="mailto:hello&#64;vesax.com">info&#64;snappstay.com</a></li>
                        <li><i class="bx bxs-inbox"></i><a href="tel:+557854578964">+55 785 4578964</a></li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/snappstay1" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/@Snappstay" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a  class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/@Snappstay" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-lg-1 col-md-2 mb-30">
                <img src="assets/images/footer/aws.png" class="img-fluid" />
            </div>
            <div class="col-lg-1 col-md-2 mb-30">
                <img src="assets/images/footer/pci.png" class="img-fluid" />
            </div>
            <div class="col-lg-1 col-md-2 mb-30">
                <img src="assets/images/footer/docudashh.png" class="img-fluid" />
            </div>
            <div class="col-lg-1 col-md-2 mb-30">
                <img src="assets/images/footer/us-flag.png" class="img-fluid" />
            </div>
            <div class="col-lg-1 col-md-2 mb-30">
                <img src="assets/images/footer/gdpr.png" class="img-fluid" />
            </div>
            <div class="col-lg-1 col-md-2 mb-30">
                <img src="assets/images/footer/dmca.png" class="img-fluid" />
            </div>
            <div class="col-lg-1 col-md-2 mb-30" style="    background-color: #ffffff;
                border-radius: 5px;
                height: 60px;
                display: flex;
                width: 225px;
                padding: 8px;
                margin-top: 20px;">
                <img src="assets/images/footer/docudash.png" class="img-fluid" />
            </div>
        </div>
        <div class="copyright-area">
            <p>© Copyrights. All rights reserved by <a href="https://snappstay.com/" target="_blank">Snappstay</a></p>
        </div>
    </div>

    <!-- <div class="footer-image text-center">
        <img src="assets/img/footer-image.png" alt="image">
    </div> -->
</footer>
<!-- End Footer Area -->