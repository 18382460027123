import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Subscription, Subject, lastValueFrom, Observable } from 'rxjs';
import { StateName } from 'src/app/models/lookup/city-name';
import { CityName } from 'src/app/models/lookup/city-names';
import { CountryNames } from 'src/app/models/lookup/country-names';
import { LookupApiService } from 'src/app/services/api-services/lookup-api';
import {
  Login,
  SetAmenity,
  SetDashBoardCards,
  SetListing,
  SetPropertyType,
  SetReview,
  SetRoles,
  SetSingleListing,
  SetUserProfile,
} from 'src/app/state/snappstay.action';
import { Country, State, City } from 'country-state-city';
import { ZipCodeService } from 'src/app/services/common-services/zip-code-service';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { ListingApiService } from 'src/app/services/api-services/listing-api';
import { Role } from 'src/app/models/role.model';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { UserRegestrationResponse } from 'src/app/models/response-model/user-regestration-response-model';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { environment } from 'src/environments/environment';
import { ReviewApiService } from 'src/app/services/api-services/reviews-api';
import { PaginationReviewrRequest } from 'src/app/models/request-models/pagination-review-response';
import { Authorize } from 'src/app/models/authorize.model';
import { ToastrService } from 'ngx-toastr';
import { UserProfile } from 'src/app/models/user-profile.model';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { AccountsApiService } from 'src/app/services/api-services/accounts-api';
import { DashboardApiService } from 'src/app/services/api-services/dashboard-api';
import { PayoutsApiService } from 'src/app/services/api-services/payouts-api';
import { WalletApiService } from 'src/app/services/api-services/wallet-api';
@Component({
  template: '',
})
export abstract class SnappstayBaseComponent {
  @Select(SnappstayState.Authorize)
  Authorize$!: Observable<Authorize>;

  @Select(SnappstayState.GetRoles)
  roles$!: Observable<Role[]>;

  @Select(SnappstayState.GetUserProfile)
  userProfile$!: Observable<UserProfile>;

  @Select(SnappstayState.Listings)
  listings$!: Observable<BaseGridResponse<ListingResponse[]>>;

  public subscriptions: Subscription[] = [];
  public destroyer$: Subject<boolean> = new Subject<boolean>();
  public router: Router;
  public store: Store;
  public activatedRoute: ActivatedRoute;
  public environmentVar = environment;
  public lookupApiService: LookupApiService;
  public listingApiService: ListingApiService;
  public reviewApiService: ReviewApiService;
  public cities: CityName[] = [];
  public countries: CountryNames[] = [];
  public states: StateName[] = [];
  public user: UserRegestrationResponse | undefined;
  public userProfileDetails: UserProfile | undefined;
  public role: string | undefined;
  public toastr: ToastrService;
  public accountApiService: AccountsApiService;
  public dashboardApiService: DashboardApiService;
  public payoutsApiService: PayoutsApiService;
  public walletApiService: WalletApiService;
  public IsLuxury: boolean | undefined;
  protected constructor(injector: Injector) {
    this.store = injector.get(Store);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.lookupApiService = injector.get(LookupApiService);
    this.listingApiService = injector.get(ListingApiService);
    this.reviewApiService = injector.get(ReviewApiService);
    this.accountApiService = injector.get(AccountsApiService);
    this.dashboardApiService = injector.get(DashboardApiService);
    this.payoutsApiService = injector.get(PayoutsApiService);
    this.walletApiService = injector.get(WalletApiService);
    this.toastr = injector.get(ToastrService);
    this.cities = City.getAllCities() as unknown as CityName[];
    this.countries = Country.getAllCountries() as CountryNames[];
    this.states = State.getAllStates() as StateName[];
    this.authorizeSub();
    this.IsLuxuryDub();
    this.userProfileDetailsSub();
    this.environmentVar = environment;
    this.role;
    this.IsLuxury;
  }

  getZipCodeByCityAndStateCode(cityCode: string, stateCode: string): any {
    const zipCodeService = new ZipCodeService();
    const zip = zipCodeService.getZipCodeByCityAndStateCode(
      cityCode,
      stateCode
    );
    return zip;
  }

  authorizeSub() {
    const isAuthorizeSub = this.store
      .select(SnappstayState.Authorize)
      .subscribe((authorize) => {
        if (!authorize) {
          this.role = undefined;
        } else {
          this.role = authorize.roles.at(0);
        }
      });
    this.subscriptions.push(isAuthorizeSub);
  }

  IsLuxuryDub() {
    const isLuxuryDub = this.store
      .select(SnappstayState.IsLuxury)
      .subscribe((isLuxury) => {
        this.IsLuxury = isLuxury;
      });
    this.subscriptions.push(isLuxuryDub);
  }

  userSub() {
    const userSub = this.store
      .select(SnappstayState.GetUser)
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.role = this.user.roles.at(0);
        } else {
          this.user = undefined;
          this.role = 'Guest';
        }
      });
    this.subscriptions.push(userSub);
  }

  userProfileDetailsSub() {
    const userSub = this.store
      .select(SnappstayState.GetUserProfile)
      .subscribe((user) => {
        this.userProfileDetails = user;
      });
    this.subscriptions.push(userSub);
  }

  async getPropertyProfileById(id: number) {
    try {
      const propertyProfile$ =
        this.listingApiService.GetPropertyProfileById(id);
      const propertyProfile = await lastValueFrom(propertyProfile$, {
        defaultValue: undefined,
      });
      if (propertyProfile) {
        this.store.dispatch(new SetSingleListing(propertyProfile));
        const request: PaginationReviewrRequest = {
          propertyProfileId: id,
          pageNumber: null,
          pageSize: null,
          sortColumn: 'Id',
          sortOrder: null,
          search: null,
        };
        this.GetReviewByPropertyProfileId(request);
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }

      this.store.dispatch(new SetSingleListing(undefined));
    } finally {
    }
  }

  async GetReviewByPropertyProfileId(request: PaginationReviewrRequest) {
    try {
      const profileReview$ =
        this.reviewApiService.GetReviewByPropertyProfileId(request);
      const profileReview = await lastValueFrom(profileReview$, {
        defaultValue: undefined,
      });
      if (profileReview) {
        this.store.dispatch(new SetReview(profileReview));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }

      this.store.dispatch(new SetReview(undefined));
    } finally {
    }
  }

  async getAmenity() {
    try {
      const getAmenities$ = this.lookupApiService.GetAmenities();
      const getAmenities = await lastValueFrom(getAmenities$, {
        defaultValue: undefined,
      });
      if (getAmenities) {
        getAmenities.forEach((x) => {
          x.isChecked = false;
        });
        this.store.dispatch(new SetAmenity(getAmenities));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }

      this.store.dispatch(new SetAmenity(undefined));
    } finally {
    }
  }

  async getPropertyTypes() {
    try {
      const getPropertyTypes$ = this.lookupApiService.GetPropertyTypes();
      const getPropertyTypes = await lastValueFrom(getPropertyTypes$, {
        defaultValue: undefined,
      });
      if (getPropertyTypes) {
        this.store.dispatch(new SetPropertyType(getPropertyTypes));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }

      this.store.dispatch(new SetPropertyType(undefined));
    } finally {
    }
  }

  async userProfile() {
    try {
      const getMyProfile$ = this.accountApiService.GetMyProfile();
      const getMyProfile = await lastValueFrom(getMyProfile$, {
        defaultValue: undefined,
      });
      if (getMyProfile) {
        this.store.dispatch(new SetUserProfile(getMyProfile));
      }
    } catch (e: any) {
      let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  async getListings(request: GetListingRequest) {
    try {
      const getListings$ = this.listingApiService.GetListings(request);
      const getListings = await lastValueFrom(getListings$, {
        defaultValue: undefined,
      });
      this.store.dispatch(new SetListing(getListings));
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetListing(undefined));
    } finally {
    }
  }

  async getRoles() {
    try {
      const getRoles$ = this.lookupApiService.GetAllRoles();
      const getRoles = await lastValueFrom(getRoles$, {
        defaultValue: undefined,
      });
      if (getRoles) {
        this.store.dispatch(
          new SetRoles((getRoles as Role[]).filter((x) => x.id != 1))
        );
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetListing(undefined));
    } finally {
    }
  }

  moveToHomeTwo($event?: any) {
    this.router.navigate(['/']).then();
  }
  moveToAddListing() {
    this.router.navigate(['/', 'dashboard-add-listings']).then();
  }
  moveToMoreListing() {
    this.router.navigate(['/', 'grid-listings-left-sidebar']).then();
  }
  moveToAddListingById(id: number) {
    this.router.navigate(['/', 'dashboard-add-listings', id]).then();
  }
  moveToDashboard() {
    this.router.navigate(['/', 'dashboard']).then();
  }
  moveToUserlisting(param: string) {
    this.router
      .navigate(['/', 'users-listing', param], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }
  moveToBookings() {
    this.router
      .navigate(['/', 'dashboard-bookings'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }
  moveToReviews() {
    this.router
      .navigate(['/', 'dashboard-reviews'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToPayouts() {
    this.router
      .navigate(['/', 'payouts'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }
  moveToWallet() {
    this.router
      .navigate(['/', 'dashboard-wallet'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }
  moveToSalesTransaction() {
    this.router
      .navigate(['/', 'sales-Transactions'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }
  moveToMylistings(param: string) {
    this.router
      .navigate(['/', 'dashboard-my-listings', param], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToSingleListing(routeLink: any, id: number) {
    this.router
      .navigate(['/', routeLink, id], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToUserDetails(id: number) {
    this.router
      .navigate(['/', 'user-profile', id], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToUpdateProfile() {
    this.router
      .navigate(['/', 'update-profile'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToChangePassword() {
    this.router
      .navigate(['/', 'change-password'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToAdminEarnings() {
    this.router
      .navigate(['/', 'admin-earnings'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToUserEarnings() {
    this.router
      .navigate(['/', 'user-earnings'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  moveToMessages() {
    this.router
      .navigate(['/', 'dashboard-messages'], {
        relativeTo: this.activatedRoute,
      })
      .then();
  }

  logOut() {
    this.store.dispatch(new Login(undefined));
    this.store.reset({});
    this.router.navigate(['/']).then();
  }
}
