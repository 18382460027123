import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-download',
    templateUrl: './app-download.component.html',
    styleUrls: ['./app-download.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
    ]
})
export class AppDownloadComponent implements OnInit {

        

    ngOnInit(): void {
    }

    appDownloadContent = [
        {
            title: 'Download Snappstay App',
            paragraph: 'SnappStay: Explore, Discover, and Connect—Crafting Unique Travel Experiences Just for You!',
            btnBox: [
                {
                    img: 'assets/img/play-store.png',
                    text: 'GET IT ON',
                    subText: 'Google Play',
                    link: '#'
                },
                {
                    img: 'assets/img/apple-store.png',
                    text: 'Download on the',
                    subText: 'Apple Store',
                    link: '#'
                }
            ]
        }
    ]
    appDownloadImage = [
        {
            img: 'assets/img/Mobile.png'
        }
    ];

}