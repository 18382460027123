import { Component, Injector, OnInit } from '@angular/core';
import { SnappstayBaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss'],
  standalone: true,
  imports: [],
})
export class FooterStyleOneComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  constructor(inject: Injector) {
    super(inject);
  }

  ngOnInit(): void {}

  moveToAbout(event : any) {
    this.router.navigate(['/', 'about']).then();
  }
}
