import { ZipCode } from 'src/app/models/lookup/zip-codes';
import zipcodes from 'zipcodes';

export class ZipCodeService {
  getZipCodeByCityAndStateCode(cityCode: string, stateCode: string): ZipCode[] {
    const zipCodesList: ZipCode[] = [];

    // Iterate over the zipcodes.codes object
    for (const zip in zipcodes.codes) {
      if (zipcodes.codes.hasOwnProperty(zip)) {
        const data = zipcodes.codes[zip];
        if (data.city.toLowerCase() === cityCode.toLowerCase() && data.state === stateCode) {
          const zipCode = new ZipCode(
            data.zip,
            data.latitude,
            data.longitude,
            data.city,
            data.state,
            data.country
          );
          zipCodesList.push(zipCode);
        }
      }
    }

    return zipCodesList;
  }
}
