<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="heading-container" style="margin-top: 10px;">
        <h2 style="font-weight: bold; color: #333;">Users</h2>
    </div>
    <section class="listing-section">
        <div class="row mb-3" >
            <div class="col">
                <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="Filter by any field">
            </div>
        </div>

        <div *ngIf="userProfiles.length > 0; else noData" class="listing-section">
            <table class="listing-table">
                <thead>
                    <tr>
                        <th (click)="onSortChange('fullName')" class="heading">Full Name</th>
                        <th (click)="onSortChange('firstName')" class="heading">First Name</th>
                        <th (click)="onSortChange('lastName')" class="heading">Last Name</th>
                        <th (click)="onSortChange('gender')" class="heading">Gender</th>
                        <th (click)="onSortChange('phoneNumber')" class="heading">Phone Number</th>
                        <th (click)="onSortChange('dateOfBirth')" class="heading">Date of Birth</th>
                        <th (click)="onSortChange('placeOfResidence')" class="heading">Place of Residence</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of userProfiles | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }" style="height: 50px;">
                        <td>{{ user.fullName }}</td>
                        <td>{{ user.firstName }}</td>
                        <td>{{ user.lastName }}</td>
                        <td>{{ user.gender }}</td>
                        <td>{{ user.phoneNumber }}</td>
                        <td>{{ user.dateOfBirth | date }}</td>
                        <td>{{ user.placeOfResidence }}</td>
                        <!-- <td class="action-column">
                            <button class="context-btn" (click)="toggleContextMenu(user)"  aria-haspopup="true" aria-expanded="Content.showMenu">&#x22EE;</button>
                            <div *ngIf="user.showMenu" class="context-menu">
                                <ul class="context-menu-list">
                                    <li (click)="moveToUserDetails(user.id)">View Details</li>
                                    <li (click)="onContextMenu('edit', user)">Edit</li>
                                    <li (click)="onContextMenu('delete', user)">Delete</li>
                                </ul>
                            </div>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        
        <ng-template #noData>
            <table class="listing-table">
                <tbody>
                    <tr>
                        <td colspan="7" class="text-center">
                            <div class="empty-state" style="display: flex; justify-content: center; align-items: center; min-height: 65vh; width: 100%;">
                                <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
        
        <div class="pagination-area text-center">
            <pagination-controls id="server"
                itemsPerPage="pageSize"
                currentPage="currentPage"
                totalItems="totalRecords"
                (pageChange)="onPageChange($event)">
            </pagination-controls>
        </div>
        
        
    </section>
</div>
