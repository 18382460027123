import {
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { GetPropertyRequest } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { lastValueFrom } from 'rxjs';
import { PropertyProfileStatusRequest } from 'src/app/models/request-models/property-profile-status-request';
import { VerticalListingsLeftSidebarComponent } from '../../vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';

@Component({
  selector: 'app-dashboard-my-listings',
  templateUrl: './dashboard-my-listings.component.html',
  styleUrls: ['./dashboard-my-listings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    NgxPaginationModule,
    CarouselModule,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    RouterLinkWithHref,
    RouterLinkActive,
    VerticalListingsLeftSidebarComponent,
  ],
})
export class DashboardMyListingsComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  singleListingsBox: any[] = [];
  status: string | undefined;
  currentPage: number = 1; // Starting page
  pageSize: number = 4; // Items per page
  totalRecords: number = 0; // Total items count for pagination
  sortField = 'fullName';
  sortDirection = 'asc';
  filter = '';
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    const getJobId = this.activatedRoute.paramMap.subscribe((params: any) => {
      if (params.has('status')) {
        this.status = params.params.status;
        this.GetListingApiCalling(); // Initial data fetch
        this.listingsSub(); // Subscribe to listing data updates
      }
    });
  }

  private GetListingApiCalling() {
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: this.currentPage, // Dynamic page number
      pageSize: this.pageSize,
      isActive:
        this.status == 'Active'
          ? true
          : this.status == 'Pending'
          ? false
          : undefined,
      isRejected: this.status == 'Rejected' ? true : undefined,
      isLuxury: this.IsLuxury,
    };

    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }
  onSortChange(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: this.currentPage, // Dynamic page number
      pageSize: this.pageSize,
      isActive:
        this.status == 'Active'
          ? true
          : this.status == 'Pending'
          ? false
          : undefined,
      isRejected: this.status == 'Rejected' ? true : undefined,
      isLuxury: this.IsLuxury,
      sortColumn: this.sortField,
      sortOrder: this.sortDirection,
    };

    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }
  listingsSub() {
    const listingSub = this.listings$.subscribe((item) => {
      if (item) {
        this.totalRecords = item.totalRecords; // Set total records for pagination
        this.singleListingsBox = item.result.map(
          (property: ListingResponse) => ({
            id: property.id,
            mainImg:
              property.propertyPicture?.map((image) => ({
                img: `${this.environmentVar.blobUrl}${image.path}`,
              })) || [],
            category: property.propertyCategory,
            location: `${property.city}-${property.country}`,
            title: property.title,
            price: property.pricePerNight,
            detailsLink: 'single-listings',
            rating: Array(property.starRatings).fill({ icon: 'bx bxs-star' }),
            ratingCount: property.numberOfReviews || null,
          })
        );
      }
    });
    this.subscriptions.push(listingSub);
  }

  applyFilter(event: Event) {
    this.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.currentPage = 1;
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: this.currentPage, // Dynamic page number
      pageSize: this.pageSize,
      isActive:
        this.status == 'Active'
          ? true
          : this.status == 'Pending'
          ? false
          : undefined,
      isRejected: this.status == 'Rejected' ? true : undefined,
      isLuxury: this.IsLuxury,
      search: this.filter,
    };

    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }

  async SetPropertyProfileStatus(status: string, id: number) {
    try {
      const request: PropertyProfileStatusRequest = {
        id: id,
        propertyStatus: status,
      };
      const PropertyProfileStatus$ =
        this.listingApiService.SetPropertyProfileStatus(request);
      const PropertyProfileStatus = await lastValueFrom(
        PropertyProfileStatus$,
        {
          defaultValue: undefined,
        }
      );
      if (PropertyProfileStatus) {
        this.GetListingApiCalling();
      }
    } catch (e: any) {
      let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  approve(listingId: any) {
    this.SetPropertyProfileStatus('Approved', listingId);
  }

  reject(listingId: any) {
    this.SetPropertyProfileStatus('Rejected', listingId);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.GetListingApiCalling(); // Fetch data for the new page
  }

  toggleContextMenu(content: any): void {
    this.singleListingsBox.forEach((item) => (item.showMenu = false));
    content.showMenu = !content.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.context-menu') && !target.closest('.context-btn')) {
      this.singleListingsBox.forEach((item) => (item.showMenu = false));
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
