import { Routes } from '@angular/router';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { DashboardAddListingsComponent } from './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component';
import { DashboardBookingsComponent } from './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component';
import { DashboardBookmarksComponent } from './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component';
import { DashboardInvoiceComponent } from './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
import { DashboardMessagesComponent } from './components/pages/dashboard/dashboard-messages/dashboard-messages.component';
import { DashboardMyListingsComponent } from './components/pages/dashboard/dashboard-my-listings/dashboard-my-listings.component';
import { DashboardMyProfileComponent } from './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { DashboardWalletComponent } from './components/pages/dashboard/dashboard-wallet/dashboard-wallet.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { NgxsModule } from '@ngxs/store';
import { SnappstayState } from './state/snappstay.state';
import { importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpDefaultInterceptor } from './utils/interceptor/default.interceptor';
import { LoadingInterceptor } from './utils/interceptor/loading.service';
import { StripeCancelComponent } from './components/common/stripe-cancel/stripe-cancel.component';
import { StripeSuccessComponent } from './components/common/stripe-success/stripe-success.component';
import { PaypalSuccessComponent } from './components/common/paypal-success/paypal-success.component';
import { PaypalCancelComponent } from './components/common/paypal-cancel/paypal-cancel.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { UsersListingComponent } from './components/pages/dashboard/users-listing/users-listing.component';
import { UpdateUserProfileComponent } from './components/pages/update-user-profile/update-user-profile.component';
import { UpdatePasswordComponent } from './components/pages/update-password/update-password.component';
import { DashboardPayoutsComponent } from './components/pages/dashboard/dashboard-payouts/dashboard-payouts.component';
import { DashboardSalesTransactionsComponent } from './components/pages/dashboard/dashboard-sales-transactions/dashboard-sales-transactions.component';
import { DashboardAdminEarningsComponent } from './components/pages/dashboard/dashboard-admin-earnings/dashboard-admin-earnings.component';
import { DashboardUserEarningsComponent } from './components/pages/dashboard/dashboard-user-earnings/dashboard-user-earnings.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeDemoTwoComponent,
    providers: [
      importProvidersFrom([NgxsModule.forFeature([SnappstayState])]),
      provideHttpClient(withInterceptorsFromDi()),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpDefaultInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true,
      },
    ],
    //canActivate: [authGuard],
  },
  { path: 'index-2', component: HomeDemoOneComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'term-of-services', component: TermsOfServiceComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'how-it-works', component: HowItWorksPageComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'user-profile/:Id', component: AuthorProfileComponent },
  { path: 'update-profile', component: UpdateUserProfileComponent },
  { path: 'change-password', component: UpdatePasswordComponent },
  { path: 'categories', component: CategoriesComponent },
  {
    path: 'grid-listings-left-sidebar',
    component: VerticalListingsLeftSidebarComponent,
  },
  {
    path: 'single-listings/:id',
    component: ListingsDetailsComponent,
  },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard-messages', component: DashboardMessagesComponent },
  { path: 'dashboard-bookings', component: DashboardBookingsComponent },
  { path: 'dashboard-wallet', component: DashboardWalletComponent },
  { path: 'dashboard-reviews', component: DashboardReviewsComponent },
  { path: 'dashboard-invoice', component: DashboardInvoiceComponent },
  { path: 'dashboard-my-profile', component: DashboardMyProfileComponent },
  { path: 'dashboard-add-listings', component: DashboardAddListingsComponent },
  {
    path: 'dashboard-add-listings/:id',
    component: DashboardAddListingsComponent,
  },
  { path: 'dashboard-bookmarks', component: DashboardBookmarksComponent },
  {
    path: 'dashboard-my-listings/:status',
    component: DashboardMyListingsComponent,
  },
  {
    path: 'users-listing/:user',
    component: UsersListingComponent,
  },
  { path: 'stripe-success', component: StripeSuccessComponent },
  { path: 'stripe-cancel', component: StripeCancelComponent },
  { path: 'paypal-success', component: PaypalSuccessComponent },
  { path: 'paypal-cancel', component: PaypalCancelComponent },
  { path: 'payouts', component: DashboardPayoutsComponent },
  { path: 'sales-Transactions', component: DashboardSalesTransactionsComponent },
  { path: 'admin-earnings', component: DashboardAdminEarningsComponent },
  { path: 'user-earnings', component: DashboardUserEarningsComponent },
  { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];
