import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { AccountComponent } from '../account/account.component';
@Component({
    selector: 'app-dashboard-navbar',
    templateUrl: './dashboard-navbar.component.html',
    styleUrls: ['./dashboard-navbar.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        RouterLinkWithHref,
        RouterLinkActive,
        AccountComponent
    ]
})
export class DashboardNavbarComponent  extends SnappstayBaseComponent implements OnInit {

    constructor(
        inject: Injector,
      ) {
        super(inject);
      }

    ngOnInit(): void {}

    classApplied = false;



    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

}