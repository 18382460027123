import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PropertyType } from 'src/app/models/lookup/property-type';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { GetPropertyRequest } from 'src/app/state/snappstay.action';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref, RouterLinkActive],
})
export class CategoryComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  @Select(SnappstayState.PropertyType)
  propertyType$!: Observable<PropertyType[]>;
  singleCategoryBox: any[] = [];
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.propertyType$.subscribe((propertyType) => {
      if (propertyType && propertyType.length > 0) {
        this.singleCategoryBox = [];
        propertyType.forEach((item) => {
          this.singleCategoryBox.push({
            id: item.id,
            icon: this.getIconByPropertytype(item.name),
            title: item.name,
            // link: 'grid-listings-left-sidebar'
          });
        });
      }
    });
  }

  getIconByPropertytype(propertyName: string): string {
    if (propertyName == 'Apartment') {
      return 'assets/snappstay/apartment.png';
    } else if (propertyName == 'House') {
      return 'assets/snappstay/home.png';
    } else if (propertyName == 'Condo') {
      return 'assets/snappstay/housess.png';
    } else if (propertyName == 'Villa') {
      return 'assets/snappstay/vila.png';
    } else if (propertyName == 'Studio') {
      return 'assets/snappstay/studio.png';
    } else if (propertyName == 'Serviced Apartment') {
      return 'assets/snappstay/apartment.png';
    }
    return `flaticon-furniture-and-household`;
  }
  sectionTitle = [
    {
      title: 'Browse properties by Category',
      paragraph:
        'Find the Perfect Rental Property by Category – Your Ideal Home Awaits!',
    },
  ];
  getPropertyByCategory(name: string) {
    this.router.navigate(['/', 'grid-listings-left-sidebar']).then();
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      propertyCategorys: [name],
    };
    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    );
  }
}
