<app-navbar-style-two></app-navbar-style-two>

<div class="page-title-area" *ngFor="let Content of pageTitleContent;"
    style="background-image: url('assets/img/checkout.png');">

</div>

<section class="checkout-area ptb-100">
    <div class="container">
   

        <form [formGroup]="profileForm">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Check-In date</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{bookingRequest.checkInDate | luxonDate: 'yyyy-MM-dd'}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Check-out date</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{bookingRequest.checkOutDate | luxonDate: 'yyyy-MM-dd'}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Number of guests</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{bookingRequest.numberOfGuests}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Price per night</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">${{bookingRequest.pricePerNight}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-subtotal">
                                            <span>Total Amount</span>
                                        </td>
                                        <td class="order-subtotal-price">
                                            <span
                                                class="order-subtotal-amount">${{bookingRequest.totalPriceAfterTax}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-shipping">
                                            <span>Status</span>
                                        </td>
                                        <td class="shipping-price">
                                            <span>Unpaid</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                  <input type="radio" id="paypal" formControlName="paymentMethod" value="PayPal">
                                  <label for="paypal">PayPal</label>
                                </p>
                                <p>
                                  <input type="radio" id="cash-on-delivery" formControlName="paymentMethod" value="Credit Card">
                                  <label for="cash-on-delivery">Credit Card</label>
                                </p>
                              </div>
                            <button type="button" [disabled]="profileForm.invalid" (click)="pay()"  class="default-btn"><i class="flaticon-tick"></i>Pay</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>