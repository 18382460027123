import { Injectable } from '@angular/core';
import { MapMarker, PinType } from './map-marker';

@Injectable({
  providedIn: 'root',
})
export class PopUpService {
  constructor() {}

  locationDetailPopup(data: MapMarker): string {
    // Check if the marker has an image
    const imageHTML = data.customData.data.img
      ? `<div style="text-align:center; position: relative;">
            <img src="${data.customData.data.img}" alt="${data.name}" style="object-fit: cover;width: 100%;height: 180px;" />
         </div>`
      : '';

    // Price at the top-left corner, placed above the image
    const priceLabel = data.price
      ? `<div style="font-weight: bold; font-size: 16px; padding: 10px; position: absolute; top: 10px; left: 10px; background-color: rgba(255, 255, 255, 0.8); border-radius: 5px;">
           $${data.price} <span style="font-weight: normal;">per night</span>
         </div>`
      : '';

    // Title without icon or label
    const nameLabel = `<div style="text-align:center; margin-top: 20px; font-size: 18px; font-weight: bold;">
                         ${data.name}
                       </div>`;

    // Address with icon (includes city)
    const addressLabel = `<p style="margin:3px; display: flex; align-items: center;">
                            <i class="flaticon-pin" style="margin-right: 5px;"></i>
                            <span> ${data.city}</span>
                         </p>`;

    // Category in the same row as the address
    const propertyType = `<p style="margin:3px; display: flex; align-items: center;">
                            <i class="flaticon-category" style="margin-right: 5px;"></i>
                            <span>${data.propertyType}</span>
                         </p>`;

    const ratingLabel = data.customData.data.rating
    ? (() => {
        const fullStars = Math.floor(data.customData.data.rating); // Number of full stars
        const halfStar = data.customData.data.rating % 1 !== 0; // True if there's a half star
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars
  
        // Generate full stars
        const fullStarIcons = '<i class="bx bxs-star" style="color: gold;"></i>'.repeat(fullStars);
  
        // Generate half star, if needed
        const halfStarIcon = halfStar ? '<i class="bx bxs-star-half" style="color: gold;"></i>' : '';
  
        // Generate empty stars
        const emptyStarIcons = '<i class="bx bx-star" style="color: gold;"></i>'.repeat(emptyStars);
  
        // Combine the star icons
        return `<div style="text-align:center; margin-top: 10px;">
                  ${fullStarIcons} ${halfStarIcon} ${emptyStarIcons}
                </div>`;
      })()
    : '';

    return (
      `` +
      `<div style="position: relative;">
            ${imageHTML} <!-- Image container -->
            <div>
            ${priceLabel} <!-- Price above image -->
              ${nameLabel} <!-- Title row -->
              <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                ${addressLabel} <!-- Address and city in one row -->
                ${propertyType} <!-- Category in the same row -->
              </div>
              ${ratingLabel} <!-- Ratings in the last row -->
            </div>
            <hr style="margin:5px;" />
         </div>`
    );
  }
}
