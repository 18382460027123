import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { Amenity } from 'src/app/models/listing-model/amenity';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { Role } from 'src/app/models/role.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LookupApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  GetAmenities(): Observable<Amenity[]> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAmenities}`)
      .pipe(
        map((data: ApiResponse<Amenity[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetAllRoles(): Observable<Role[]> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAllRoles}`)
      .pipe(
        map((data: ApiResponse<Role[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetPropertyTypes(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetPropertyTypes}`)
      .pipe(
        map((data: ApiResponse<Amenity[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
