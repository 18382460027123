import { CommonModule } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { lastValueFrom } from 'rxjs';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { NavbarStyleTwoComponent } from 'src/app/components/common/navbar-style-two/navbar-style-two.component';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { ProviderEarnings } from 'src/app/models/provider-earnings.model';
import { PaginationFilterInput } from 'src/app/models/request-models/pagination-review-response';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { SetProviderEarnings } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-dashboard-user-earnings',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    NavbarStyleTwoComponent,
    DashboardSidemenuComponent,
    DashboardNavbarComponent
  ],
  templateUrl: './dashboard-user-earnings.component.html',
  styleUrl: './dashboard-user-earnings.component.scss'
})
export class DashboardUserEarningsComponent    extends SnappstayBaseComponent
implements OnInit, OnDestroy
{
totalRecords = 0;
pageSize = 10;
currentPage = 1;
sortField = 'propertyProfileName';
sortDirection = 'asc';
filter = '';
providerEarnings: ProviderEarnings[] = [];

constructor(injector: Injector) {
  super(injector);
}

ngOnInit(): void {
  this.currentPage = 1;
  this.fetchProviderEarnings();
  this.setProviderEarnings();
}

setProviderEarnings() {
  const data = this.store
  .select(SnappstayState.GetProviderEarnings)
  .subscribe((value) => {
    if (value.result && value.result.length > 0) {
      this.providerEarnings = value.result;
    } else {
      // Dummy data to use when no provider earnings are available
      const dummyData = [
        {
          id: 1,
          propertyProfileId: 101,
          propertyProfileName: 'Sample Property',
          customerId: 201,
          customerName: 'John Doe',
          totalAmount: 1500,
          status: 'Active',
          paymentStatus: 'Completed',
          bookingDate: new Date('2023-01-15'), // Example date
        },
      ];

      // Assign dummy data to providerEarnings
      this.providerEarnings = dummyData;
    }
  });


  this.subscriptions.push(data);
}

applyFilter(event: Event) {
  this.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
  this.currentPage = 1;
  this.fetchProviderEarnings();
}

onPageChange(page: number) {
  this.currentPage = page;
  this.fetchProviderEarnings();
}

onSortChange(field: string) {
  if (this.sortField === field) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortField = field;
    this.sortDirection = 'asc';
  }
  this.fetchProviderEarnings();
}

async fetchProviderEarnings() {
  try {
    const filter: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: this.sortField,
      sortOrder: this.sortDirection,
      search: this.filter,
    };

    const providerEarnings$ =
      this.walletApiService.getAllProviderEarnings(filter);
    const response: BaseGridResponse<ProviderEarnings[]> =
      await lastValueFrom(providerEarnings$);
    this.totalRecords = response.totalRecords || 0;

    this.store.dispatch(new SetProviderEarnings(response));
  } catch (e: any) {
    let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
    if (e?.error) {
      message = e.error.message || e.error.errors[0];
      this.toastr.error(message, 'Please try again later', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
    }
    this.store.dispatch(new SetProviderEarnings(undefined));
  }
}

ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
