import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { BookingApiService } from 'src/app/services/api-services/booking-api';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { lastValueFrom } from 'rxjs';
import { PaginationFilterInput } from 'src/app/models/request-models/pagination-review-response';
import {
  SetBookingRequest,
  SetBookings,
  SetUserIdForChat,
} from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { NgxPaginationModule } from 'ngx-pagination';
import { BookingRequest } from 'src/app/models/request-models/booking-request';
import { Booking } from 'src/app/models/booking-model';

@Component({
  selector: 'app-dashboard-bookings',
  templateUrl: './dashboard-bookings.component.html',
  styleUrls: ['./dashboard-bookings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    NgxPaginationModule,
  ],
})
export class DashboardBookingsComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  status: string | undefined;
  bookingsListingsBox: any[] = [];
  filteredBookings: any[] = [];
  searchQuery: string = '';
  sortColumn: string = 'Id';
  sortDirection: 'asc' | 'desc' = 'desc';
  pageSize: number = 10;
  currentPage: number = 1;
  totalRecords: number = 0;
  detailHeaders: string[] = ['Address', 'Date', 'Price', 'Persons', 'Payment'];
  options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  constructor(
    injector: Injector,
    private bookingApiService: BookingApiService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadBookings();
    this.bookingSub();
  }

  // Load bookings based on user role and pagination/filtering/sorting
  loadBookings(): void {
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: this.sortColumn,
      sortOrder: this.sortDirection,
      search: this.searchQuery,
    };

    if (this.role == 'Guest') {
      this.GetAllMyBooking(request);
    } else {
      this.GetAllAdminBooking(request);
    }
  }

  bookingSub() {
    const bookingsSub = this.store
      .select(SnappstayState.Bookings)
      .subscribe((value) => {
        if (value) {
          this.totalRecords = value.totalRecords;
          this.bookingsListingsBox = [];
          value.result.forEach((booking) => {
            this.bookingsListingsBox.push({
              id: booking.id,
              guestId: booking.guestId,
              customerImg: booking.customerImg
                ? `${this.environmentVar.blobUrl}` + booking.customerImg
                : 'assets/img/download.png',
              customerName: booking.firstName + ' ' + booking.lastName,
              customerNumber: booking.phoneNumber,
              customerEmail: booking.email,
              title: booking.propertyProfileName,
              bookingsStatus: booking.bookingStatus,
              pendingApprovedCanceled:
                booking.bookingStatus === 'Pending' ? 'pending' : 'approved',
              address: booking.propertyAddress,
              date: new Date(booking.checkInDate).toLocaleDateString('en-GB'),
              price: `$${booking.pricePerNight}`,
              persons: booking.numberOfGuests,
              payment: booking.bookingStatus,
              showMenu: false,
            });
          });
          this.filteredBookings = [...this.bookingsListingsBox];
        }
      });
    this.subscriptions.push(bookingsSub);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    this.searchQuery = filterValue;
    this.currentPage = 1;
    this.loadBookings();
  }

  onSortChange(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.loadBookings();
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.loadBookings();
  }

  moveToCheckOut(id: any) {
    const booking = this.store.selectSnapshot(SnappstayState.Bookings).result.find(x => x.id == id);
    const request: BookingRequest = {
      id: booking.id, // Assuming `id` exists in booking
      checkInDate: booking.checkInDate,
      checkOutDate: booking.checkOutDate,
      numberOfGuests: booking.numberOfGuests,
      pricePerNight: booking.pricePerNight,
      totalPrice: booking.totalPrice,
      discount: booking.discount,
      discountType: booking.discountType,
      serviceFee: booking.serviceFee,
      cleaningFee: booking.cleaningFee,
      totalPriceAfterTax: booking.totalPriceAfterTax,
      bookingStatus: booking.bookingStatus,
      guestId: booking.guestId,
      propertyProfileId: booking.propertyProfileId, // Ensure this is mapped correctly
      paymentId: booking.paymentId,
      firstName: booking.firstName,
      lastName: booking.lastName,
      phoneNumber: booking.phoneNumber,
      email: booking.email,
      country: booking.country, // Assuming `country` exists in booking
    };

    this.store.dispatch(new SetBookingRequest(request));
    this.router.navigate(['/', 'checkout']).then();
  }

  async GetAllAdminBooking(request: PaginationFilterInput) {
    try {
      const GetAllAdminBooking$ =
        this.bookingApiService.GetAllAdminBooking(request);
      const response = await lastValueFrom(GetAllAdminBooking$, {
        defaultValue: undefined,
      });
      if (response) {
        this.store.dispatch(new SetBookings(response));
      }
    } catch (e: any) {
      this.handleError(e);
    }
  }

  async GetAllMyBooking(request: PaginationFilterInput) {
    try {
      const GetAllMyBooking$ = this.bookingApiService.GetAllMyBooking(request);
      const response = await lastValueFrom(GetAllMyBooking$, {
        defaultValue: undefined,
      });
      if (response) {
        this.store.dispatch(new SetBookings(response));
      }
    } catch (e: any) {
      this.handleError(e);
    }
  }

  private handleError(error: any) {
    let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
    if (error?.error) {
      message = error.error.message
        ? error.error.message
        : error.error.errors[0];
      this.toastr.error(message, 'Please try again later', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
    }
    this.store.dispatch(new SetBookings(undefined));
  }

  moveToChat(booking: Booking) {
    if(this.role == 'host'){
      this.store.dispatch(new SetUserIdForChat(booking.guestId));
    }else{
      this.store.dispatch(new SetUserIdForChat(booking.hostId));}
    this.moveToMessages();
  }

  toggleContextMenu(content: any): void {
    this.bookingsListingsBox.forEach((item) => (item.showMenu = false));
    content.showMenu = !content.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.context-menu') && !target.closest('.context-btn')) {
      this.bookingsListingsBox.forEach((item) => (item.showMenu = false));
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
