// src/app/services/chat.service.ts
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Message } from 'src/app/models/message';
import { SetMessage } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private hubConnection: HubConnection | undefined;

  constructor(private store: Store) {
    this.startConnection();
  }

  private startConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalR, {
        accessTokenFactory: () =>
          this.store.selectSnapshot(SnappstayState.Authorize).token,
        withCredentials: true,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('SignalR connected'))
      .catch((err) => console.log('Error connecting SignalR: ', err));

    this.hubConnection.on('ReceiveMessage', (message: Message) => {
      this.store.dispatch(new SetMessage(message));
    });
  }

  //Ends the SignalR connection if it exists
  stopConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      if (
        this.hubConnection &&
        this.hubConnection.state === signalR.HubConnectionState.Connected
      ) {
        this.hubConnection
          .stop()
          .then(() => {
            console.log('Connection stopped successfully');
            observer.next();
            observer.complete();
          })
          .catch((error) => {
            console.error('Error stopping the SignalR hub connection:', error);
            observer.error(error);
          });
      } else {
        console.warn('No active connection to stop');
        observer.complete();
      }
    });
  }
}
