<!-- Start Listings Area -->
<section class="listings-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title" *ngFor="let Title of sectionTitle;">
            <h2>{{Title.title}}</h2>
            <p>{{Title.paragraph}}</p>
        </div>

        <div class="row">
            <div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let Content of singleListingsBox;">
                <div class="single-listings-box home-listing-cards" style="margin-bottom: 20px;box-shadow: none;border-radius: 15px;">
                    <div class="listings-image">
                        <div class="listings-image-slides">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide *ngFor="let Image of Content.mainImg;">
                                    <div class="single-image" style="height: 220px;">
                                        <img [src]="Image.img" alt="image" class="img-responsive" style="object-fit: cover;width: 100%;height: 220px;">
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                    <div class="listings-content" style="padding: 10px 10px 10px !important;">
                        <!-- <div class="author">
                            <div class="d-flex align-items-center">
                                <img [src]="Content.authorImg" alt="image">
                                <span>{{Content.authorName}}</span>
                            </div>
                        </div> -->
                        <ul class="listings-meta d-flex flex-wrap" style="margin-bottom: 0px;">
                            <li style="margin-bottom: 4px;font-weight: 400;font-size: 11px;" ><i class="flaticon-furniture-and-household"></i> {{Content.category}}</li>
                            <li style="margin-bottom: 4px;font-weight: 400;font-size: 11px;"><i class="flaticon-pin"></i>{{Content.location}}</li>
                        </ul>
                        <h3 style="margin-bottom: 7px;font-size: 14px;font-weight: 500;">{{Content.title}}</h3>

                        <!-- <span class="status {{Content.extraClass}}"><i class="flaticon-save"></i> {{Content.openORclose}}</span> -->
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="rating" *ngIf="Content.rating != null">
                                <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                <span class="count" *ngIf="Content.ratingCount > 0">({{Content.ratingCount}})</span>
                            </div>

                            <div class="price" style="font-weight: 500;">
                                ${{Content.price}}
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-center align-items-center btn-block">
                          <button
                            type="button"
                            class="btn btn-primary"
                            style="
                              background-color: #D2A65B;
                              border: none;
                              width: 100px !important;
                              margin-top: 6px;
                              margin-bottom: 6px;"
                            (click)="moveToSingleListing(Content.detailsLink,Content.id)"
                          >
                            View
                          </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="more-listings-box" *ngFor="let Button of bottomButton;">
                    <a routerLink="/{{Button.link}}" class="default-btn">{{Button.text}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Area -->
