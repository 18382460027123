import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { MessageService } from 'src/app/services/api-services/message-service-api';
import { lastValueFrom } from 'rxjs';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import {
  SetConversation,
  SetConversationList,
  SetMessage,
  SetUserIdForChat,
} from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { UserConversation } from 'src/app/models/user-conversation';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { SendMessage } from 'src/app/models/request-models/send-message';
import { UserChats } from 'src/app/models/user-chats';
import { ChatService } from 'src/app/components/shared/signalr/chat-service';
import { Message } from 'src/app/models/message';
@Component({
  selector: 'app-dashboard-messages',
  templateUrl: './dashboard-messages.component.html',
  styleUrls: ['./dashboard-messages.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    ReactiveFormsModule,
    PickerComponent,
  ],
})
export class DashboardMessagesComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('chatContainer') chatContainer: ElementRef;
  @ViewChild('chatInput') chatInput: ElementRef;
  isUserScrolling: boolean = false;
  clickedOnInput: boolean = false;
  messageConversation: UserConversation | undefined;
  currentUserId: number | null;
  message: string = '';
  chatForm: FormGroup;
  isEmojiPickerVisible = false;
  userChatsList: UserChats[] = [];
  constructor(
    injector: Injector,
    private messagesService: MessageService,
    private fb: FormBuilder,
    private chatService: ChatService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.isUserScrolling = false;
    this.getChats(this.store.selectSnapshot(SnappstayState.GetUserProfile).id);
    this.chatForm = this.fb.group({
      message: [''],
    });

    const GetUserIdForChatSub = this.store
      .select(SnappstayState.GetUserIdForChat)
      .subscribe((value) => {
        if (value) {
          this.currentUserId = value;
          this.getConversation(
            this.store.selectSnapshot(SnappstayState.GetUserProfile).id,
            value
          );
        }
      });
    this.subscriptions.push(GetUserIdForChatSub);

    const GetConversationSub = this.store
      .select(SnappstayState.GetConversation)
      .subscribe((value) => {
        if (value) {
          this.messageConversation = value;
          if (
            !this.userChatsList.find(
              (x) => x.userId == this.messageConversation.receiverId
            )
          ) {
            this.userChatsList.push({
              userId: this.messageConversation.receiverId,
              userName: this.messageConversation.receiverName,
              imageUrl: this.messageConversation.receiverImage,
            });
          }
        } else {
          this.messageConversation = null;
        }
      });
    this.subscriptions.push(GetConversationSub);
    const GetConversationListSub = this.store
      .select(SnappstayState.GetConversationList)
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.userChatsList = value;
          this.currentUserId = value[0].userId;
          this.getConversation(
            this.store.selectSnapshot(SnappstayState.GetUserProfile).id,
            value[0].userId
          );
        } else {
          this.userChatsList = [];
        }
      });
    this.subscriptions.push(GetConversationListSub);

    const GetMessageSub = this.store
      .select(SnappstayState.GetMessage)
      .subscribe((value) => {
        if (value && this.isMessage(value)) {
          this.messageConversation.messages.push(value);
          this.scrollToBottom();
        }
        this.store.dispatch(new SetMessage(undefined));
      });
    this.subscriptions.push(GetMessageSub);
  }

  // Scroll to the bottom of the chat container
  private scrollToBottom(): void {
    if (this.chatContainer) {
      this.chatContainer.nativeElement.scrollTop =
        this.chatContainer.nativeElement.scrollHeight;
      this.clickedOnInput = false;
      this.isUserScrolling = false;
    }
  }

  // Automatically scroll when the view is updated
  ngAfterViewChecked(): void {
    if (!this.isUserScrolling || this.clickedOnInput) {
      this.scrollToBottom();
      this.clickedOnInput = false; // Reset after scrolling
    }
  }
  onScroll(): void {
    const container = this.chatContainer.nativeElement;
    const atBottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10;

    this.isUserScrolling = !atBottom; // Track if the user is manually scrolling
  }

  onContainerClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target === this.chatContainer.nativeElement) {
      this.isUserScrolling = true; // User clicked inside the chat container but not on the input box
    }
  }

  scrollToBottomOnInput(): void {
    this.isUserScrolling = false; // Allow auto-scrolling when the input box is interacted with
    this.clickedOnInput = true; // Mark interaction with input box
    this.scrollToBottom();
  }
  private isMessage(value: any): value is Message {
    return (
      typeof value === 'object' &&
      value !== null &&
      typeof value.senderId === 'number' &&
      typeof value.receiverId === 'number' &&
      typeof value.messageText === 'string'
    );
  }
  toggleEmojiPicker() {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  addEmoji(event: any) {
    const currentMessage = this.chatForm.get('message')?.value;
    this.chatForm.patchValue({ message: currentMessage + event.emoji.native });
    this.isEmojiPickerVisible = false;
  }

  sendMessage(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    const message = this.chatForm.get('message')?.value.trim();
    if (message) {
      const request: SendMessage = {
        ReceiverId: this.currentUserId,
        SenderId: this.store.selectSnapshot(SnappstayState.GetUserProfile).id,
        MessageText: message,
      };
      this.messageConversation.messages.push({
        receiverId: this.currentUserId,
        senderId: this.store.selectSnapshot(SnappstayState.GetUserProfile).id,
        messageText: message,
      });
      this.sendMessages(request).then((x) => {
        this.scrollToBottom();
        this.chatForm.reset();
      });
    }
  }

  async sendMessages(request: SendMessage) {
    try {
      const GetAllMyBooking$ = this.messagesService.sendMessage(request);
      const response = await lastValueFrom(GetAllMyBooking$, {
        defaultValue: undefined,
      });
      if (response) {
        this.store.dispatch(new SetMessage(response));
      }
    } catch (error: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (error?.error) {
        message = error.error.message
          ? error.error.message
          : error.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    }
  }

  async getChats(senderId: number) {
    try {
      const GetAllMyBooking$ =
        this.messagesService.GetUsersWithChatAsync(senderId);
      const response = await lastValueFrom(GetAllMyBooking$, {
        defaultValue: undefined,
      });
      if (response) {
        this.store.dispatch(new SetConversationList(response));
      }
    } catch (e: any) {
      this.handleError(e);
    }
  }
  openchat(chat: UserChats) {
    this.currentUserId = null;
    this.message = null;
    this.getConversation(
      this.store.selectSnapshot(SnappstayState.GetUserProfile).id,
      chat.userId
    ).then((x) => {
      this.scrollToBottom();
    });
  }
  async getConversation(senderId: number, receiverId: number) {
    try {
      const GetAllMyBooking$ = this.messagesService.getMessages(
        senderId,
        receiverId
      );
      const response = await lastValueFrom(GetAllMyBooking$, {
        defaultValue: undefined,
      });
      if (response) {
        this.store.dispatch(new SetConversation(response));
      }
    } catch (e: any) {
      this.handleError(e);
    }
  }

  private handleError(error: any) {
    let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
    if (error?.error) {
      message = error.error.message
        ? error.error.message
        : error.error.errors[0];
      this.toastr.error(message, 'Please try again later', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
    }
    this.store.dispatch(new SetConversation(undefined));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.messageConversation = null;
    this.currentUserId = null;
    this.userChatsList = null;
    this.message = null;
    this.store.dispatch(new SetUserIdForChat(undefined));
    this.store.dispatch(new SetMessage(undefined));
    this.store.dispatch(new SetConversation(undefined));
    this.store.dispatch(new SetConversationList(undefined));
    this.chatService.stopConnection();
  }
}
