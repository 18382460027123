<app-dashboard-sidemenu></app-dashboard-sidemenu>

<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area">
        <h1>Update Profile</h1>
    </div>

    <section class="listing-section">

        <div *ngIf="uploadProfilePicModelOpen" class="modal fade loginRegisterModal show d-block" tabindex="-1"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-3">
                    <!-- <button type="button" class="close position-absolute top-0 end-0 m-2"
                        (click)="closeProfilePicPopup()">
                        <i class="bx bx-x fs-4"></i>
                    </button> -->
                    <button type="button" class="close" (click)="closeProfilePicPopup()" style="position: absolute;
                      right: 0px;
                      top: 0px;
                      opacity: 1 !important;
                      visibility: visible !important;
                      background-color: #ffffff;
                      text-align: center;
                      text-shadow: unset;
                      border-radius: 50%;
                      font-size: 20px;
                      border: none;
                      padding: 0;
                      color: #161515;
                      padding: 0px 0 0;">
                          <i class='bx bx-x'></i>
                      </button>
                    <div class="modal-header border-0">
                        <h3 class="modal-title fw-bold">Upload Profile Pic</h3>
                    </div>
                    <div class="modal-body">
                        <div class="file-upload-box text-center mb-3 d-flex justify-content-center">
                            <input type="file" (change)="onFileSelected($event)" accept="image/*" hidden #fileInput>
                            <button mat-raised-button color="primary" (click)="fileInput.click()"
                                class="btn btn-outline-primary">
                                <mat-icon>cloud_upload</mat-icon> Select Image
                            </button>
                        </div>
                        <div *ngIf="selectedFile" class="image-gallery text-center mb-3">
                            <mat-card class="image-card d-inline-block">
                                <img [src]="createImageUrl(selectedFile)" class="image-preview rounded mb-2"
                                    alt="Selected Image">
                                <button mat-icon-button color="warn" (click)="removeFile()"
                                    class="btn btn-danger btn-sm">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card>
                        </div>
                    </div>

                    <div class="modal-footer border-0 d-flex justify-content-center">
                        <button type="button" class="btn btn-success " (click)="updateProfilePicApi()"
                            [disabled]="!selectedFile">
                            <mat-icon class="mx-2">cloud_upload</mat-icon>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>

        <div *ngIf="isBankInfoModelOpen" class="modal fade loginRegisterModal show d-block" tabindex="-1"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <!-- <button type="button" class="close" (click)="closePopup()">
                        <i class='bx bx-x'></i>
                    </button> -->
                    <button type="button" class="close" (click)="closePopup()" style="position: absolute;
                      right: 0px;
                      top: 0px;
                      opacity: 1 !important;
                      visibility: visible !important;
                      background-color: #ffffff;
                      text-align: center;
                      text-shadow: unset;
                      border-radius: 50%;
                      font-size: 20px;
                      border: none;
                      padding: 0;
                      color: #161515;
                      padding: 0px 0 0;">
                      <i class='bx bx-x'></i>
                    </button>
                    <div class="modal-header d-flex justify-content-center align-items-center m-0"> <!-- Added modal header -->
                        <h3 class="modal-title fw-bold">Update Bank Account Info</h3> <!-- Title added -->
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <form [formGroup]="bankUpdateForm">
                                <div class="form-group row align-items-center mb-3"> <!-- Added mb-3 for spacing -->
                                    <label class="col-form-label col-sm-4">Account Title:</label>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="accountTitle"
                                            placeholder="Enter account title" class="form-control" required>
                                        <div *ngIf="bankUpdateForm.get('accountTitle')?.touched && bankUpdateForm.get('accountTitle')?.hasError('required')"
                                            class="text-danger">
                                            Account title is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center mb-3"> <!-- Added mb-3 for spacing -->
                                    <label class="col-form-label col-sm-4">Account Number:</label>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="accountNumber"
                                            placeholder="Enter account number" class="form-control" required>
                                        <div *ngIf="bankUpdateForm.get('accountNumber')?.touched && bankUpdateForm.get('accountNumber')?.hasError('required')"
                                            class="text-danger">
                                            Account number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center mb-3"> <!-- Added mb-3 for spacing -->
                                    <label class="col-form-label col-sm-4">Bank Name:</label>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="bankName" placeholder="Enter bank name"
                                            class="form-control" required>
                                        <div *ngIf="bankUpdateForm.get('bankName')?.touched && bankUpdateForm.get('bankName')?.hasError('required')"
                                            class="text-danger">
                                            Bank name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center mb-3"> <!-- Added mb-3 for spacing -->
                                    <label class="col-form-label col-sm-4">IBAN:</label>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="iban" placeholder="Enter IBAN"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center" style="padding: 0px 0px 0px 125px;">
                                        <button type="button" class="btn btn-primary custom-button"
                                            style=" background-color: #D2A65B; border-color: #D2A65B;"
                                            (click)="updateAccountInfo()" [disabled]="bankUpdateForm.invalid">
                                            Update Account Info
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>


        <div class="form-box">
            <h3 class="text-center">User Profile</h3>

            <!-- New buttons row -->
            <div class="button-row">
                <button class="btn update-profile-pic" (click)="uploadProfilePic()">
                    <i class="fas fa-user-circle"></i> Update Profile Pic
                </button>
                <button class="btn add-bank-info" (click)="OpenPopUp()">
                    <i class="fas fa-university"></i> Update Bank Info
                </button>
            </div>

            <form [formGroup]="profileForm">
                <!-- Full Name -->
                <div class="row my-3">
                  <div class="mb-3 col-md-4">
                    <label>Full Name *</label>
                    <input type="text" formControlName="fullName" class="form-control" placeholder="Enter full name" />
                    <div *ngIf="profileForm.get('fullName').invalid && profileForm.get('fullName').touched"
                        class="text-danger">
                        Full Name is required.
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>First Name *</label>
                    <input type="text" formControlName="firstName" class="form-control"
                        placeholder="Enter first name" />
                    <div *ngIf="profileForm.get('firstName').invalid && profileForm.get('firstName').touched"
                        class="text-danger">
                        First Name is required.
                    </div>
                  </div>

                  <div class="col-md-4">
                      <label>Middle Name</label>
                      <input type="text" formControlName="middleName" class="form-control"
                          placeholder="Enter middle name" />
                  </div>
                </div>

                <!-- Name Fields in One Row -->
                <div class="row mb-3">
                    <div class="col-md-4">
                        <label>Last Name *</label>
                        <input type="text" formControlName="lastName" class="form-control"
                            placeholder="Enter last name" />
                        <div *ngIf="profileForm.get('lastName').invalid && profileForm.get('lastName').touched"
                            class="text-danger">
                            Last Name is required.
                        </div>
                    </div>
                    <div class="col-md-4">
                      <label>Gender *</label>
                      <div class="custom-select-wrapper">
                        <select formControlName="gender" class="form-select">
                            <option *ngIf="!profileForm.get('gender')?.value" value="" disabled selected>Select gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                      </div>
                      <div *ngIf="profileForm.get('gender').invalid && profileForm.get('gender').touched"
                          class="text-danger">
                          Gender is required.
                      </div>
                    </div>

                    <div class="col-md-4">
                        <label>Date of Birth *</label>
                        <!-- <input type="date" formControlName="dateOfBirth" class="form-control" /> -->
                        <mat-form-field appearance="outline" class="w-100 date-field">
                          <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" readonly>
                          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker panelClass="custom-datepicker"></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="profileForm.get('dateOfBirth').invalid && profileForm.get('dateOfBirth').touched"
                            class="text-danger">
                            Date of Birth is required.
                        </div>
                    </div>
                  </div>

                <!-- Address Fields -->
                <div class="row mb-3">
                    <div class="col-md-4">
                        <label>Place of Residence</label>
                        <input type="text" formControlName="placeOfResidence" class="form-control"
                            placeholder="Enter place of residence" />
                    </div>
                    <div class="col-md-4">
                        <label>Zip Code</label>
                        <input type="text" formControlName="zipCode" class="form-control"
                            placeholder="Enter zip code" />
                    </div>
                    <div class="col-md-4">
                      <label>City</label>
                      <mat-form-field appearance="fill" class="city-search-field">
                          <input type="text" matInput formControlName="city" [matAutocomplete]="auto" placeholder="Serach City">
                          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCity">
                              <ng-container *ngIf="filteredCities.length > 5; else noScroll">
                                  <cdk-virtual-scroll-viewport itemSize="48" class="viewport" style="height: 200px;">
                                      <mat-option *cdkVirtualFor="let city of filteredCities" [value]="city">
                                          {{ city.name }}
                                      </mat-option>
                                  </cdk-virtual-scroll-viewport>
                              </ng-container>
                              <ng-template #noScroll>
                                  <mat-option *ngFor="let city of filteredCities" [value]="city">
                                      {{ city.name }}
                                  </mat-option>
                              </ng-template>
                          </mat-autocomplete>
                      </mat-form-field>
                  </div>
                </div>

                <!-- City, State, and Country in One Row -->
                <div class="row mb-3">
                    <div class="col-md-4">
                        <label>State</label>
                        <input type="text" formControlName="state" class="form-control" placeholder="Enter state" />
                    </div>

                    <div class="col-md-4">
                        <label>Country</label>
                        <input type="text" formControlName="country" class="form-control" placeholder="Enter country" />
                    </div>
                </div>
                <div class="mb-3">
                    <label>Address</label>
                    <input type="text" formControlName="address" class="form-control" placeholder="Enter address" />
                </div>
                <!-- Contact Fields -->
                <div class="mb-3">
                    <label>Phone Number</label>
                    <input type="text" formControlName="phoneNumber" class="form-control"
                        placeholder="Enter phone number" />
                </div>

                <!-- Social Links in One Row -->
                <div class="row mb-3">
                    <div class="col-md-4">
                        <label>Facebook Link</label>
                        <input type="url" formControlName="fbLink" class="form-control"
                            placeholder="Enter Facebook link" />
                    </div>
                    <div class="col-md-4">
                        <label>Instagram Link</label>
                        <input type="url" formControlName="instaLink" class="form-control"
                            placeholder="Enter Instagram link" />
                    </div>
                    <div class="col-md-4">
                        <label>X (Twitter) Link</label>
                        <input type="url" formControlName="xLink" class="form-control"
                            placeholder="Enter X (Twitter) link" />
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="text-center">
                    <button type="button" class="btn btn-primary d-flex justify-content-center" (click)="submitForm()" style="    background-color: #D2A65B;
                    border-color: #D2A65B;
                    font-weight: 500;
                    width: 10%;
                    color: #ffffff;">Save</button>
                </div>
                <br>
            </form>
        </div>
    </section>
</div>
