import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meterToKm',
  standalone: true,
})
export class MeterToKmPipe implements PipeTransform {
  transform(value: number | undefined): string | null {
    if (value) {
      return `${(value / 1000).toFixed(2)} KM`;
    }
    return null;
  }
}
