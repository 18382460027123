import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { CategoryComponent } from '../../common/category/category.component';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        NavbarStyleOneComponent,
        FooterStyleTwoComponent,
        CategoryComponent
    ]
})
export class CategoriesComponent implements OnInit {

        

    ngOnInit(): void {
    }
    
    pageTitleContent = [
        {
            title: 'Listings Category',
            backgroundImage: 'assets/img/page-title/page-title2.jpg'
        }
    ]

}