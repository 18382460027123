import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, Duration } from 'luxon';

@Pipe({
  name: 'luxonDate',
  standalone: true,
})
export class LuxonDatePipe implements PipeTransform {
  transform(
    value: DateTime | Duration | Date | string | undefined,
    format: string = 'DATE_SHORT'
  ): any {
    if (value) {
      let dateTimeToUse: DateTime;

      if (value instanceof Date) {
        dateTimeToUse = DateTime.fromJSDate(value);
      } else if (value instanceof Duration) {
        const _dateTime = value.shiftTo('hours', 'minutes', 'seconds', 'milliseconds');
        const roundedSeconds = Math.round(_dateTime.seconds + _dateTime.milliseconds / 1000);
        const formattedTime = `${_dateTime.hours}:${String(_dateTime.minutes).padStart(2, '0')}:${String(roundedSeconds).padStart(2, '0')}`;
        return formattedTime;
      } else if (typeof value === 'string') {
        // Check if the string is an ISO 8601 date
        if (value.includes('T')) {
          dateTimeToUse = DateTime.fromISO(value); // Handle ISO date strings
        } else {
          dateTimeToUse = DateTime.fromFormat(value, 'hh:mm:ss'); // Handle other string formats
        }
      } else {
        dateTimeToUse = value; // Handle DateTime directly
      }

      return dateTimeToUse.toFormat(format);
    }
    return;
  }
}
