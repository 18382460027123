import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { Booking } from 'src/app/models/booking-model';
import { BookingRequest } from 'src/app/models/request-models/booking-request';
import { PaginationFilterInput } from 'src/app/models/request-models/pagination-review-response';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookingApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  bookingReservation(userData: BookingRequest): Observable<BookingRequest> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.BookingReservation}`, userData)
      .pipe(
        map((data: ApiResponse<BookingRequest>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  
  GetAllMyBooking(userData: PaginationFilterInput): Observable<BaseGridResponse<Booking[]>> {
    let params = new HttpParams();
    Object.keys(userData).forEach((key) => {
      if (userData[key] !== null && userData[key] !== undefined) {
        params = params.append(key, userData[key].toString());
      }
    });
  
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAllMyBooking}`,  { params })
      .pipe(
        map((data: BaseGridResponse<Booking[]>) => {
          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetAllOwnerPropertyProfileBooking(userData: PaginationFilterInput): Observable<Booking[]> {

    let params = new HttpParams();
    Object.keys(userData).forEach((key) => {
      if (userData[key] !== null && userData[key] !== undefined) {
        params = params.append(key, userData[key].toString());
      }
    });
  
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAllOwnerPropertyProfileBooking}`, { params })
      .pipe(
        map((data: ApiResponse<Booking[]>) => data.result!),
        catchError((err) => {
          throw err;
        })
      );
  }
  
  GetAllAdminBooking(userData: PaginationFilterInput): Observable<BaseGridResponse<Booking[]>> {
    let params = new HttpParams();
    Object.keys(userData).forEach((key) => {
      if (userData[key] !== null && userData[key] !== undefined) {
        params = params.append(key, userData[key].toString());
      }
    });
  
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAllAdminBooking}`, { params })
      .pipe(
        map((data: BaseGridResponse<Booking[]>) => data),
        catchError((err) => {
          throw err;
        })
      );
  }
}
