export enum ApplicationApiUrls {
    // Authentication
    Login = "/Auth/login",
    ChangeExpiredPassword = "/Auth/ChangeExpiredPassword",
    AppleLogin = "/Auth/AppleLogin",
    GoogleLogin = "/Auth/GoogleLogin",

    // Account Management
    CreateUser = "/Account/CreateUser",
    GetMyProfile = "/Account/GetMyProfile",
    UpdateProfile = "/Account/UpdateProfile",
    UpdateProfileImage = "/Account/UpdateProfileImage",

    // Lookups
    GetAmenities = "/Lookup/get-all-amenities",
    GetPropertyTypes = "/Lookup/get-property-types",
    GetAllRoles = "/Lookup/get-roles",

    // Property Profile Management
    AddListing = "/PropertyProfile/CreatePropertyProfile",
    EditListing = "/PropertyProfile/CreatePropertyProfile",
    GetListings = "/PropertyProfile/GetAllPropertyProfileByAdvanceSearch",
    GetPropertyProfileById = "/PropertyProfile/GetPropertyProfileById",
    SetPropertyProfileStatus = "/PropertyProfile/SetPropertyProfileStatus",

    // Reviews
    GetReviewByPropertyProfileId = "/Review/GetReviewByPropertyProfileId",
    GetAllReview = "/Review/GetAllReview",
    CreateReview = "/Review/CreateReview",

    // Dashboard
    DashBoardCardsForAdmin = "/DashBoard/DashBoardCardsForAdmin",
    DashBoardCardsForHost = "/DashBoard/DashBoardCardsForHost",
    AdminDashBoardCard = "/Admin/AdminDashBoardCard",
    AdminDashBoardBookingCard = "/Admin/AdminDashBoardBookingCard",

    // Booking Management
    BookingReservation = "/Booking/BookingReservation",
    GetAllMyBooking = "/Booking/GetAllMyBooking",
    GetAllOwnerPropertyProfileBooking = "/Booking/GetAllOwnerPropertyProfileBooking",
    GetAllAdminBooking = "/Admin/GetAllAdminBooking",

    // User Management
    GetAllUser = "/Admin/GetAllUser",

    // Payouts
    GetAllPayout = "/Payouts/GetAllPayout",
    CreatePayout = "/Payouts/CreatePayout",
    GetPayoutById = "/Payouts/GetPayoutById",
    ChangePayoutStatus = "/Payouts/ChangePayoutStatus",
    GetAllAdminPayout = "/Payouts/GetAllAdminPayout",
    DeletePayoutById = "/Payouts/DeleteById",

    // Wallet Management
    GetWalletInfo = "/Wallet/GetWalletInfo",
    GetAllSalesTransactions = "/Wallet/GetAllSalesTransactions",
    GetAllProviderSale = "/Wallet/GetAllProviderSale",
    GetAllProviderEarnings = "/Wallet/GetAllProviderEarnings",
    HostRemainingWalletBalance = "/Wallet/HostRemainingWalletBalance",
    WalletHostCard="/Wallet/WalletHostCard",

    // User Bank Account Management
    CreateUpdateBankInfo = "/UserBankAccount/CreateUpdateBankInfo",
    GetUserBankInfo = "/UserBankAccount/GetUserBankInfo",
}
