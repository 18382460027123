<div class="sidemenu-area" [class.active]="classApplied">
    <div class="sidemenu-header">
        <a class="navbar-brand" >
            <img src="assets/img/logo-dark.png" (click)="moveToHomeTwo($event)" style="width: 25%; height: auto;"
                alt="logo">
        </a>

        <div class="responsive-burger-menu d-block d-xl-none" (click)="toggleClass()">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>
    <div class="sidemenu-body">
        <ul class="sidemenu-nav h-100">
            <li class="nav-item-title">
                Main
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard') }" (click)="moveToDashboard()" *ngIf="role != 'Guest'">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Dashboard</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-messages') }" (click)="moveToMessages()">
                <a  class="nav-link">
                    <span class="icon"><i class='bx bx-envelope-open'></i></span>
                    <span class="menu-title">Messages</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-bookings') }" (click)="moveToBookings()">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-copy'></i></span>
                    <span class="menu-title">Bookings</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-add-listings') }" (click)="moveToAddListing()"
                *ngIf="role == 'Host'">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-plus-circle'></i></span>
                    <span class="menu-title">Add Listings</span>
                </a>
            </li>

            <!-- <li class="nav-item-title">
                Listings
            </li> -->
            <li class="nav-item" [ngClass]="{'mm-active': isListingDropdownOpen} " *ngIf="role != 'Guest'"
                (click)="toggleDropdown($event)">
                <a href="javascript:void(0)" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class="bx bx-layer"></i></span>
                    <span class="menu-title">Listings</span>

                </a>
                <ul class="sidemenu-nav-second-level" *ngIf="isListingDropdownOpen">
                    <li class="nav-item" (click)="moveToMylistings('Active')" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-my-listings/Active') }">
                        <a class="nav-link">
                            <span class="menu-title">Active</span>
                        </a>
                    </li>
                    <li class="nav-item" (click)="moveToMylistings('Pending')" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-my-listings/Pending') }">
                        <a  class="nav-link">
                            <span class="menu-title">Pending</span>
                        </a>
                    </li>
                    <li class="nav-item" (click)="moveToMylistings('Rejected')" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-my-listings/Rejected') }">
                        <a  class="nav-link">
                            <span class="menu-title">Rejected</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="role == 'Host'" (click)="moveToWallet()">
                <a routerLink="/dashboard-wallet" class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title">Wallet</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-wallet') }" *ngIf="role == 'Administrator'" (click)="moveToPayouts()">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title">Payouts</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/sales-Transactions') }" *ngIf="role == 'Administrator'" (click)="moveToSalesTransaction()">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-money'></i></span>
                    <span class="menu-title">Sales Transactions</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="role == 'Administrator'">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-dollar'></i></span>
                    <span class="menu-title">Admin Earnings</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" *ngIf="role == 'Administrator'">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-dollar'></i></span>
                    <span class="menu-title">Host Earnings</span>
                </a>
            </li>

            <li class="nav-item" *ngIf="role == 'Administrator'"
                [ngClass]="{'mm-active': isUsersDropdownOpen}" (click)="toggleUserDropdown($event)">
                <a href="javascript:void(0)" class="collapsed-nav-link nav-link" aria-expanded="false">
                    <span class="icon"><i class="bx bx-user"></i></span>
                    <span class="menu-title">Users</span>
                </a>
                <ul class="sidemenu-nav-second-level" *ngIf="isUsersDropdownOpen">
                    <li class="nav-item" (click)="moveToUserlisting('Hosts')" [ngClass]="{ 'mm-active': isRouteExactActive('/users-listing/Hosts') }">
                        <a class="nav-link">
                            <span class="menu-title">Hosts</span>
                        </a>
                    </li>
                    <li class="nav-item" (click)="moveToUserlisting('Guests')" [ngClass]="{ 'mm-active': isRouteExactActive('/users-listing/Guests') }">
                        <a class="nav-link">
                            <span class="menu-title">Guests</span>
                        </a>
                    </li>

                </ul>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/dashboard-reviews') }" (click)='moveToReviews()'>
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-star'></i></span>
                    <span class="menu-title">Reviews</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/update-profile') }" *ngIf="role != 'Administrator'"
                (click)='moveToUpdateProfile()'>
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title">Update Profile</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mm-active': isRouteExactActive('/change-password') }" (click)="moveToChangePassword()">
                <a  class="nav-link">
                    <span class="icon"><i class='bx bx-wallet'></i></span>
                    <span class="menu-title">Change Password</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active" (click)="logOut()">
                <a class="nav-link">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Log Out</span>
                </a>
            </li>
            <!-- <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/dashboard-bookmarks" class="nav-link">
                    <span class="icon"><i class='bx bx-heart'></i></span>
                    <span class="menu-title">Bookmarks</span>
                </a>
            </li>
             <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/dashboard-add-listings" class="nav-link">
                    <span class="icon"><i class='bx bx-plus-circle'></i></span>
                    <span class="menu-title">Add Listings</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/dashboard-invoice" class="nav-link">
                    <span class="icon"><i class='bx bx-certification'></i></span>
                    <span class="menu-title">Invoice</span>
                </a>
            </li>
            <li class="nav-item-title">
                Account
            </li>
            <li class="nav-item" routerLinkActive="active mm-active">
                <a routerLink="/dashboard-my-profile" class="nav-link">
                    <span class="icon"><i class='bx bxs-user-circle'></i></span>
                    <span class="menu-title">Profile</span>
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/" class="nav-link">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </a>
            </li>-->
        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->
