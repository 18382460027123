import { HttpClient } from '@angular/common/http';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { NavbarStyleTwoComponent } from '../navbar-style-two/navbar-style-two.component';

@Component({
  selector: 'app-paypal-success',
  standalone: true,
  imports: [NavbarStyleTwoComponent],
  templateUrl: './paypal-success.component.html',
  styleUrl: './paypal-success.component.scss',
})
export class PaypalSuccessComponent   extends SnappstayBaseComponent
{


  constructor(inject: Injector,private route: ActivatedRoute, private http: HttpClient) {
    super(inject);
    // Get paymentId and PayerId from query params
    const paymentId = this.route.snapshot.queryParamMap.get('paymentId');
    const payerId = this.route.snapshot.queryParamMap.get('PayerID');
    const bookigId = this.store.selectSnapshot(SnappstayState.GetBookingRequest).id
    // Execute payment on backend
    this.http
      .get(
        this.environmentVar.paymentUrl + `paypal/execute-payment?paymentId=${paymentId}&payerId=${payerId}&bookigId=${bookigId}`
      )
      .subscribe((response) => {
        console.log('Payment successful', response);
      });
  }
}
