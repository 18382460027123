<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <ng-container *ngIf="role != 'Guest'">
        <div class="row">
            <ng-container *ngIf="dashBoardCards$ | async ">

                <div *ngFor="let card of dashBoardCards$ | async" class="col-lg-3 col-md-6 col-sm-6">
                    <div class="stats-card-box">
                        <div class="icon-box">
                            <i [ngClass]="getIconClass(card.name)"></i>
                        </div>
                        <span class="sub-title">{{ card.name }}</span>
                        <h3>{{ card.count }}</h3>
                    </div>
                </div>
            </ng-container>
        </div>

    </ng-container>

    <section class="listing-area">

        <div class="row" *ngIf="role =='Administrator'">
            <div class="col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="home-user">
                            <div class="home-head-user">
                                <h2>Revenue</h2>
                                <div class="home-select">
                                    <div class="dropdown">
                                        <button class="btn btn-action btn-sm dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Monthly
                                        </button>
                                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item">Weekly</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item">Monthly</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item">Yearly</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown">
                                        <a class="delete-table bg-white" href="javascript:void(0);"
                                            data-bs-toggle="dropdown" aria-expanded="true">
                                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item"> View</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item"> Edit</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="chartgraph">
                                <div id="chart-view"><apx-chart [series]="chartOptionsOne.series"
                                        [chart]="chartOptionsOne.chart" [xaxis]="chartOptionsOne.xaxis"
                                        [stroke]="chartOptionsOne.stroke" [tooltip]="chartOptionsOne.tooltip"
                                        [dataLabels]="chartOptionsOne.dataLabels"
                                        [fill]="chartOptionsOne.fill"></apx-chart></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="home-user">
                            <div class="home-head-user">
                                <h2>Booking Summary</h2>
                                <div class="home-select">
                                    <div class="dropdown">
                                        <button class="btn btn-action btn-sm dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            Monthly
                                        </button>
                                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item">Weekly</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item">Monthly</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item">Yearly</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown">
                                        <a class="delete-table bg-white" href="javascript:void(0);"
                                            data-bs-toggle="dropdown" aria-expanded="true">
                                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item"> View</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);" class="dropdown-item"> Edit</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="chartgraph">
                                <div id="chart-booking"><apx-chart [series]="chartOptionsThree.series"
                                        [chart]="chartOptionsThree.chart" [dataLabels]="chartOptionsThree.dataLabels"
                                        [plotOptions]="chartOptionsThree.plotOptions" [yaxis]="chartOptionsThree.yaxis"
                                        [legend]="chartOptionsThree.legend" [fill]="chartOptionsThree.fill"
                                        [stroke]="chartOptionsThree.stroke" [tooltip]="chartOptionsThree.tooltip"
                                        [xaxis]="chartOptionsThree.xaxis"
                                        [colors]="chartOptionsThree.colors"></apx-chart></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-state" *ngIf="role == 'Host'" style="display: flex; justify-content: center; align-items: center; min-height: 65vh;">
            <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
        </div>
        
    </section>

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>