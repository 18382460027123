<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area header-image" *ngFor="let Content of pageTitleContent;"
    style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <!--   <h2>{{Content.title}}</h2>
          <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul> -->
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="about-content">
                        <h2>About Snappstay</h2>
                        <p>
                            Snappstay is an innovative online platform designed to offer a seamless experience for
                            travelers and property owners alike. Our goal is to provide a secure and convenient
                            marketplace for vacation rentals, short-term bookings, and more. Whether you're a homeowner
                            looking to list your property or a traveler searching for the perfect place to stay,
                            Snappstay is here to help you find what you need with confidence and ease.
                        </p>
                        <p>
                            Founded in 2021, Snappstay has grown into a trusted platform for travelers and property
                            owners around the globe. With a focus on user-friendly features, secure payment processing,
                            and top-notch customer support, we aim to redefine the travel and accommodation industry.
                        </p>
                    </div>
                    <br>
                    <div class="about-content">
                        <h2>Our Mission</h2>
                        <p>
                            At Snappstay, our mission is simple: to empower both travelers and property owners with the
                            tools they need to connect, communicate, and book with ease. We are committed to providing
                            the highest level of service and transparency in the travel industry.
                        </p>
                        <p>
                            Our platform offers users a seamless and secure booking experience. We aim to continuously
                            improve and expand our services, ensuring that every user has the best possible experience
                            when they choose Snappstay for their travel needs.
                        </p>
                        <p>
                            We are driven by the belief that travel should be easy, affordable, and transparent, making
                            it accessible for everyone around the world.
                        </p>
                    </div>
                    <br>
                    <div class="about-content">
                        <h2>Privacy and Security</h2>
                        <p>
                            We understand that your privacy and the protection of your personal information are
                            paramount. At Snappstay, we take every precaution to protect the data you share with us. Our
                            platform uses state-of-the-art encryption and security protocols to ensure that your
                            personal and payment information remains safe.
                        </p>
                        <p>
                            Our Privacy Policy outlines how we collect, use, and protect your data. We are committed to
                            ensuring that your personal information is handled responsibly and in compliance with global
                            data protection regulations.
                        </p>
                        <p>
                            Snappstay also allows users to control their data preferences. You can update your data
                            settings at any time through your user account or opt out of certain communications by
                            managing your preferences.
                        </p>
                    </div>
                    <br>
                    <div class="about-content">
                        <h2>Terms of Service</h2>
                        <div>
                            By using Snappstay, you agree to our Terms and Conditions, which govern the use of our
                            platform and all transactions conducted through the site. These Terms help ensure that all
                            parties—whether property owners or travelers—have a clear understanding of their rights and
                            responsibilities while using the platform.
                            <p>The Terms of Service include provisions regarding:</p>
                            <ul>
                                <li>Property listings and bookings</li>
                                <li>Payment processing and fees</li>
                                <li>Cancellation policies</li>
                                <li>User conduct and responsibilities</li>
                                <li>Dispute resolution procedures</li>
                            </ul>
                            <p>
                              We recommend reviewing the full Terms before using our platform to ensure you are fully
                              informed about the guidelines that govern your use of Snappstay.
                            </p>
                        </div>
                    </div>
                    <br>
                    <div class="about-content">
                        <h2>What We Offer</h2>
                        <div>
                            <p>
                              Snappstay offers a wide range of services to enhance your travel experience. Whether you're
                              looking for a cozy vacation rental, a luxurious getaway, or a family-friendly property, we
                              have something for everyone.
                            </p>
                            <p>Our offerings include:</p>
                            <ul>
                                <li><strong>Vacation Rentals:</strong> A variety of properties ranging from beach houses
                                    to urban apartments, perfect for every budget and preference.</li>
                                <li><strong>Secure Payment Processing:</strong> Our platform supports a secure and
                                    easy-to-use payment system, ensuring that all transactions are safe and
                                    straightforward.</li>
                                <li><strong>Property Management Tools:</strong> Easy-to-use tools for property owners to
                                    list, manage, and promote their properties.</li>
                                <li><strong>Customer Support:</strong> Our dedicated team is available 24/7 to assist
                                    with any inquiries or issues you may encounter during your booking or stay.</li>
                                <li><strong>Reviews and Ratings:</strong> Transparent reviews and ratings from past
                                    guests help travelers make informed decisions about where to stay.</li>
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="about-content">
                        <h2>Your Privacy and Data Protection</h2>
                        <p>
                            At Snappstay, your privacy is one of our top priorities. We ensure that your personal data
                            is handled with the utmost care and in compliance with relevant privacy regulations.
                        </p>
                        <p>
                            We do not share your personal data with third parties without your consent, except as
                            necessary to facilitate transactions or as required by law. Our Privacy Policy explains in
                            detail how we collect, use, and protect your data.
                        </p>
                        <p>
                            In addition, Snappstay implements robust security measures to protect against unauthorized
                            access, alteration, or destruction of your personal information. These measures include
                            encryption, secure payment systems, and regular security audits.
                        </p>
                    </div>
                    <br>
                    <div class="about-content">
                        <h2>Contact Us</h2>
                        <div class="d-flex flex-column">
                          <p>
                            If you have any questions, concerns, or feedback, we are here to help. Our customer support
                            team is available 24/7 to assist with your needs.
                          </p>
                          <p>
                            You can reach us via email, phone, or through our online support system.
                          </p>
                          <div class="d-flex" style="gap:10px;">
                            <strong>Email:</strong> <a href="mailto:info@snappstay.com">{{snappstayEmail}}</a>
                          </div>
                          <div class="d-flex" style="gap:10px;">
                            <strong>Phone:</strong> +1 (123) 456 7890
                          </div>
                          <div class="d-flex" style="gap:10px;">
                            <strong>Address:</strong> 175 5th Ave Premium Area, New York, NY 10010, United States
                          </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image" *ngFor="let Image of aboutImage;">
                    <img [src]="Image.img" alt="image">
                    <a href="{{Image.videoLink}}" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- <section class="funfacts-area pb-70">
    <app-funfacts></app-funfacts>
</section>

<section class="team-area pt-100 pb-70 bg-f9f9f9">
    <app-team></app-team>
</section> -->

<section class="how-it-works-area pt-100 pb-70">
    <app-how-it-works></app-how-it-works>
</section>

<!-- <div class="partner-area ptb-100 bg-f5f5f5">
    <app-partner></app-partner>
</div>

<section class="feedback-area bg-f9f9f9 ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</section> -->

<app-footer-style-two></app-footer-style-two>
