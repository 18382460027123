<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area header-image" *ngFor="let Content of pageTitleContent;"
    style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>{{Content.title}}</h2> -->
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul> -->
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content" *ngFor="let Content of aboutContent;">
                    <h2>Terms and Conditions</h2>
                    <span><strong>Last updated: 11.30.2022</strong></span>
                    <p>
                        By using or accessing Snappstay.com, a subdomain of any such websites, any mobile application
                        for such websites or any other website operated by us on which these Terms and Conditions are
                        posted via a link or otherwise (each referred to herein as a “Site”), you acknowledge and agree
                        that you are subject to the following terms and conditions, as well as our Privacy Policy, which
                        also governs your use of the Site and is incorporated by reference. These Terms and Conditions,
                        together with the Privacy Policy, shall be referred to as the “Terms.”
                    </p>
                    <p>
                      Please read these Terms carefully, as they contain important information about limitations of
                      liability and resolution of disputes through arbitration rather than court. By using this Site,
                      you signify your agreement to these Terms.
                    </p>
                    <br>
                    <h2>Binding Nature of Terms and Eligibility</h2>
                    <p>
                        A user’s reservation or use of a Snappstay.com site is bound by the terms and conditions on the
                        Snappstay.com site where the reservation is finalized (and not necessarily the site on which the
                        property was originally listed).
                    </p>
                    <p>
                      If there are any conflicts between the terms and conditions of the Snappstay.com site you found
                      the property on and the site where the reservation is finalized, the terms and conditions of the
                      latter will govern. If you do not fully agree to these Terms and any other terms posted or
                      linked to any Site, you are not authorized to access or use the Site.
                    </p>
                    <p>
                      Under these Terms, “use” or “access” of the Site includes any direct or indirect access to or
                        use of the Site, any cached version of the Site, or any content on the Site, regardless of how
                        obtained. Each Site is operated by Snappstay.com, Inc. Unless specified otherwise, the entity
                        controlling the Site you are accessing is referred to herein as “Snappstay.com,” “we,” “us,” or
                        “our.”
                    </p>
                    <p>
                      The term “you” refers to the user visiting the Site, listing a property, and/or requesting a
                        reservation on the Site. By using this Site, you represent that you are at least 18 years of age
                        and capable of entering legally binding contracts. We do not knowingly collect information from
                        anyone under 18 years of age.
                    </p>
                    <br>
                    <h2>The Site is a Venue and We are Not a Party to Any Rental Agreement</h2>
                    <p>
                        We urge all users to be responsible in their use of this Site and any transaction entered into
                        as a result of listing or renting a property. We do not own, manage, or contract for any
                        vacation rental property listed on the Site. The Site provides an online marketplace that allows
                        homeowners and property managers who advertise on the Site (each, a “member”) to offer specific
                        vacation or short-term rental properties for rent to potential renters (each, a “traveler”).
                    </p>
                    <p>
                      Members may include property owners or managers who originally advertised their properties on
                      another platform and whose listings have been redistributed on the Site. We also may offer
                      online booking tools or other services to facilitate communication or transactions between
                      users.
                    </p>
                    <p>
                      However, we are not a party to any rental or other agreement between users. This remains true
                        even if the Site facilitates a booking, provides ancillary products or services, or offers tools
                        to aid in communication. All aspects of an actual or potential transaction, including property
                        quality, condition, legality, and accuracy of listings, are solely the responsibility of the
                        involved users.
                    </p>
                    <p>
                      You acknowledge that you may be required to enter into additional agreements or terms and
                        conditions with other parties, and that Snappstay.com or its affiliates may impose additional
                        restrictions on your booking or product purchase.
                    </p>
                    <br>
                    <h2>User Responsibilities</h2>
                    <div>
                      <p>
                        Users agree that they are responsible for complying with all laws, rules, and regulations
                        applicable to their use of the Site, the tools, services, or products offered, and any
                        transactions entered into via the Site. This includes, but is not limited to:
                      </p>
                        <ul>
                            <li>Ensuring that their use of the Site does not violate any applicable laws or regulations.
                            </li>
                            <li>Abiding by all applicable rules, ordinances, and requirements related to tax
                                obligations, credit cards, data and privacy, permits or license requirements, zoning
                                ordinances, and safety compliance.</li>
                            <li>Complying with anti-discrimination and fair housing laws, where applicable.</li>
                        </ul>
                        <p>Members who list properties on the Site further agree to:</p>
                        <ul>
                            <li>Abide by all local laws and regulations applicable to their rental business and property
                                listings.</li>
                            <li>Accurately and honestly describe their properties and their rental terms in their
                                listings.</li>
                            <li>Properly handle and safeguard payment or other sensitive information provided by
                                travelers, in compliance with applicable legal and regulatory standards.</li>
                        </ul>
                        <p> While Snappstay.com takes certain measures to assist users in avoiding fraud or illegal
                          activities, users acknowledge that Snappstay.com is not liable for ensuring such prevention.
                          Users are encouraged to exercise due diligence when engaging in transactions through the Site.</p>
                    </div>
                    <br>
                    <h2>Limited License to Use the Site</h2>
                    <div>
                      <p>
                        Users are granted a limited, revocable, non-exclusive license to access the Site and the content
                        and services provided, solely for the following purposes:
                      </p>
                        <ul>
                            <li>Advertising a property</li>
                            <li>Searching for a property</li>
                            <li>Purchasing or researching products or services offered on the Site</li>
                            <li>Participating in an interactive area hosted on the Site</li>
                            <li>Any other purpose clearly stated on the Site</li>
                        </ul>
                        <p>
                          Any use of the Site that does not align with these purposes, or that is otherwise unauthorized
                        by Snappstay.com in writing, is expressly prohibited.
                        </p>
                    </div>
                    <br>
                    <h2>Unauthorized Uses of the Site</h2>
                    <div>
                      <p>The license to use the Site does not include the right to:</p>
                        <ul>
                            <li>Collect, aggregate, copy, duplicate, display, or create derivative works from the Site's
                                content without prior written permission.</li>
                            <li>Use data mining, robots, spiders, or similar tools to gather or extract data from the
                                Site, except for general-purpose search engines complying with our robots.txt file.</li>
                            <li>Engage in commercial use of the Site or its content, unless authorized by Snappstay.com
                                in writing.</li>
                            <li>Reproduce, upload, post, republish, distribute, or transmit any part of the Site's
                                content in any form without permission.</li>
                            <li>Deep-link to any part of the Site without express written permission.</li>
                            <li>Modify, translate, reverse engineer, or create derivative works from the Site or its
                                content.</li>
                            <li>Sell, transfer, or license any part of the Site to third parties without consent.</li>
                            <li>Use the Site for false, fraudulent, or misleading purposes, such as phishing or unlawful
                                activities.</li>
                            <li>Post or transmit content that is unlawful, threatening, libelous, obscene, defamatory,
                                or violates the rights of others.</li>
                        </ul>
                        <p>
                          If you encounter any content, activity, or communication on the Site that appears to violate
                        these restrictions, please report it to us via the “Contact Us” section.
                        </p>
                    </div>
                    <br>
                    <h2>Proprietary Rights and Downloading Information from the Site</h2>
                    <div>
                      <p>
                        The Site and all content and information available on it are protected by copyright as a
                        collective work and/or compilation, under applicable U.S. and international copyright laws and
                        conventions. By using the Site, you agree to:
                      </p>
                        <ul>
                            <li>Respect all copyright notices and restrictions contained on or related to the content
                                available on the Site.</li>
                            <li>Access the Site and its content only for personal, non-commercial use, except where
                                explicitly permitted by a valid subscription or agreement.</li>
                        </ul>
                        <p>
                          You are permitted to download, display, and print one copy of content from the Site for your
                          personal, non-commercial use as part of a rental inquiry or reservation process. However, such
                          content must not be modified and must retain the original copyright notice.
                        </p>
                        <p>
                          Any unauthorized reproduction, distribution, or modification of content or information from the
                          Site is expressly prohibited without prior written permission from Snappstay.com.
                        </p>
                    </div>
                    <br>
                    <h2>Your E-mail Address and Data; Privacy Policy</h2>
                    <div>
                      <p>
                        When you provide your email address, name, or other information to us in connection with your
                        use of the Site, you agree to:
                      </p>
                        <ul>
                            <li>Allow the Site and its affiliated websites to add your email address and other provided
                                information to their user database.</li>
                            <li>Receive promotional emails from the Site or its affiliated websites, unless you choose
                                to opt out.</li>
                        </ul>
                        <p>
                          You may opt out of receiving promotional emails at any time by following the instructions in the
                          email or updating your account preferences.
                        </p>
                        <p>
                          Please review our <a href="/privacy">Privacy Policy</a> for details on our email and data
                          collection practices, safeguards, and your rights concerning your information. By using the
                          Site, you acknowledge and agree to the terms outlined in our Privacy Policy.
                        </p>
                    </div>
                    <br>
                    <h2>Identity Verification</h2>
                    <div>
                      <p>
                        User verification on the internet is challenging, and we cannot guarantee the confirmation of
                        each user's identity. To help ensure trust, we encourage users to:
                      </p>
                        <ul>
                            <li>Communicate directly with travelers or members through the tools provided on the Site.
                            </li>
                            <li>Take reasonable measures to confirm the identity of other users, and for travelers,
                                verify the property details and booking terms.</li>
                        </ul>
                        <p>
                          You are responsible for maintaining the security of your password and account credentials.
                          Specifically, you agree to:
                        </p>
                        <ul>
                            <li>Keep your password and account ID secure and confidential.</li>
                            <li>Notify us immediately if you suspect unauthorized access to your account or email.</li>
                            <li>Change your password promptly if requested by us for security reasons.</li>
                        </ul>
                        <p>
                          If you share your account credentials or fail to secure them, you are responsible for any
                          activity performed using your account, including unauthorized transactions. Snappstay.com is not
                          liable for unauthorized transactions made using compromised account credentials.
                        </p>
                    </div>
                    <br>
                    <h2>Limitation on Communications and Use of Other Users’ Information; No Spam</h2>
                    <div>
                      <p>
                        You agree that, with respect to other users' personal information that you obtain through the
                        Site or Site-related communication, you are granted a license to use such information only for:
                      </p>
                        <ul>
                            <li>Site-related communications that are not unsolicited commercial messages.</li>
                            <li>Using services offered through the Site.</li>
                            <li>Facilitating a financial transaction between you and another user related to the purpose
                                of the Site (e.g., booking a property or purchasing a service).</li>
                        </ul>
                        <p>
                          Any other use of personal information requires express permission from the other user.
                        </p>
                        <p>
                          You may not use any information obtained through the Site for unlawful purposes or share it with
                          third parties without the consent of the other user. You also agree to protect other users’
                          personal information with at least the same level of care that you use to protect your own
                          confidential information.
                        </p>
                        <p>
                          We do not tolerate spam or unsolicited commercial communications. Therefore, you are not
                          authorized to add any user to your mailing list without their express consent. You also agree
                          not to use any tool or service on the Site to send spam or unsolicited messages.
                        </p>
                    </div>
                    <br>
                    <h2>Responsibility for Property Listings, Reviews, and Other User-Contributed Content</h2>
                    <div>
                      <p>
                        We do not pre-screen content posted by members, travelers, or other users on the Site, including
                        property listings, reviews, and other user-contributed content (e.g., participation in forums or
                        blogs). However, we are not responsible for any user-contributed content.
                      </p>
                      <p>We reserve the right to:</p>
                        <ul>
                            <li>Decline to allow the posting of content on the Site that does not meet our Content
                                Guidelines or violates these Terms.</li>
                            <li>Remove user-contributed content that we deem inappropriate, misleading, or in violation
                                of
                                these Terms or the Site's requirements.</li>
                            <li>Edit content to ensure compliance with our guidelines or formatting standards, as
                                necessary.
                            </li>
                        </ul>
                        <p>
                          We also reserve the right to remove any content that is brought to our attention through user
                          complaints or reports.
                        </p>
                        <p>
                          All property listings are the sole responsibility of the member (the property owner or manager),
                          and
                          we disclaim all liability arising from the accuracy or legality of these listings. It is the
                          member’s responsibility to ensure that their listings, including property descriptions, photos,
                          availability, and pricing, are accurate and up-to-date.
                        </p>
                    </div>
                    <br>
                    <h2>Disclaimers and Limitations of Liability</h2>
                    <div>
                      <p>
                        The Site and its content, including but not limited to listings, descriptions, property details,
                        photos, and reviews, are provided "as is" and without any warranties or guarantees. To the
                        fullest extent permitted by law, Snappstay.com and its affiliates disclaim all representations
                        or warranties, whether express or implied, regarding the accuracy, completeness, or reliability
                        of the Site’s content, services, or products.
                      </p>
                       <p>Snappstay.com does not warrant that:</p>
                        <ul>
                            <li>The Site will be error-free or uninterrupted.</li>
                            <li>The content on the Site is accurate, complete, or up-to-date.</li>
                            <li>Property descriptions or availability are free from errors or omissions.</li>
                            <li>The Site is free from viruses, malware, or other harmful components.</li>
                        </ul>
                        <p> Snappstay.com will not be liable for any loss, damage, or injury arising from the use of the
                          Site, including:</p>
                        <ul>
                            <li>Any errors or omissions in the content provided on the Site.</li>
                            <li>Any interruption or cessation of the services provided by the Site.</li>
                            <li>Any damages caused by a third party through the Site.</li>
                            <li>Any issues arising from transactions between members and travelers.</li>
                        </ul>
                        <p>
                          In no event will Snappstay.com be liable for indirect, incidental, special, consequential, or
                          punitive damages, including but not limited to, lost profits, lost data, or business
                          interruption, even if Snappstay.com was advised of the possibility of such damages.
                        </p>
                    </div>
                    <br>
                    <h2>Indemnification</h2>
                    <div>
                      <p>
                        You agree to indemnify, defend, and hold harmless Snappstay.com, its affiliates, officers,
                        directors, employees, agents, and licensors from and against any and all claims, losses,
                        damages, liabilities, costs, or expenses, including reasonable attorney's fees, arising out of:
                      </p>
                        <ul>
                            <li>Your use or misuse of the Site or services offered by the Site.</li>
                            <li>Your violation of these Terms or any other policies, rules, or guidelines set by
                                Snappstay.com.</li>
                            <li>Your violation of any applicable laws, rules, or regulations.</li>
                            <li>Your infringement of any intellectual property or privacy rights of third parties.</li>
                        </ul>
                        <p>
                          This indemnity obligation will survive the termination of these Terms and your use of the Site.
                        </p>
                    </div>
                    <br>
                    <h2>Governing Law and Dispute Resolution</h2>
                    <p>
                        These Terms are governed by and construed in accordance with the laws of the jurisdiction in
                        which Snappstay.com is based, without regard to its conflict of law principles. You agree that
                        any disputes arising out of or in connection with these Terms or your use of the Site will be
                        resolved exclusively in the courts located in the jurisdiction where Snappstay.com is based.
                    </p>
                    <p>
                      If any dispute arises, you agree to attempt to resolve the dispute informally by contacting us
                        first. If the dispute cannot be resolved informally, it will be submitted to binding
                        arbitration. The arbitration shall be conducted by a single arbitrator in accordance with the
                        rules of the relevant arbitration association, and any judgment on the award rendered by the
                        arbitrator may be entered in any court of competent jurisdiction.
                    </p>
                    <p>
                      You agree that any claim or cause of action arising out of or related to the use of the Site
                      must be filed within one year after the cause of action arises. Otherwise, such claim or cause
                      of action will be permanently barred.
                    </p>
                    <br>
                    <h2>Changes to the Terms</h2>
                    <p>
                        Snappstay.com reserves the right to modify or update these Terms at any time. Any changes to the
                        Terms will be posted on this page with an updated “Last Updated” date. By continuing to use the
                        Site after such changes, you agree to the updated Terms. It is your responsibility to review the
                        Terms periodically to stay informed of any changes.
                    </p>
                    <p>
                      If we make material changes to these Terms, we may provide additional notice, such as via email
                      or through a notice on the Site. However, your continued use of the Site after such changes
                      constitutes your acceptance of the updated Terms.
                    </p>
                    <br>
                    <h2>Termination</h2>
                    <div>
                      <p>
                        Snappstay.com reserves the right to terminate or suspend your access to the Site, without prior
                        notice, if you violate any of these Terms or engage in activities that may harm the interests of
                        Snappstay.com or other users. Termination of your account may be implemented at our sole
                        discretion and may include:
                      </p>
                        <ul>
                            <li>Revoking your ability to access the Site and use its services.</li>
                            <li>Removing any content or information you may have posted on the Site.</li>
                            <li>Blocking any email addresses or other information associated with your account.</li>
                        </ul>
                        <p>
                          You may also terminate your account at any time by notifying Snappstay.com and discontinuing use
                          of the Site.
                        </p>
                       <p>
                        Upon termination, your right to use the Site will immediately cease, but any outstanding
                        obligations or liabilities you may have incurred under these Terms will survive. Additionally,
                        provisions regarding intellectual property, indemnification, limitations of liability, and
                        dispute resolution will remain in effect after termination.
                       </p>
                    </div>
                    <br>
                    <h2>Miscellaneous</h2>
                    <p>
                        These Terms represent the entire agreement between you and Snappstay.com regarding your use of
                        the Site and supersede any prior agreements or understandings, whether written or oral, related
                        to the same subject matter.
                    </p>
                    <p>
                      If any provision of these Terms is determined to be invalid or unenforceable by a court of
                        competent jurisdiction, such provision will be modified or deleted to the extent necessary to
                        make it enforceable, and the remaining provisions will continue in full force and effect.
                    </p>
                    <p>
                      No waiver of any provision of these Terms will be deemed a further or continuing waiver of such
                        provision or any other provision, and Snappstay.com's failure to assert any right or provision
                        under these Terms will not constitute a waiver of such right or provision.
                    </p>
                    <p>
                      You may not assign or transfer your rights and obligations under these Terms to any third party
                        without Snappstay.com's prior written consent. Snappstay.com may assign or transfer its rights
                        and obligations without restriction.
                    </p>
                    <p>These Terms are binding upon your heirs, executors, administrators, successors, and assigns.</p>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image" *ngFor="let Image of aboutImage;">
                    <img [src]="Image.img" alt="image">
                    <a href="{{Image.videoLink}}" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End About Area -->

<app-footer-style-two></app-footer-style-two>
