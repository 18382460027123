import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "enumToArray",
  standalone: true
})
export class EnumToArrayPipe implements PipeTransform {

  transform(data: Object): { key: unknown, value: unknown }[] {
    return Object.entries(data).map(([key, value]) => ({ key: key, value: value }));
  }

}
