import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { lastValueFrom } from 'rxjs';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { PaginationReviewrRequest } from 'src/app/models/request-models/pagination-review-response';
import { SetReview } from 'src/app/state/snappstay.action';
import { Review } from 'src/app/models/listing-model/review';

@Component({
  selector: 'app-feedback-style-one',
  templateUrl: './feedback-style-one.component.html',
  styleUrls: ['./feedback-style-one.component.scss'],
  standalone: true,
  imports: [CommonModule, CarouselModule],
})
export class FeedbackStyleOneComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  singleFeedbackBox: any[] = [];
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    const request: PaginationReviewrRequest = {
      propertyProfileId: null,
      pageNumber: null,
      pageSize: null,
      sortColumn: 'Id',
      sortOrder: null,
      search: null,
    };
    this.GetAllReview(request);
  }

  async GetAllReview(request: PaginationReviewrRequest) {
    try {
      const profileReview$ = this.reviewApiService.GetAllReview(request);
      const profileReview = (await lastValueFrom(profileReview$, {
        defaultValue: undefined,
      })) as Review[];
      if (profileReview) {
        this.store.dispatch(new SetReview(profileReview));
        profileReview.forEach((review) => {
          const obj = {
            paragraph: review.comment,
            authorImg: review.reviewerPic,
            authorName: review.reviewerName,
            rating: [],
          };
          for (let i = 0; i < review.rating; i++) {
            obj.rating.push({
              icon: 'bx bxs-star',
            });
          }
          this.singleFeedbackBox.push(obj);
        });
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,positionClass: 'toast-bottom-right',
        });
      }

      this.store.dispatch(new SetReview(undefined));
    } finally {
    }
  }
  sectionTitle = [
    {
      title: 'What Our Users Say About Us',
      paragraph:
        'Hear from our users about their experiences with us. From exceptional service to memorable experiences, we’re proud to have made a positive impact on so many journeys. Explore their stories and see how we’re helping travelers create unforgettable memories.',
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
}
