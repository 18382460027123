import { Component, OnInit } from '@angular/core';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        NavbarStyleOneComponent,
        FooterStyleTwoComponent
    ]
})
export class CartComponent implements OnInit {

        

    ngOnInit(): void {}
    
    pageTitleContent = [
        {
            title: 'Cart',
            backgroundImage: 'assets/img/page-title/page-title2.jpg'
        }
    ]

}