import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private static promise: Promise<void>;

  load(): Promise<void> {
    if (!GoogleMapsLoaderService.promise) {
      GoogleMapsLoaderService.promise = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDQ7kTX_tnqAEkvG4T_Ab_He1Qu6BXOxXg&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject('Google Maps script could not be loaded');
        document.body.appendChild(script);
      });
    }
    return GoogleMapsLoaderService.promise;
  }
}
