import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import {
  PaginationFilterInput,
  ProviderEarningsPaginationFilterInputDTO,
} from 'src/app/models/request-models/pagination-review-response';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { WalletInfo } from 'src/app/models/wallet-info.model';
import { ProviderEarnings } from 'src/app/models/provider-earnings.model';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { DashBoardCards } from 'src/app/models/dashboard-cards';

@Injectable({
  providedIn: 'root',
})
export class WalletApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getWalletInfo(filter: PaginationFilterInput): Observable<WalletInfo[]> {
    return this.http
      .get<ApiResponse<WalletInfo[]>>(
        `${this.apiUrl}${ApplicationApiUrls.GetWalletInfo}`,
        { params: this.getPaginationParams(filter) }
      )
      .pipe(
        map((response) => response.result!),
           catchError((err) => {
          throw err;
        })
      );
  }

  getAllSalesTransactions(filter: PaginationFilterInput): Observable<any[]> {
    return this.http
      .get<ApiResponse<any[]>>(
        `${this.apiUrl}${ApplicationApiUrls.GetAllSalesTransactions}`,
        { params: this.getPaginationParams(filter) }
      )
      .pipe(
        map((response) => response.result!),
           catchError((err) => {
          throw err;
        })
      );
  }

  getAllProviderSale(
    filter: ProviderEarningsPaginationFilterInputDTO
  ): Observable<any[]> {
    return this.http
      .get<ApiResponse<any[]>>(
        `${this.apiUrl}${ApplicationApiUrls.GetAllProviderSale}`,
        { params: this.getPaginationParams(filter) }
      )
      .pipe(
        map((response) => response.result!),
           catchError((err) => {
          throw err;
        })
      );
  }

  getAllProviderEarnings(
    filter: ProviderEarningsPaginationFilterInputDTO
  ): Observable<BaseGridResponse<ProviderEarnings[]>> {
    return this.http
      .get<BaseGridResponse<ProviderEarnings[]>>(
        `${this.apiUrl}${ApplicationApiUrls.GetAllProviderEarnings}`,
        { params: this.getPaginationParams(filter) }
      )
      .pipe(
        map((response) => response),
           catchError((err) => {
          throw err;
        })
      );
  }

  hostRemainingWalletBalance(): Observable<number> {
    return this.http
      .get<number>(
        `${this.apiUrl}${ApplicationApiUrls.HostRemainingWalletBalance}`
      )
      .pipe(
        map((response) => response),
           catchError((err) => {
          throw err;
        })
      );
  }

  walletHostCard(): Observable<DashBoardCards[]> {
    return this.http
      .get<ApiResponse<DashBoardCards[]>>(
        `${this.apiUrl}${ApplicationApiUrls.WalletHostCard}`
      )
      .pipe(
        map((response) => response.result!),
           catchError((err) => {
          throw err;
        })
      );
  }

  private getPaginationParams(filter: any): HttpParams {
    let params = new HttpParams();
    Object.keys(filter).forEach((key) => {
      if (filter[key] !== undefined && filter[key] !== null) {
        params = params.set(key, filter[key].toString());
      }
    });
    return params;
  }

}
