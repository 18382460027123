import { ApplicationConfig } from '@angular/core';
import { importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app-routing';
import { provideStore } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { SnappstayState } from './state/snappstay.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { HttpDefaultInterceptor } from './utils/interceptor/default.interceptor';
import { HttpTokenInterceptor } from './utils/interceptor/token.interceptor';
import { LoadingInterceptor } from './utils/interceptor/loading.service';
import { provideToastr } from 'ngx-toastr';
import { StarRatingModule } from 'angular-star-rating';
import { provideAuth0 } from '@auth0/auth0-angular';
import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      NgxsStoragePluginModule.forRoot({
        storage: StorageOption.LocalStorage,
        keys: [SnappstayState],
      }),
      NgxsLoggerPluginModule.forRoot({ disabled: true }),   
      StarRatingModule.forRoot()
    ]),
    provideAuth0({
      domain: 'dev-hkbjgope0uzwp5su.us.auth0.com',
      clientId: 'v8HQDUQIz5172f6xmZz08W4BOLl87LDN',
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }),
    provideRouter(routes),
    provideAnimations(),
    provideToastr(),
    provideHttpClient(),

    provideStore(),
    provideHttpClient(
      withInterceptorsFromDi()
     ),
     {
       provide: HTTP_INTERCEPTORS,
       useClass: HttpTokenInterceptor,
       multi: true,
     },
     {
       provide: HTTP_INTERCEPTORS,
       useClass: HttpDefaultInterceptor,
       multi: true,
     },
     {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    }
  ],
};
