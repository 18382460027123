import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Message } from 'src/app/models/message';
import { SendMessage } from 'src/app/models/request-models/send-message';
import { UserChats } from 'src/app/models/user-chats';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private apiUrl = `${environment.apiUrl}/Messages`;

  constructor(private http: HttpClient) {}

  // Sends a text message
  sendMessage(dto: SendMessage): Observable<Message> {
    return this.http.post<Message>(`${this.apiUrl}/SendMessage`, dto).pipe(
      map((response) => response),
      catchError((error) => {
        throw new Error('An error occurred while sending the message.');
      })
    );
  }
  GetUsersWithChatAsync(id : number): Observable<UserChats[]>{
    return this.http.get<UserChats[]>(`${this.apiUrl}/GetUsersWithChat?userId=${id}`).pipe(
        map((response) => response),
        catchError((error) => {
          throw new Error('An error occurred while retrieving messages.');
        })
      );
  }
  // Retrieves messages between a sender and receiver
  getMessages(senderId: number, receiverId: number): Observable<Message[]> {
    return this.http.get<Message[]>(`${this.apiUrl}/${senderId}/${receiverId}`).pipe(
      map((response) => response),
      catchError((error) => {
        throw new Error('An error occurred while retrieving messages.');
      })
    );
  }

  // Marks a message as read
  markMessageAsRead(messageId: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${messageId}/read`, {}).pipe(
      catchError((error) => {
        throw new Error('An error occurred while marking the message as read.');
      })
    );
  }
}
