<app-dashboard-sidemenu></app-dashboard-sidemenu>

<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <ng-container *ngIf="role != 'Guest'">
        <div class="row">
            <ng-container *ngIf="dashBoardCards$ | async ">

                <div *ngFor="let card of dashBoardCards$ | async" class="col-lg-4 col-md-6 col-sm-6">
                    <div class="stats-card-box">
                        <div class="icon-box">
                            <i [ngClass]="getIconClass(card.name)"></i>
                        </div>
                        <span class="sub-title">{{ card.name }}</span>
                        <h3>{{ card.count }}</h3>
                    </div>
                </div>
            </ng-container>
        </div>

    </ng-container>

    <div class="row">
        <div class="col">
          <input
            type="text"
            class="form-control"
            (keyup)="applyFilter($event)"
            placeholder="Filter by any field"
          />
        </div>
      </div>
      
      <div *ngIf="filteredEarnings.length > 0; else noData">
        <table class="listing-table">
          <thead style="padding: 0px 0px 0px 0px;">
            <tr style="height: 50px;">
              <th (click)="onSortChange('propertyProfileName')" class="heading">Property Name</th>
              <th (click)="onSortChange('customerName')" class="heading">Customer Name</th>
              <th (click)="onSortChange('totalAmount')" class="heading">Total Amount</th>
              <th (click)="onSortChange('status')" class="heading">Status</th>
              <th (click)="onSortChange('paymentStatus')" class="heading">Payment Status</th>
              <th (click)="onSortChange('bookingDate')" class="heading">Booking Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let earning of filteredEarnings | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems:totalRecords }" style="height: 50px;"
            >
              <td>{{ earning.propertyProfileName }}</td>
              <td>{{ earning.customerName }}</td>
              <td>{{ earning.totalAmount | currency }}</td>
              <td>{{ earning.status }}</td>
              <td>{{ earning.paymentStatus }}</td>
              <td>{{ earning.bookingDate | date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <ng-template #noData>
        <div class="empty-state">
          <img src="assets/images/no-data.png" alt="No Data" class="no-data-image" />
        </div>
      </ng-template>
      
      <div class="pagination-area text-center">
        <pagination-controls
          (pageChange)="onPageChange($event)"
          itemsPerPage="pageSize"
          currentPage="currentPage"
          totalItems="totalRecords"
        ></pagination-controls>
      </div>
      
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->