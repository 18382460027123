import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { GetPropertyRequest } from 'src/app/state/snappstay.action';
import { GoogleMapsLoaderService } from 'src/app/services/google-search/google-api-service';

@Component({
  selector: 'app-hometwo-banner',
  templateUrl: './hometwo-banner.component.html',
  styleUrls: ['./hometwo-banner.component.scss'],
  standalone: true,
  imports: [
    NgxTypedJsModule,
    CommonModule,
    RouterLinkWithHref,
    RouterLinkActive,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ScrollingModule,
    AsyncPipe,
  ],
})
export class HometwoBannerComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  searchForm: FormGroup;
  bannerContent = [
    {
      title: 'Find Nearby',
      paragraph: 'Explore top-rated properties and more...',
      popularSearchList: [
        { title: 'Restaurants', link: 'grid-listings-left-sidebar' },
        { title: 'Events', link: 'grid-listings-left-sidebar' },
        { title: 'Clothing', link: 'grid-listings-left-sidebar' },
        { title: 'Bank', link: 'grid-listings-left-sidebar' },
        { title: 'Fitness', link: 'grid-listings-left-sidebar' },
        { title: 'Bookstore', link: 'grid-listings-left-sidebar' },
      ],
    },
  ];

  bannerImage = [
    {
      img: 'assets/img/banner-img1.png',
    },
  ];

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private googleMapsLoader: GoogleMapsLoaderService
  ) {
    super(injector);
  }
  ngOnInit(): void {
    // Initialize the reactive form
    this.searchForm = this.fb.group({
      where: ['', Validators.required],
    });
    this.googleMapsLoader
      .load()
      .then(() => {
        this.initializeAutocomplete();
      })
      .catch((err) => {
        console.error('Error loading Google Maps', err);
      });
  }
  initializeAutocomplete(): void {
    const input = document.getElementById('autocomplete') as HTMLInputElement;

    if (!input) {
      console.error('Autocomplete input element not found');
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['geocode'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.name) {
        this.searchForm.controls['where'].patchValue(place.name);
      }
    });
  }

  GetListingApiCalling() {
    const formData = this.searchForm.getRawValue();
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      city: formData.where != '' ? formData.where : undefined,
      isLuxury: this.IsLuxury,
    };
    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.moveToMoreListing();
  }

  // Handle form submission
  onSubmit(): void {
    if (this.searchForm.valid) {
      console.log(this.searchForm.value);
    }
  }
}
