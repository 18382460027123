import { CommonModule } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { GetPropertyRequest, SetListing } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-hometwo-listings',
  templateUrl: './hometwo-listings.component.html',
  styleUrls: ['./hometwo-listings.component.scss'],
  standalone: true,
  imports: [CarouselModule, CommonModule, RouterLinkWithHref, RouterLinkActive],
})
export class HometwoListingsComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  singleListingsBox: any[] = [];
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.IsLuxury = false;
    this.getRoles();
    this.GetListingApiCalling();
    this.listingsSub();
  }

  private GetListingApiCalling() {
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      isLuxury: this.IsLuxury,
    };
    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }

  listingsSub() {
    const listingSub = this.listings$.subscribe((item) => {
      if (item) {
        this.singleListingsBox = [];
        item.result.forEach((property: ListingResponse) => {
          const images: any[] = [];
          if (property.propertyPicture) {
            property.propertyPicture.forEach((image) => {
              images.push({
                img: `${this.environmentVar.blobUrl}` + image.path,
              });
            });
          }
          const newList = {
            id: property.id,
            mainImg: images,
            categoryLink: 'single-listings',
            category: property.propertyCategory,
            bookmarkLink: 'single-listings',
            location: `${property.city}-${property.country}`,
            title: property.title,
            price: property.pricePerNight,
            detailsLink: 'single-listings',
            authorImg: '',
            openORclose: 'Open Now',
            extraClass: 'status-open',
            authorName: '',
            rating: [],
            ratingCount:
              property.numberOfReviews != 0 ? property.numberOfReviews : null,
          };
          for (let i = 0; i < property.starRatings; i++) {
            newList.rating.push({
              icon: 'bx bxs-star',
            });
          }
          this.singleListingsBox.push(newList);
        });
      }
    });
    this.subscriptions.push(listingSub);
  }


  sectionTitle = [
    {
      title: 'Most Visited Listings',
      paragraph:
        'Explore Our Most Visited Rental Listings – Top Picks Just for You!.Find the Perfect Rental Property Among the Most Sought-After Homes!',
    },
  ];
  bottomButton = [
    {
      text: 'More Listings',
      link: 'grid-listings-left-sidebar',
    },
  ];
  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplayHoverPause: true,
    autoplay: true,
    mouseDrag: false,
    items: 1,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
  };
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(new SetListing(undefined));
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
