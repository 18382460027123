import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { Review } from 'src/app/models/listing-model/review';
import { SetReview } from 'src/app/state/snappstay.action';
import { PaginationReviewrRequest } from 'src/app/models/request-models/pagination-review-response';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { LuxonDatePipe } from '../../../../utils/pipes/luxon-date.pipe';

@Component({
  selector: 'app-dashboard-reviews',
  templateUrl: './dashboard-reviews.component.html',
  styleUrls: ['./dashboard-reviews.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    RouterLinkWithHref,
    RouterLinkActive,
    NgxPaginationModule,
    LuxonDatePipe,
  ],
})
export class DashboardReviewsComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  singleFeedbackBox: any[] = [];
  currentPage = 1;
  pageSize = 5;
  totalRecords = 0;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.fetchReviews();
  }

  async fetchReviews() {
    const request: PaginationReviewrRequest = {
      propertyProfileId: null,
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: 'Rating',
      sortOrder: 'desc',
      search: null,
    };

    try {
      const profileReview$ = this.reviewApiService.GetAllReview(request);
      const profileReview = (await lastValueFrom(profileReview$, {
        defaultValue: undefined,
      })) as BaseGridResponse<Review[]>;

      if (profileReview) {
        this.store.dispatch(new SetReview(profileReview.result));
        this.totalRecords = profileReview.totalRecords;

        this.singleFeedbackBox = profileReview.result.map((review) => ({
          paragraph: review.comment,
          userImage: review.reviewerPic
            ? `${this.environmentVar.blobUrl}` + review.reviewerPic
            : 'assets/img/download.png',
          userName: review.reviewerName,
          rating: Array(review.rating).fill({ icon: 'bx bxs-star' }),
          date: review.reviewDate,
          listingsName: review.propertyProfileName,
          text: review.comment,
        }));
      }
    } catch (error) {
      const message =
        error?.error?.message ||
        ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      this.toastr.error(message, 'Please try again later', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });

      this.store.dispatch(new SetReview(undefined));
    }
  }

  onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.fetchReviews();
  }

  breadcrumb = [
    {
      title: 'Reviews',
    },
  ];
}
