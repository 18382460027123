import { Component } from '@angular/core';
import { NavbarStyleTwoComponent } from '../navbar-style-two/navbar-style-two.component';

@Component({
  selector: 'app-paypal-cancel',
  standalone: true,
  imports: [NavbarStyleTwoComponent],
  templateUrl: './paypal-cancel.component.html',
  styleUrl: './paypal-cancel.component.scss'
})
export class PaypalCancelComponent {

}
