<app-dashboard-sidemenu></app-dashboard-sidemenu>

<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="heading-container" style="margin-top: 10px;">
        <h2 style="font-weight: bold; color: #333;">Listings</h2>
    </div>
    <section class="listing-section">
        <div class="row mb-3">
            <div class="col">
                <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="Filter by any field">
            </div>
        </div>
    
        <div *ngIf="singleListingsBox?.length > 0; else noData" class="listing-section">
            <table class="listing-table">
                <!-- Table Header -->
                <thead>
                    <tr>
                        <th class="heading">Image</th>
                        <th (click)="onSortChange('title')" class="heading">Title</th>
                        <th (click)="onSortChange('category')" class="heading">Category</th>
                        <th (click)="onSortChange('location')" class="heading">Location</th>
                        <th (click)="onSortChange('rating')" class="heading" style="text-align: center; width: 130px;">Rating</th>
                        <th (click)="onSortChange('price')" class="heading">Price</th>
                        <th *ngIf="status == 'Pending' && role == 'Administrator'" class="heading">Actions</th>
                      
                    </tr>
                </thead>
    
                <!-- Table Body -->
                <tbody>
                    <tr *ngFor="let Content of singleListingsBox | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }">
                        <!-- Image Column with Carousel -->
                        <td style="width: 220px;">
                            <div class="carousel-container" *ngIf="Content.mainImg?.length > 0">
                                <div class="listings-image" [style.background-image]="'url(' + Content.mainImg[0].img + ')'">
                                    <img [src]="Content.mainImg[0].img" alt="Listing image">
                                </div>
                            </div>
                        </td>
    
                        <!-- Title Column -->
                        <td style="width: 250px;" (click)="moveToSingleListing(Content.detailsLink, Content.id)">
                            {{ Content.title || '-' }}
                        </td>
    
                        <!-- Category Column -->
                        <td style="width: 130px;">{{ Content.category || '-' }}</td>
    
                        <!-- Location Column -->
                        <td>{{ Content.location || '-' }}</td>
    
                        <!-- Rating Column -->
                        <td *ngIf="Content.ratingCount != null">
                            <div class="rating-container" style="text-align: center; width: 130px; vertical-align: middle; padding: 8px; color: #efc02f;">
                                <i *ngFor="let Star of Content.rating" [ngClass]="Star.icon"></i>
                                <span>({{ Content.ratingCount }})</span>
                            </div>
                        </td>
                        <td *ngIf="Content.ratingCount == null" style="text-align: center; vertical-align: middle; padding: 8px; width: 130px;">
                            -
                        </td>
    
                        <!-- Price Column -->
                        <td style="width: 100px;">${{ Content.price || '-' }}</td>
    
                        <!-- Actions Column for Approve/Reject -->
                        <td style="width: 120px;" *ngIf="status == 'Pending' && role == 'Administrator'">
                            <div class="button-container">
                                <button class="approve-btn" (click)="approve(Content.id)">Approve</button>
                                <button class="reject-btn" (click)="reject(Content.id)">Reject</button>
                            </div>
                        </td>
    
                        <!-- Actions Column for Context Menu -->
                        <td class="action-column">
                            <button class="context-btn" (click)="toggleContextMenu(Content)" aria-haspopup="true" aria-expanded="Content.showMenu">&#x22EE;</button>
    
                            <!-- Context Menu -->
                            <div *ngIf="Content.showMenu" class="context-menu">
                                <ul class="context-menu-list">
                                    <li *ngIf="status != 'Approved' && role == 'Host'" (click)="moveToAddListingById(Content.id)">Edit</li>
                                    <li (click)="moveToSingleListing(Content.detailsLink, Content.id)">View Details</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <!-- Empty State within tbody -->
        <ng-template #noData>
            <table class="listing-table">
                <tbody>
                    <tr>
                        <td colspan="8" class="text-center">
                            <div class="empty-state" style="display: flex; justify-content: center; align-items: center; min-height: 65vh; width: 100%;">
                                <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    
        <!-- Pagination Controls -->
        <div class="pagination-area text-center">
            <pagination-controls id="server" itemsPerPage="pageSize" currentPage="currentPage" totalItems="totalRecords" (pageChange)="onPageChange($event)">
            </pagination-controls>
        </div>
    </section>

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>