<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="heading-container" style="margin-top: 10px;">
        <h2 style="font-weight: bold; color: #333;">Provider Earnings</h2>
    </div>

    <section class="listing-section">
        <div class="row mb-3">
            <div class="col">
                <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="Filter by any field">
            </div>
        </div>

        <div *ngIf="providerEarnings.length > 0; else noData">
            <table class="listing-table">
                <thead>
                    <tr>
                        <th (click)="onSortChange('propertyProfileName')" class="heading">Property Name</th>
                        <th (click)="onSortChange('customerName')" class="heading">Customer Name</th>
                        <th (click)="onSortChange('totalAmount')" class="heading">Total Amount</th>
                        <th (click)="onSortChange('status')" class="heading">Status</th>
                        <th (click)="onSortChange('paymentStatus')" class="heading">Payment Status</th>
                        <th (click)="onSortChange('bookingDate')" class="heading">Booking Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let earning of providerEarnings | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }"
                        style="height: 50px;">
                        <td>{{ earning.propertyProfileName }}</td>
                        <td>{{ earning.customerName }}</td>
                        <td>{{ earning.totalAmount | currency }}</td>
                        <td>{{ earning.status }}</td>
                        <td>{{ earning.paymentStatus }}</td>
                        <td>{{ earning.bookingDate | date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Empty State -->
        <ng-template #noData>
            <div class="empty-state"
                style="display: flex; justify-content: center; align-items: center; min-height: 65vh; width: 100%;">
                <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
            </div>
        </ng-template>

        <!-- Pagination Controls -->
        <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="pagination-area text-center">
                <pagination-controls id="server" (pageChange)="onPageChange($event)" itemsPerPage="pageSize"
                    currentPage="currentPage" totalItems="totalRecords"></pagination-controls>
            </div>
        </div>
    </section>
</div>