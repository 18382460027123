<!-- Start Category Area -->
<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-2 col-sm-6 col-md-4 align-items-center" *ngFor="let Content of singleCategoryBox;" style="cursor: pointer;">
            <div class="single-category-box" (click)="getPropertyByCategory(Content.title)">
                <div  class="icon">
                    <img [src]="Content.icon" alt="image" style="width: 60%;">
                </div>
                <h4>{{Content.title}}</h4>
                <!-- <span>{{Content.numberOfPlaces}}</span> -->
                <!-- <a routerLink="/{{Content.link}}" class="link-btn"></a> -->
            </div>
        </div>
    </div>
</div>
<!-- End Category Area -->
