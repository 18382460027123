import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "enumLocationTypeToString",
  standalone: true
})
export class EnumLocationTypeToString implements PipeTransform {
  transform(value: any, enumType: any): string {
   const x= Object.keys(enumType)[Object.values(enumType).indexOf(value)];

    return x;
  }

}