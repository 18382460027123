import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { CopyrightsComponent } from './copyrights/copyrights.component';
import { DashboardNavbarComponent } from '../../common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from '../../common/dashboard-sidemenu/dashboard-sidemenu.component';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { lastValueFrom, Observable } from 'rxjs';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { GetPropertyRequest, SetDashBoardCards } from 'src/app/state/snappstay.action';
import { Select } from '@ngxs/store';
import { DashBoardCards } from 'src/app/models/dashboard-cards';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    NgApexchartsModule,
    RouterLinkWithHref,
    RouterLinkActive
  ]
})
export class DashboardComponent extends SnappstayBaseComponent implements OnInit, OnDestroy {
  @Select(SnappstayState.GetDashBoardCards)
  dashBoardCards$!: Observable<DashBoardCards[]>;

  chartOptionsOne: any;
  chartOptionsThree: any;

  constructor(inject: Injector) {
    super(inject);
  }

  ngOnInit(): void {
    this.chartOptionsOne = {
      series: [
        { name: 'series1', data: [11, 32, 45, 32, 34, 52, 41], colors: [' #4169E1'] },
        { name: 'series2', colors: [' #4169E1'], data: [31, 40, 28, 51, 42, 109, 100] }
      ],
      chart: { height: 350, type: 'area' },
      fill: {
        colors: [' #4169E1', '#4169E1'],
        type: 'gradient',
        gradient: { shade: 'dark', type: 'horizontal', shadeIntensity: 0.1, opacityFrom: 0.5, opacityTo: 0.5, stops: [0, 50, 100] }
      },
      dataLabels: { enabled: false },
      stroke: { width: 1, curve: 'smooth', dashArray: [0, 8, 5], opacityFrom: 0.5, opacityTo: 0.5, colors: [' #4169E1'] },
      xaxis: { type: 'Month', categories: ['Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July'] }
    };

    this.chartOptionsThree = {
      colors: ['#4169E1'],
      series: [{ name: 'Received', type: 'column', data: [70, 150, 80, 180, 150, 175, 201, 60, 200, 120, 190, 160, 50] }],
      chart: { type: 'bar', height: 350, toolbar: { show: false } },
      plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, colors: ['transparent'] },
      xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'] },
      yaxis: { title: { text: '$ (thousands)' } },
      fill: { opacity: 1 }
    };

    if (this.role == 'Administrator') {
      this.CardsForAdmin();
    } else {
      this.DashBoardCardsForHost();
    }
  }

  getIconClass(cardName: string): string {
    switch (cardName) {
      case 'Pending': return 'bx bx-map-alt';
      case 'Rejected': return 'bx bx-help-circle';
      case 'Approved': return 'bx bx-star';
      case 'Bookmarked': return 'bx bx-heart';
      default: return 'bx bx-user';
    }
  }

  async CardsForAdmin() {
    try {
      const dashBoardCards$ = this.dashboardApiService.DashBoardCardsForAdmin();
      const dashBoardCards = await lastValueFrom(dashBoardCards$);
      if (dashBoardCards) {
        this.store.dispatch(new SetDashBoardCards(dashBoardCards));
      }
    } catch (error) {
      console.error('Error fetching dashboard cards for Admin:', error);
    }
  }

  async DashBoardCardsForHost() {
    try {
      const dashBoardCards$ = this.dashboardApiService.DashBoardCardsForHost();
      const dashBoardCards = await lastValueFrom(dashBoardCards$);
      if (dashBoardCards) {
        this.store.dispatch(new SetDashBoardCards(dashBoardCards));
      }
    } catch (error) {
      console.error('Error fetching dashboard cards for Host:', error);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe()); }
}
