<style>
    .mdc-text-field--filled:not(.mdc-text-field--disabled){
        background-color: #ffffff !important;
    }
</style>
<section class="banner-area">
  <img src="../../../../../assets/images/brian-babb-XbwHrt87mQ0-unsplash.jpg" alt="Banner Image" class="banner-img" />
    <div class="container-fluid" style="position: absolute;top: 0%;height: 100%;">
        <div class="row" style="height: 100%;">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content" *ngFor="let Content of bannerContent;">
                    <h1 style="color: #ffffff;">{{ Content.title }}
                        <ngx-typed-js [strings]="['Apartment', 'House', 'Condo', 'Studio', 'Villa']" [typeSpeed]="200"
                            [loop]="true" [backSpeed]="100" [showCursor]="false">
                            <span class="typing" style="color: #ffffff;"></span>
                        </ngx-typed-js>
                    </h1>
                    <p style="color: #ffffff;">{{ Content.paragraph }}</p>

                    <!-- Reactive Form -->
                    <form [formGroup]="searchForm" style="background-color: #f6f6f6; box-shadow: 1px 1px #D2A65B;">
                        <div class="row m-10 align-items-center">
                            <!-- Input Field Section -->
                            <div class="col-lg-10" style="padding: 10px;
                        ">

                                    <mat-form-field appearance="fill" class="w-100 home-page-search">
                                        <input
                                            type="text"
                                            matInput
                                            id="autocomplete"
                                            placeholder="Which city... Search destination"
                                            >
                                            <!--  [matAutocomplete]="auto" >

                                       <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCity">
                                            <ng-container *ngIf="filteredCities.length > 5; else noScroll">
                                                <cdk-virtual-scroll-viewport itemSize="48" class="viewport" style="height: 200px;">
                                                    <mat-option
                                                        *cdkVirtualFor="let city of filteredCities"
                                                        [value]="city">
                                                        {{ city.name }}
                                                    </mat-option>
                                                </cdk-virtual-scroll-viewport>
                                            </ng-container>

                                            <ng-template #noScroll>
                                                <div>
                                                    <mat-option
                                                        *ngFor="let city of filteredCities"
                                                        [value]="city">
                                                        {{ city.name }}
                                                    </mat-option>
                                                </div>
                                            </ng-template>
                                        </mat-autocomplete> -->
                                    </mat-form-field>

                            </div>

                            <!-- Search Button Section -->
                            <div class="col-lg-2 col-md-12 p-0">
                                <div class="submit-btn" style="padding: 0px 13px 0px 0px;">
                                    <button
                                        type="button"
                                        class="btn btn-primary w-100"
                                        (click)="GetListingApiCalling()">
                                        Search Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            <div class="col-lg-5 col-md-12" style="height: 100%;">
                <img src='assets/img/banner-img1.png' alt="image" style="position: absolute;
                width: 22%;
                bottom: 0px;
                right: 10%;">
            </div>
        </div>
    </div>
</section>
