import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { Payout } from 'src/app/models/payout.model';
import { PaginationFilterInput } from 'src/app/models/request-models/pagination-review-response';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { ChangePayoutStatusRequest } from 'src/app/models/request-models/change-payout-status-request';

@Injectable({
  providedIn: 'root',
})
export class PayoutsApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createPayout(request: Payout): Observable<ApiResponse<Payout>> {
    return this.http
      .post<ApiResponse<Payout>>(
        `${this.apiUrl}${ApplicationApiUrls.CreatePayout}`,
        request
      )
      .pipe(
        map((response) => response),
        catchError((error) => this.handleError(error))
      );
  }

  getPayoutById(id: number): Observable<Payout> {
    const params = new HttpParams().set('id', id.toString());
    return this.http
      .get<ApiResponse<Payout>>(
        `${this.apiUrl}${ApplicationApiUrls.GetPayoutById}`,
        { params }
      )
      .pipe(
        map((response) => response.result!),
        catchError((error) => this.handleError(error))
      );
  }

  changePayoutStatus(receiptUrl: File, obj: ChangePayoutStatusRequest): Observable<ApiResponse<boolean>> {
    const formData = new FormData();
    formData.append('ReceiptUrl', receiptUrl);
    formData.append('obj', JSON.stringify(obj));
    return this.http.put<ApiResponse<boolean>>(
      `${this.apiUrl}${ApplicationApiUrls.ChangePayoutStatus}`,
      formData
    ).pipe(
      map((response) => response),
      catchError((error) => this.handleError(error))
    );
  }

  getAllAdminPayout(filter: PaginationFilterInput): Observable<Payout[]> {
    return this.http
      .get<ApiResponse<Payout[]>>(
        `${this.apiUrl}${ApplicationApiUrls.GetAllAdminPayout}`,
        { params: this.getPaginationParams(filter) }
      )
      .pipe(
        map((response) => response.result!),
        catchError((error) => this.handleError(error))
      );
  }

  getAllPayout(filter: PaginationFilterInput): Observable<Payout[]> {
    return this.http
      .get<ApiResponse<Payout[]>>(
        `${this.apiUrl}${ApplicationApiUrls.GetAllPayout}`,
        { params: this.getPaginationParams(filter) }
      )
      .pipe(
        map((response: ApiResponse<Payout[]>) => response.result!),
        catchError((error) => this.handleError(error))
      );
  }

  deleteById(id: number): Observable<boolean> {
    const params = new HttpParams().set('id', id.toString());
    return this.http
      .delete<ApiResponse<boolean>>(
        `${this.apiUrl}${ApplicationApiUrls.DeletePayoutById}`,
        { params }
      )
      .pipe(
        map((response) => response.result!),
        catchError((error) => this.handleError(error))
      );
  }

  private getPaginationParams(filter: PaginationFilterInput): HttpParams {
    let params = new HttpParams();
    Object.keys(filter).forEach((key) => {
      if (filter[key] !== undefined && filter[key] !== null) {
        params = params.set(key, filter[key].toString());
      }
    });
    return params;
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(
      () => new Error('Something went wrong; please try again later.')
    );
  }

  downloadRecepit(url: string, filename: string) {
    this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = filename;

      // Trigger the download and cleanup
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
      anchor.remove();
    });
  }
}
