<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>


    <form [formGroup]="listingFormGroup">


        <div class="add-listings-box">
            <h3>Basic Informations</h3>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label><i class='bx bx-briefcase-alt'></i> Listing Title:</label>
                        <input type="text" class="form-control" formControlName="title"
                            placeholder="Name of your property">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-duplicate'></i> Type / Category:</label>
                        <select formControlName="propertyType">
                            <option>Select Category</option>
                            <option *ngFor="let category of propertyType$ | async">{{ category.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bxs-key'></i> Keywords:</label>
                        <!-- <input type="text" class="form-control" formControlName="keyWords"
                            placeholder="Maximum 15 , should be separated by commas"> -->
                        <mat-form-field appearance="outline" class="w-100 chips-field">
                            <mat-chip-grid #chipGrid>
                                <mat-chip-row *ngFor="let tag of keywords" (removed)="removeTag(tag)" [editable]="true"
                                    (edited)="editTag(tag, $event)">
                                    {{tag}}
                                    <button matChipRemove>
                                        <mat-icon style="color:white">close</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input placeholder="Enter Tag Name" [matChipInputFor]="chipGrid"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addTag($event)" [matAutocomplete]="auto" />
                            </mat-chip-grid>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="p-0"> Amenities:</label>
                        <ng-select [items]="amenities" bindLabel="name" bindValue="amenityId" [multiple]="true"
                            placeholder="Select Amenities" clearAllText="Clear" formControlName="amenities">
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="p-0"> Availability:</label>
                        <div class="sidebar-widgets">
                            <div class="box">
                                <span class="title">Available For Booking</span>
                                <label class="switch">
                                    <input type="checkbox" [value]="isAvailable" formControlName="isAvailable">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-purchase-tag'></i> Pricing per night:</label>
                        <input type="number" class="form-control" placeholder="$00.00" formControlName="pricePerNight">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Phone Number</label>
                        <input type="number" class="form-control" placeholder="012345678" formControlName="phoneNumber">
                    </div>
                </div>
            </div>
        </div>

        <div class="add-listings-box">
            <h3>Location</h3>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> City:</label>
                        <mat-form-field appearance="fill" class="city-search-field">
                            <input type="text" matInput formControlName="city" [matAutocomplete]="auto"
                                placeholder="Name of your city">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCity"
                                (optionSelected)="citySelected($event.option.value)">
                                <ng-container *ngIf="filteredCities.length > 5; else noScroll">
                                    <cdk-virtual-scroll-viewport itemSize="48" class="viewport" style="height: 200px;">
                                        <mat-option *cdkVirtualFor="let city of filteredCities" [value]="city">
                                            {{ city.name }}
                                        </mat-option>
                                    </cdk-virtual-scroll-viewport>
                                </ng-container>
                                <ng-template #noScroll>
                                    <div>
                                        <mat-option *ngFor="let city of filteredCities" [value]="city">
                                            {{ city.name }}
                                        </mat-option>
                                    </div>
                                </ng-template>
                            </mat-autocomplete>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Country:</label>
                        <input type="text" class="form-control" formControlName="country"
                            placeholder="Name of your country">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Address:</label>
                        <input type="text" class="form-control" placeholder="e.g. 55 County Laois"
                            formControlName="address">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Region Or State:</label>
                        <input type="text" class="form-control" formControlName="regionOrState"
                            placeholder="Name of your Region Or State">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Zip-Code:</label>
                        <input type="number" class="form-control" formControlName="zipCode"
                            placeholder="Your city zipcode">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Max Guests:</label>
                        <input type="number" class="form-control" formControlName="maxGuests" placeholder="Max Guests">
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label><i class='bx bx-menu-alt-left'></i> Add Coordinates:</label>
                        <!-- <button type="button" mat-raised-button color="primary" (click)="openMap()" *ngIf="!isShowMap">
                            <i class='bx bx-map'></i> {{isUpdateMapButton != true ? 'Open Map' : 'Update Location'}}
                        </button> -->
                        <div style="height: 400px;width: 100%;">
                            <snappstay-map-component [isReadOnly]="isReadOnly" [markersData]="markers"
                                (mapClick)="onMapClick($event)" (actionButtonClick)="closeMap($event)" [focus]="bounds">
                            </snappstay-map-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-listings-box">
            <h3>Details</h3>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label><i class='bx bx-text'></i> Description:</label>
                        <textarea cols="30" rows="7" class="form-control" placeholder="Details..."
                            formControlName="description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-listings-box">
            <h3>Gallery</h3>

            <div class="file-upload-box d-flex flex-wrap" style="gap: 10px;">
                <input type="file" (change)="onFileSelected($event)" multiple accept="image/*" hidden #fileInput>
                <!-- <button mat-raised-button color="primary" (click)="fileInput.click()">
                    <mat-icon>upload</mat-icon> Upload Images
                </button> -->
                <div
                  class="upload-images d-flex flex-column justify-content-center align-items-center"
                  (dragover)="$event.preventDefault();" (drop)="onDropSuccess($event)">
                  <img src="assets/images/ic_upload.svg" alt="" width="50px" height="50px">
                  <div class="d-flex flex-column align-items-center">
                    <span>{{'Drop Images here or'}}</span>
                    <div class="px-1" style="color: #d2a65b;text-decoration: underline;cursor:pointer;" (click)="[fileInput.click(),fileInput.value = '']">
                      {{'Click to upload'}}
                    </div>
                  </div>
                </div>
                <div
                  *ngFor="let imageUrl of backendImages; let i = index"
                  class="image-card d-flex justify-content-center align-items-center p-2 position-relative">
                    <img [src]="imageUrl" class="image-preview" alt="Backend Image">
                    <button mat-icon-button color="warn" (click)="removeBackendImage(i)" style="position: absolute;top:0;right:0;">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div
                  *ngFor="let imageUrl of selectedImageUrls; let i = index"
                  class="image-card d-flex justify-content-center align-items-center p-2 position-relative">
                    <img [src]="imageUrl" class="image-preview" alt="Selected Image">
                    <button mat-icon-button color="warn" (click)="removeFile(i)" style="position: absolute;top:0;right:0;">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>

            <!-- Image gallery container -->
            <!-- <div class="image-gallery"> -->
                <!-- Loop through backend images -->
                <!-- <div *ngFor="let imageUrl of backendImages; let i = index" class="image-card">
                    <img [src]="imageUrl" class="image-preview" alt="Backend Image">
                    <button mat-icon-button color="warn" (click)="removeBackendImage(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div> -->

                <!-- Loop through locally selected files -->
                <!-- <div *ngFor="let imageUrl of selectedImageUrls; let i = index" class="image-card">
                    <img [src]="imageUrl" class="image-preview" alt="Selected Image">
                    <button mat-icon-button color="warn" (click)="removeFile(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div> -->
            <!-- </div> -->
        </div>

        <div class="add-listings-btn">
            <button type="button" [disabled]="listingFormGroup.invalid" (click)="addListing()">
                Submit Listings
            </button>
        </div>


    </form>
    <!-- End -->

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->