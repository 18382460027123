import { CommonModule } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { DashBoardCards } from 'src/app/models/dashboard-cards';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { SetDashBoardCards } from 'src/app/state/snappstay.action';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { ProviderEarnings } from 'src/app/models/provider-earnings.model';
import { ProviderEarningsPaginationFilterInputDTO } from 'src/app/models/request-models/pagination-review-response';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-dashboard-wallet',
  templateUrl: './dashboard-wallet.component.html',
  styleUrls: ['./dashboard-wallet.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CopyrightsComponent,
    DashboardNavbarComponent,
    DashboardSidemenuComponent,
    NgxPaginationModule,
  ],
})
export class DashboardWalletComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  @Select(SnappstayState.GetDashBoardCards)
  dashBoardCards$!: Observable<DashBoardCards[]>;
  filteredEarnings: ProviderEarnings[] = [];
  totalRecords = 0;
  pageSize = 6;
  currentPage = 1;
  searchText = undefined;
  sortField = 'Id';
  sortDirection = 'asc';
  constructor(inject: Injector) {
    super(inject);
  }

  ngOnInit(): void {
    this.walletHostCard();
    this.getAllProviderEarnings();
  }

  async walletHostCard() {
    try {
      const dashBoardCards$ = this.walletApiService.walletHostCard();
      const dashBoardCards = await lastValueFrom(dashBoardCards$);
      if (dashBoardCards) {
        this.store.dispatch(new SetDashBoardCards(dashBoardCards));
      }
    } catch (e) {
      this.handleErrorResponse(e, 'Error fetching wallet host card');
    }
  }

  async getAllProviderEarnings() {
    try {
      const filter: ProviderEarningsPaginationFilterInputDTO = {
        providerUserId: this.store.selectSnapshot(SnappstayState.GetUserProfile)
          .id,
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        sortColumn: this.sortField,
        sortOrder: this.sortDirection,
        search: this.searchText,
      };
      const response$ = this.walletApiService.getAllProviderEarnings(filter);
      const response = await lastValueFrom(response$);
      if (response.successful) {
        this.filteredEarnings = response.result;
        this.totalRecords = response.totalRecords;
      } else {
        this.toastr.error(response.message, 'Error fetching provider earnings', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } catch (error) {
      this.handleErrorResponse(error, 'Error fetching provider earnings');
    }
  }

  applyFilter(event?: Event): void {
    if (event) {
      const input = event.target as HTMLInputElement;
      this.searchText = input.value.toLowerCase();
      this.currentPage = 1;
      this.getAllProviderEarnings();
    }
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getAllProviderEarnings();
  }

  onSortChange(column: string): void {
    this.sortField = column;
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.getAllProviderEarnings();
  }

  handleErrorResponse(error: any, fallbackMessage: string) {
    let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
    if (error?.error) {
      message = error.error.message || error.error.errors[0];
      this.toastr.error(message, fallbackMessage, {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
    }
  }
  getIconClass(cardName: string): string {
    switch (cardName) {
      case 'Withdrawable Balance':
        return 'bx bxs-badge-dollar';
      case 'Total Earnings':
        return 'bx bx-dollar';
      case 'Total Orders':
        return 'bx bx-cart';
      default:
        return 'bx bx-user';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
