import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-how-it-works',
    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss'],
    standalone: true,   
    imports:[
        CommonModule,
    ]
})
export class HowItWorksComponent implements OnInit {

        

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'How It Works',
            paragraph: 'Find and book your ideal room in just a few steps. Browse listings, view details, and secure your stay—all quickly and easily'
        }
    ]
    howItWorksBox = [
        {
            icon: 'flaticon-placeholder',
            title: 'Discover Great Rooms',
            paragraph: 'Browse a variety of rooms in popular locations, tailored to your preferences and budget.'
        },
        {
            icon: 'flaticon-support-1',
            title: 'Connect with Hosts',
            paragraph: 'Reach out to room owners to ask questions and get details about your stay before booking.'
        },
        {
            icon: 'flaticon-tick',
            title: 'Secure Your Booking',
            paragraph: 'Easily make a reservation and receive confirmation for a smooth, hassle-free experience.'
        }
    ];
    

}