import { Component, ElementRef, HostListener, Injector, OnInit } from '@angular/core';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { CommonModule } from '@angular/common';
import { UserProfile } from 'src/app/models/user-profile.model';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent extends SnappstayBaseComponent implements OnInit {

  constructor(
    inject: Injector,private eRef: ElementRef
  ) {
    super(inject);
  }
  ngOnInit(): void {
  }

  classApplied2 = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.classApplied2 = false; // Close dropdown
    }
  }
  @HostListener('window:scroll', [])
  onScroll() {
    this.classApplied2 = false; // Close dropdown on scroll
  }
}
