import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';

@Component({
    selector: 'app-dashboard-my-profile',
    templateUrl: './dashboard-my-profile.component.html',
    styleUrls: ['./dashboard-my-profile.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        CopyrightsComponent,
        DashboardNavbarComponent,
        DashboardSidemenuComponent
    ]
})
export class DashboardMyProfileComponent implements OnInit {

        

    ngOnInit(): void {
    }
    
    breadcrumb = [
        {
            title: 'My Profile',
            subTitle: 'Dashboard'
        }
    ]

}