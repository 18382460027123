import { HttpClient } from '@angular/common/http';
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarStyleTwoComponent } from '../navbar-style-two/navbar-style-two.component';
import { SnappstayBaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-stripe-success',
  standalone: true,
  imports: [NavbarStyleTwoComponent],
  templateUrl: './stripe-success.component.html',
  styleUrl: './stripe-success.component.scss',
})
export class StripeSuccessComponent extends SnappstayBaseComponent {
  sessionId: string | null = null;
  successMessage: string = '';

  constructor(
    inject: Injector,
    private route: ActivatedRoute
  ) {
    super(inject);
  }

  ngOnInit(): void {
    // Get the session_id from the query parameters
    this.sessionId = this.route.snapshot.queryParamMap.get('session_id');

    if (this.sessionId) {
      this.successMessage = `Your payment was successful! Session ID: ${this.sessionId}`;
    } else {
      this.successMessage =
        'Payment successful, but no session ID was provided.';
    }
  }
}
