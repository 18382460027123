<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area header-image" *ngFor="let Content of pageTitleContent;"
    style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul> -->
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h2>Privacy Statement</h2>
                    <span><strong>Privacy Statement and Cookie Statement</strong></span>
                    <p>
                        Last Updated: 11.30.2022
                    </p>
                    <p>
                      SnappStay, (“we” or “us”) values you as our customer and recognizes that privacy is important to
                      you. This Privacy Statement explains how we collect, use, and disclose data when you use our
                      platform and associated services, your rights in determining what we do with the information
                      that we collect or hold about you, and tells you how to contact us.
                    </p>

                    <h2>Privacy Statement Summary</h2>
                    <p>
                        This is a summary of our Privacy Statement. To review our Privacy Statement in full, please
                        scroll down.
                    </p>

                    <h3>What does this Privacy Statement cover?</h3>
                    <div>
                        <p>
                          This Privacy Statement is designed to describe:
                        </p>
                        <ul>
                            <li>How and what type of personal information we collect and use</li>
                            <li>When and with whom we share your personal information</li>
                            <li>What choices you can make about how we collect, use, and share your personal information
                            </li>
                            <li>How you can access and update your information.</li>
                        </ul>
                    </div>

                    <h3>What personal information do we collect and use, and how do we collect it?</h3>
                    <div>
                        <p> We collect personal information when:</p>
                        <ul>
                            <li>You give us the information</li>
                            <li>We collect it automatically</li>
                            <li>We receive it from others</li>
                        </ul>
                        <p>
                          When you create an account on one of our sites, sign up to receive offers or information, or
                          make a booking using our platform, you give us your personal information. We also collect such
                          information through automated technology such as cookies placed on your browser, with your
                          consent where applicable, when you visit our sites or download and use our Apps. We also receive
                          information from affiliated companies within SnappStay Group, as well as business partners and
                          other third parties, which help us improve our platform and associated tools and services,
                          update and maintain accurate records, potentially detect and investigate fraud, and more
                          effectively market our services.
                        </p>
                    </div>

                    <h3>How is your personal information shared?</h3>
                    <p>
                        Your personal information may be shared to help you book your travel and/or vacation, assist
                        with your travel and/or vacation stay, communicate with you (including when we send information
                        on products and services or enable you to communicate with travel providers and/or property
                        owners), and comply with the law. The Privacy Statement below details how personal information
                        is shared.
                    </p>

                    <h3>What are your rights and choices?</h3>
                    <p>
                        You can exercise your data protection rights in various ways. For example, you can opt out of
                        marketing by clicking the “unsubscribe” link in the emails, in your account as applicable, or
                        contacting our customer service. Our Privacy Statement has more information about the options
                        and data protection rights and choices available to you.
                    </p>

                    <h3>How to contact us</h3>
                    <p>
                        More information about our privacy practices is outlined below in our full Privacy Statement.
                        You can also contact us as described in the “Contact Us” section below to ask questions about
                        how we handle your personal information or make requests about your personal information.
                    </p>

                    <hr>

                    <h2>Categories of Personal Information We Collect</h2>
                    <div>
                      <p>
                        When you use our platform, Apps, or associated tools or services, we may collect the following
                        kinds of personal information from you as needed:
                      </p>
                        <ul>
                            <li>Name, username, email address, telephone number, and home, business, and billing
                                addresses</li>
                            <li>Government-issued identification required for booking or identity verification</li>
                            <li>Payment information such as payment card number, expiration date, billing address, and
                                financial account number</li>
                            <li>Travel-related preferences and requests such as favorite destination and accommodation
                                types</li>
                            <li>Loyalty program and membership information</li>
                            <li>Birth date and gender</li>
                            <li>Geolocation</li>
                            <li>Images (including facial photographs), videos, and other recordings</li>
                            <li>Social media account ID and other publicly available information</li>
                            <li>Communications with us</li>
                            <li>Other communications that occur through the platform among partners and travelers</li>
                        </ul>
                    </div>

                    <h2>Mobile Apps</h2>
                    <div>
                      <p>
                        When you download and use any of our mobile apps, we collect certain technical information from
                        your device to enable the app to work properly and as otherwise described in this Privacy
                        Statement. That technical information includes:
                      </p>
                        <ul>
                            <li>Device and telephone connectivity information such as your carrier, network type,
                                network operator, subscriber identity module ("SIM") operator, and SIM country</li>
                            <li>Operating system and version</li>
                            <li>Device model</li>
                            <li>Performance and data usage</li>
                            <li>Usage data, such as dates and times the app accesses our servers, the features and links
                                clicked in the app, searches, transactions, and the data and files downloaded to the app
                            </li>
                            <li>Device settings selected or enabled, such as Wi-Fi, Global Positioning System ("GPS"),
                                and Bluetooth (which may be used for location services, subject to your permission as
                                explained below)</li>
                            <li>Mobile device settings</li>
                            <li>Other technical information such as app name, type, and version as needed to provide you
                                with services</li>
                        </ul>
                    </div>

                    <h3>Permissions for Location-Based Services</h3>
                    <p>
                        Depending on your device’s settings and permissions and your choice to participate in certain
                        programs, we may collect the location of your device by using GPS signals, cell phone towers,
                        Wi-Fi signals, Bluetooth, or other technologies. We will collect this information if you opt in
                        through the app or other program (either during your initial login or later) to enable certain
                        location-based services available within the app (for example, locating available lodging
                        closest to you). To disable location capabilities of the app, you can log off or change your
                        mobile device’s settings.
                    </p>

                    <h2>Use of Personal Information</h2>
                    <div>
                      <p>
                        We use your personal information for various purposes described below, which depend on the site
                        you visit or the app you use:
                      </p>
                        <ul>
                            <li class="mb-3">
                                <strong>Your Use of Online Sites, Apps, and Services:</strong>
                                <ul>
                                    <li>Book the requested travel or enable vacation property booking</li>
                                    <li>Provide services related to the booking and/or account</li>
                                    <li>Create, maintain, and update user accounts on our platform and authenticate you
                                        as a user</li>
                                    <li>Maintain your search and travel history, accommodation and travel preferences,
                                        and similar information about your use of SnappStay Group’s platform and
                                        services</li>
                                    <li>Enable and facilitate acceptance and processing of payments, coupons, and other
                                        transactions</li>
                                    <li>Administer loyalty and rewards programs</li>
                                    <li>Collect and enable booking-related reviews</li>
                                    <li>Help you to use our services faster and easier through features like the ability
                                        to sign in using your account</li>
                                </ul>
                            </li>
                            <li class="mb-3">
                                <strong>Communications and Marketing:</strong>
                                <ul>
                                    <li>Respond to your questions, requests for information, and process information
                                        choices</li>
                                    <li>Enable communication between you and travel suppliers like hotels and vacation
                                        property owners</li>
                                    <li>Contact you (via text message, email, phone calls, mail, or push notifications)
                                        to provide information like travel booking confirmations and updates, or for
                                        marketing purposes</li>
                                    <li>Market our products and services, optimize such marketing to be more relevant to
                                        you, and measure the effectiveness of our promotions</li>
                                    <li>Administer promotions like contests, sweepstakes, and similar giveaways</li>
                                </ul>
                            </li>
                            <li class="mb-3">
                                <strong>Other Business Purposes and Compliance:</strong>
                                <ul>
                                    <li>Conduct surveys, market research, and data analytics</li>
                                    <li>Monitor or record communications with our customer service team for quality
                                        control</li>
                                    <li>Create aggregated or anonymized data for analysis</li>
                                    <li>Verify customer identity, prevent fraud, and comply with applicable laws</li>
                                    <li>Defend against claims and respond to legal requests</li>
                                    <li>Operate our business for lawful purposes as permitted by law</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <h2>Sharing of Personal Information</h2>
                    <div>
                      <p>
                        We share your personal information as described below and in this Privacy Statement, and as
                        permitted by applicable law:
                      </p>
                        <ul>
                            <li>
                                <strong>SnappStay Group Companies:</strong>
                                <p>We share your personal information within the SnappStay Group companies, listed at
                                    SnappStaygroup.com. SnappStay Group companies share, access, and use your personal
                                    information as described in this Privacy Statement.</p>
                            </li>
                            <li>
                                <strong>Third-Party Service Providers:</strong>
                                <p>We share personal information with third parties in connection with the delivery of
                                    services to you and the operation of our business, such as credit card processing,
                                    customer service, fraud prevention, and advertising tailored to your interests.
                                    These third-party service providers are required to protect personal information and
                                    may not use any directly identifying personal information other than to provide
                                    services we contracted them for.</p>
                            </li>
                            <li>
                                <strong>Travel Suppliers:</strong>
                                <p>We share personal information with travel-related suppliers such as hotels, airlines,
                                    car-rental companies, insurance, vacation-rental property owners, and managers who
                                    fulfill your booking. Please note that travel suppliers may contact you to obtain
                                    additional information if required.</p>
                            </li>
                            <li>
                                <strong>Business Partners and Offers:</strong>
                                <p>If we promote a program or offer a service or product in conjunction with a
                                    third-party business partner, we will share your information with that partner to
                                    assist in marketing or to provide the associated product or service. You will
                                    typically be informed of the partner involved, and the information will be governed
                                    by the partner's privacy policy.</p>
                            </li>
                            <li>
                                <strong>Other Third-Parties:</strong>
                                <p>When you access certain features like social media sharing or single sign-on,
                                    information may be shared with third-party providers. The information shared will be
                                    governed by the third-party provider’s privacy policy.</p>
                            </li>
                            <li>
                                <strong>Legal Rights and Obligations:</strong>
                                <p>We may disclose your personal information to enforce our policies, comply with
                                    applicable laws, respond to law enforcement or legal authorities, and protect our
                                    rights or interests.</p>
                            </li>
                            <li>
                                <strong>Corporate Transactions:</strong>
                                <p>We may share your personal information in connection with a corporate transaction,
                                    such as a merger, divestiture, or sale of assets. In case of an acquisition, the
                                    buyer must use your personal information as disclosed in this Privacy Statement.</p>
                            </li>
                        </ul>
                    </div>
                    <h2>Your Rights and Choices</h2>
                    <div>
                      <p>
                        You have certain rights and choices with respect to your personal information, as described
                        below:
                      </p>
                        <ul>
                            <li>
                                <strong>Access and Update Your Information:</strong>
                                <p>If you have an account with us, you can update your personal information by logging
                                    into your account and editing the details, or contacting us via the options provided
                                    in the “Contact Us” section.</p>
                            </li>
                            <li>
                                <strong>Marketing Preferences:</strong>
                                <p>You can control our use of certain cookies by following the guidance in our Cookie
                                    Statement. You may also choose to opt out of marketing emails by clicking the
                                    “unsubscribe” link in any marketing email, or by changing your communication
                                    preferences in your account settings.</p>
                            </li>
                            <li>
                                <strong>Manage Notifications:</strong>
                                <p>For our mobile apps, you can manage notifications and preferences in the settings
                                    menu of the app or within your mobile device's operating system settings.</p>
                            </li>
                            <li>
                                <strong>Withdraw Consent:</strong>
                                <p>If we are processing your personal information on the basis of consent, you may
                                    withdraw that consent at any time by contacting us. This will not affect the
                                    lawfulness of processing prior to the withdrawal of consent.</p>
                            </li>
                            <li>
                                <strong>Data Subject Rights:</strong>
                                <p>Certain countries and regions provide residents with additional rights related to
                                    personal information, such as the ability to request a copy of your personal
                                    information, request deletion, or opt-out of the sale of your personal information.
                                    You can inquire about these rights by contacting us.</p>
                            </li>
                            <li>
                                <strong>Complaints:</strong>
                                <p>If you have concerns about our handling of your personal information, you can file a
                                    complaint with the relevant data protection authority. However, we encourage you to
                                    contact us first so we can address your concerns.</p>
                            </li>
                        </ul>
                    </div>

                    <h2>International Data Transfer</h2>
                    <p>
                        The personal information we process may be transmitted or transferred to countries other than
                        the country in which you reside. Those countries may have data protection laws that are
                        different from the laws of your country.
                    </p>
                    <p>
                      The servers for our platform are located in the United States, and the SnappStay Group companies
                        and third-party service providers operate in many countries around the world. When we collect
                        your personal information, we may process it in any of those countries.
                    </p>
                    <p>
                      We have taken appropriate steps and put safeguards in place to help ensure that your personal
                        information remains protected in accordance with this Privacy Statement. For example, any data
                        transfers between our group companies are governed by our intragroup agreements which
                        incorporate strict data transfer terms (including the European Commission's Standard Contractual
                        Clauses, for transfers from the EEA) and require all group companies to protect the personal
                        information they process in accordance with applicable data protection law.
                    </p>
                    <p>
                      In addition, certain SnappStay Group U.S. affiliates have certified with the EU-U.S. and
                        Swiss-U.S. Privacy Shield, as explained below in the "Privacy Shield" section.
                    </p>
                    <h2>Privacy Shield</h2>
                    <p>
                        Certain SnappStay Group U.S. affiliates have certified to the EU-U.S. and Swiss-U.S. Privacy
                        Shield frameworks and that we adhere to the Privacy Shield Principles of Notice, Choice,
                        accountability for Onward Transfers, Security, Data Integrity and Purpose Limitation, Access,
                        and Recourse, Enforcement, and Liability for personal information from the EU, Switzerland, and
                        the United Kingdom.
                    </p>
                    <p>
                      Such SnappStay Group U.S. affiliates will continue to adhere to the Privacy Shield frameworks
                        and Principles even though the CJEU determined in July 2020 that the EU-U.S. Privacy Shield
                        framework is no longer an adequate transfer mechanism for the transfer of EU personal
                        information to the U.S. In addition, SnappStay Group maintains intra-group Standard Contractual
                        Clauses where applicable to cover the transfer of EU personal information to the U.S.
                    </p>
                    <p>
                      Our certifications can be found <a href="https://www.privacyshield.gov">here</a>. For more
                        information about the Privacy Shield principles, please visit: <a
                            href="https://www.privacyshield.gov">www.privacyshield.gov</a>. For more on our adherence to
                        the Privacy Shield Frameworks, please see information posted <a
                            href="https://www.privacyshield.gov">here</a>.
                    </p>

                    <h2>APEC Cross Border Privacy Rules System Participation</h2>
                    <p>
                        SnappStay’s privacy practices, described in this Privacy Statement, comply with the APEC Cross
                        Border Privacy Rules System. The APEC CBPR system provides a framework for organizations to
                        ensure protection of personal information transferred among participating APEC economies.
                    </p>
                    <p>
                      More information about the APEC framework can be found <a
                      href="https://www.apec.org/Groups/Other-Groups/Privacy-Framework">here</a>.
                    </p>
                    <p>
                      Click <a href="https://www.apec.org/Groups/Other-Groups/Privacy-Framework">here</a> to view our
                      certification status.
                    </p>
                    <h2>Security</h2>
                    <p>
                        We want you to feel confident about using our platform and all associated tools and services,
                        and we are committed to taking appropriate steps to protect the information we collect. While no
                        company can guarantee absolute security, we do take reasonable steps to implement appropriate
                        physical, technical, and organizational measures to protect the personal information that we
                        collect and process.
                    </p>

                    <h2>Record Retention</h2>
                    <div>
                        <p>
                          We will retain your personal information in accordance with all applicable laws, for as long as
                          it may be relevant to fulfill the purposes set forth in this Privacy Statement, unless a longer
                          retention period is required or permitted by law. We will deidentify, aggregate, or otherwise
                          anonymize your personal information if we intend to use it for analytical purposes or trend
                          analysis over longer periods of time.
                        </p>
                        <p>The criteria we use to determine our retention periods include:</p>
                        <ul>
                            <li>The duration of our relationship with you, including any open accounts you may have with
                                SnappStay Group companies, or recent bookings or other transactions you have made on our
                                platform</li>
                            <li>Whether we have a legal obligation related to your personal information, such as laws
                                requiring us to keep records of your transactions with us</li>
                            <li>Whether there are any current and relevant legal obligations affecting how long we will
                                keep your personal information, including contractual obligations, litigation holds,
                                statutes of limitations, and regulatory investigations</li>
                        </ul>
                    </div>

                    <h2>Cookie Statement</h2>
                    <div>
                      <p><strong>Types of cookies and similar technologies</strong></p>
                        <p>Cookies are small pieces of text sent as files to your computer or mobile device when you visit
                          most websites. Cookies may be delivered by us (first-party cookies) or delivered by a
                          third-party partner or supplier (third-party cookies). Cookies are either session cookies or
                          persistent cookies. Session cookies enable sites to recognize and link the actions of a user
                          during a browsing session and expire at the end of each session. Persistent cookies help us
                          recognize you as an existing user and these cookies are stored on your system or device until
                          they expire, although you can delete them before the expiration date.</p>
                        <p><strong>Other similar technologies</strong></p>
                        <ul>
                            <li><strong>Web beacons, gifs, and clear gifs:</strong> Tiny graphics embedded invisibly on
                                sites and in emails. Web beacons allow us to know if a certain page was visited or if ad
                                banners are effective. We use them to gauge the effectiveness of communications,
                                promotions, and marketing campaigns.</li>
                            <li><strong>Proximity-based beacons:</strong> Send one-way signals over short distances to
                                communicate with associated mobile apps installed on your phone. They can notify you of
                                related deals or promotions when you are close to a specific location.</li>
                            <li><strong>Pixels:</strong> Small objects embedded into a web page that are not visible to
                                the user. We use pixels to deliver cookies to your computer, facilitate the log-in
                                process, monitor activity, and deliver online advertising.</li>
                            <li><strong>Tags:</strong> Small pieces of HTML code that request content from ad servers.
                                We use tags to show you relevant advertising and promotions.</li>
                            <li><strong>Scripts:</strong> JavaScript code that launches automatically to determine
                                whether users view associated advertisements.</li>
                            <li><strong>Local Storage Objects:</strong> Used to store content and preferences.
                                Third-party partners who provide features on our site use them to display advertising
                                based on your browsing activity.</li>
                        </ul>
                        <p>
                          All of the technologies described above are collectively referred to as "cookies" in this Cookie
                          Statement.
                        </p>
                    </div>

                    <h2>SnappStay Group’s Use of Cookies</h2>
                    <div>
                      <p>At SnappStay Group, we use cookies for a number of reasons, including to:</p>
                        <ul>
                            <li>Help us improve your experience when visiting our sites</li>
                            <li>Fulfill transactions and ensure our sites perform as intended</li>
                            <li>Remember your preferences, such as language, region, or currency</li>
                            <li>Provide you with relevant advertising and analyze the performance of the ads</li>
                            <li>Enable you to return to previous travel searches</li>
                            <li>Identify errors on our sites</li>
                            <li>Help with data protection and potentially detect and investigate malicious or fraudulent
                                activity</li>
                            <li>Help us understand traffic to our site, including the time and date of the visit, time
                                and date of the last visit, and other information</li>
                            <li>Analyze how well our sites are performing</li>
                        </ul>
                    </div>

                    <h2>Types of Information Collected by Cookies</h2>
                    <div>
                      <p>The types of information that we collect through cookies include:</p>
                        <ul>
                            <li>IP address</li>
                            <li>Device ID</li>
                            <li>Viewed pages</li>
                            <li>Browser type</li>
                            <li>Browsing information</li>
                            <li>Operating system</li>
                            <li>Internet Service Provider</li>
                            <li>Whether you have responded to, or interacted with, an advertisement</li>
                            <li>Referring or referred links or URLs</li>
                            <li>Features used and activities engaged in on our sites and in our apps</li>
                        </ul>
                        <p>
                          See the <strong>Categories of Personal Information We Collect</strong> section above for more
                          information on what types of information are collected automatically.
                        </p>
                    </div>

                    <h2>Types and Functions of Cookies</h2>
                    <div>
                        <p><strong>Essential Cookies:</strong></p>
                        <p>
                          Certain cookies are required or “essential” for our sites to function as intended. Essential
                          cookies are necessary for you to navigate our site and use certain features like logging in to
                          your account and managing your bookings. These cookies are also used to remember security
                          settings that allow access to particular content. Lastly, we use essential cookies to collect
                          information on which web pages visitors go to most, so we can improve our online services. You
                          are not able to opt out of essential cookies.
                        </p>
                        <p> <strong>Other Types of Cookies:</strong></p>
                        <p>We also use other types of cookies to make our site engaging and useful to you:</p>
                        <ul>
                            <li><strong>Functional Cookies:</strong> We ensure that when you visit our site, your
                                preferences (such as language) and previous searches are remembered to enhance your
                                experience.</li>
                            <li><strong>Analytics Cookies:</strong> We use analytics cookies to:
                                <ul>
                                    <li>Understand our site’s performance and usage</li>
                                    <li>Test different designs and features</li>
                                    <li>Monitor how visitors reach our sites</li>
                                    <li>Determine the effectiveness of our advertising</li>
                                    <li>Improve our services, including your experience on our site</li>
                                </ul>
                            </li>
                            <li><strong>Advertising Cookies:</strong> SnappStay Group and our third-party advertising
                                partners may use advertising cookies to show you ads both on and off our sites, based on
                                your interests. These cookies help tailor ads and allow our partners to track your
                                activity for ad targeting purposes. However, SnappStay Group does not have access to the
                                cookies used by these third parties.</li>
                        </ul>
                    </div>


                    <h2>Your Information Choices</h2>
                    <div>
                      <p>
                        You can choose not to receive tailored online advertising on this site and other sites, and
                        learn more about opting out of having your information used for tailored advertising purposes by
                        accessing the following resources:
                      </p>
                        <ul>
                            <li>For EEA, Switzerland, and UK: <a
                                    href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a></li>
                            <li>For Canada: <a
                                    href="http://youradchoices.ca/choices/">http://youradchoices.ca/choices/</a></li>
                            <li>For U.S. and the rest of the world: <a
                                    href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a></li>
                            <li>For Network Advertising Initiative: <a
                                    href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>
                            </li>
                        </ul>
                        <p>
                          Note that if you choose not to receive tailored ads, you will still see online advertisements,
                          but they will be general and less relevant to you.
                        </p>
                    </div>
                    <h2>How Can You Manage Your Cookies?</h2>
                    <p>
                        You can set or amend your web browser controls to accept or refuse cookies whenever you like,
                        but please remember if you do choose to reject cookies, your access to some of the functionality
                        and areas of our site may be restricted.
                    </p>
                    <p>
                      <strong>Do-Not-Track Signals and Similar Mechanisms:</strong>
                    </p>
                    <p>
                        Some web browsers may transmit "do-not-track" signals to sites with which the browser
                        communicates. Because of differences in how web browsers incorporate and activate this feature,
                        it is not always clear whether users intend for these signals to be transmitted, or whether
                        users are even aware of them. Participants in the leading Internet standards-setting
                        organization addressing this issue are in the process of determining what, if anything, sites
                        should do when they receive such signals. We currently do not take action in response to these
                        signals. If and when a final standard is established and accepted, we will reassess our sites’
                        responses to these signals and make appropriate updates to this Cookie Statement.
                    </p>


                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions or concerns about our use of your personal information, or wish to
                        inquire about our personal handling practices, please contact us via the Privacy Section found
                        on our Customer Services Portal <a href="https://www.snappstay.com/privacy">here</a>.
                    </p>
                    <p>
                      For a list of the SnappStay Group companies, click <a
                            href="https://www.snappstay.com/group-companies">here</a>.
                    </p>
                    <p>
                      If you have an unresolved privacy or data use concern that we have not addressed satisfactorily,
                        please contact our U.S.-based third-party dispute resolution provider (free of charge) at <a
                            href="https://feedback-form.truste.com/watchdog/request">https://feedback-form.truste.com/watchdog/request</a>.
                    </p>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image" *ngFor="let Image of aboutImage;">
                    <img [src]="Image.img" alt="image">
                    <a href="{{Image.videoLink}}" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End About Area -->

<app-footer-style-two></app-footer-style-two>
