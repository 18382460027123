import { Component, OnInit } from '@angular/core';
import { FooterStyleOneComponent } from '../../common/footer-style-one/footer-style-one.component';
import { CommonModule } from '@angular/common';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        FooterStyleOneComponent,
        NavbarStyleOneComponent
    ]
})
export class NotFoundComponent implements OnInit {

        

    ngOnInit(): void {
    }

    pageTitleContent = [
        {
            title: '404 Error Page',
            backgroundImage: 'assets/img/page-title/page-title3.jpg'
        }
    ]
    errorContent = [
        {
            img: 'assets/img/error.png',
            title: 'Error 404 : Page Not Found',
            paragraph: 'The page you are looking for might have been removed had its name changed or is temporarily unavailable.'
        }
    ]

}