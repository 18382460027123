<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Start -->
    <div class="chat-content-area">
        <div class="sidebar-left">
            <div class="sidebar">
                <div class="chat-sidebar-header d-flex align-items-center">
                    <div class="avatar mr-3">
                        <div class="menu-profile">
                            <img *ngIf="(userProfile$ | async)?.profileImageUrl; else defaultImage"
                                [src]="environmentVar.blobUrl + (userProfile$ | async).profileImageUrl"
                                class="rounded-circle" alt="User profile image" width="50" height="50">

                            <ng-template #defaultImage>
                                <img src="assets/img/download.png" class="rounded-circle" alt="Default profile image"
                                    width="50" height="50">
                            </ng-template>

                        </div>
                    </div>
                    <form class="form-group position-relative mb-0">
                        <label><i class='bx bx-search'></i></label>
                        <input type="text" class="form-control" placeholder="Search here...">
                    </form>
                </div>
                <div class="sidebar-content d-flex chat-sidebar">
                    <div class="chat-menu">
                        <label class="d-block list-group-label mt-0">Chats</label>
                        <ul class="list-group list-group-user list-unstyled mb-0">
                            <div *ngFor="let chat of userChatsList ">
                                <li>
                                    <div class="d-flex align-items-center" (click)="openchat(chat)">
                                        <div class="avatar mr-3">
                                            <img *ngIf="chat.imageUrl; else defaultImage"
                                                [src]="environmentVar.blobUrl + chat.imageUrl" class="rounded-circle"
                                                alt="User profile image" width="50" height="50">

                                            <ng-template #defaultImage>
                                                <img src="assets/img/download.png" class="rounded-circle"
                                                    alt="Default profile image" width="50" height="50">
                                            </ng-template>
                                        </div>

                                        <div class="user-name">
                                            <h6>{{chat.userName}}</h6>
                                            <!-- <span class="d-block">Welcome to React...</span> -->
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                        <!-- <label class="d-block list-group-label">Contacts</label>
                        <ul class="list-group list-group-user list-unstyled mb-0">
                            <li>
                                <div class="d-flex align-items-center">
                                    <div class="avatar mr-3">
                                        <img src="assets/img/user4.jpg" width="50" height="50" class="rounded-circle"
                                            alt="image">
                                        <span class="status-away"></span>
                                    </div>

                                    <div class="user-name">
                                        <h6>Mitsue Dove</h6>
                                        <span class="d-block">New Order..</span>
                                    </div>
                                </div>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="content-right">
            <div class="chat-area">
                <div class="chat-list-wrapper">
                    <ng-container *ngIf="messageConversation; else emptyState">
                        <div class="chat-list">
                            <div class="chat-list-header d-flex align-items-center">
                                <div class="header-left d-flex align-items-center mr-3">
                                    <div class="avatar mr-3">
                                        <img *ngIf="messageConversation.receiverImage; else defaultImage"
                                            [src]="environmentVar.blobUrl + messageConversation.receiverImage"
                                            class="rounded-circle" alt="User profile image" width="50" height="50">
                                        <ng-template #defaultImage>
                                            <img src="assets/img/download.png" class="rounded-circle"
                                                alt="Default profile image" width="50" height="50">
                                        </ng-template>
                                    </div>
                                    <h6 class="mb-0">{{messageConversation.receiverName}}</h6>
                                </div>
                                <div class="header-right text-end w-100">
                                    <ul class="list-unstyled mb-0">
                                        <li>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle" type="button" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="true"><i
                                                        class='bx bx-dots-vertical-rounded'></i></button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item d-flex align-items-center" href="#"><i
                                                            class='bx bx-trash'></i> Delete Chat</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="chat-container" #chatContainer (scroll)="onScroll()" (mousedown)="onContainerClick($event)">
                                <div class="chat-content" *ngFor="let msg of messageConversation.messages">
                                    <div class="chat"
                                        *ngIf="(msg && msg.senderId == userProfileDetails.id); else leftMessage">
                                        <div class="chat-avatar">
                                            <img *ngIf="messageConversation.senderImage; else defaultImage"
                                                [src]="environmentVar.blobUrl + messageConversation.senderImage"
                                                class="rounded-circle" alt="User profile image" width="50" height="50">
                                            <ng-template #defaultImage>
                                                <img src="assets/img/download.png" class="rounded-circle"
                                                    alt="Default profile image" width="50" height="50">
                                            </ng-template>
                                        </div>
                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>{{msg.messageText}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #leftMessage>
                                        <div class="chat chat-left">
                                            <div class="chat-avatar">
                                                <img *ngIf="messageConversation.receiverImage; else defaultImage"
                                                    [src]="environmentVar.blobUrl + messageConversation.receiverImage"
                                                    class="rounded-circle" alt="User profile image" width="50"
                                                    height="50">
                                                <ng-template #defaultImage>
                                                    <img src="assets/img/download.png" class="rounded-circle"
                                                        alt="Default profile image" width="50" height="50">
                                                </ng-template>
                                            </div>
                                            <div class="chat-body">
                                                <div class="chat-message">
                                                    <p>{{msg.messageText}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="chat-list-footer">
                                <form [formGroup]="chatForm" (ngSubmit)="sendMessage()"
                                    class="d-flex align-items-center" id="chatForm">
                                    <div class="btn-box d-flex align-items-center mr-3 position-relative">
                                        <button type="button" class="emoji-btn d-inline-block mr-2"
                                            (click)="toggleEmojiPicker()">
                                            <i class="bx bx-smile"></i>
                                        </button>
                                        <emoji-mart *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)"
                                            set="apple" class="emoji-picker-position"></emoji-mart>
                                    </div>
                                    <input type="text" formControlName="message" class="form-control" #chatInput
                                        (focus)="scrollToBottomOnInput()" (input)="scrollToBottomOnInput()"
                                        placeholder="Type your message..." (keydown.enter)="sendMessage($event)" />
                                    <button type="submit" class="send-btn d-inline-block"
                                        (click)="sendMessage($event)">Send
                                        <i class="bx bx-paper-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Empty State Template -->
                    <ng-template #emptyState>
                        <div class="empty-state text-center">
                            <img src="assets/img/empty-chat.png" alt="No messages" width="150" class="mb-3">
                            <h5>No Messages Yet</h5>
                            <p>Select a conversation to start messaging.</p>
                        </div>
                    </ng-template>
                </div>

            </div>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->