import { Component, OnInit } from '@angular/core';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { HometwoBannerComponent } from './hometwo-banner/hometwo-banner.component';
import { CategoryComponent } from '../../common/category/category.component';
import { HometwoListingsComponent } from './hometwo-listings/hometwo-listings.component';
import { HometwoDestinationsComponent } from './hometwo-destinations/hometwo-destinations.component';
import { FeedbackStyleOneComponent } from '../../common/feedback-style-one/feedback-style-one.component';
import { HometwoEventsComponent } from './hometwo-events/hometwo-events.component';
import { HowItWorksComponent } from '../../common/how-it-works/how-it-works.component';
import { HometwoBlogComponent } from './hometwo-blog/hometwo-blog.component';
import { AppDownloadComponent } from '../../common/app-download/app-download.component';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';

@Component({
  selector: 'app-home-demo-two',
  templateUrl: './home-demo-two.component.html',
  styleUrls: ['./home-demo-two.component.scss'],
  standalone: true,
  imports:[
    NavbarStyleTwoComponent,
    HometwoBannerComponent,
    CategoryComponent,
    HometwoListingsComponent,
    HometwoDestinationsComponent,
    FeedbackStyleOneComponent,
    HometwoEventsComponent,
    HowItWorksComponent,
    HometwoBlogComponent,
    AppDownloadComponent,
    FooterStyleTwoComponent
  ]
})
export class HomeDemoTwoComponent implements OnInit {

      

  ngOnInit(): void {
  }

}
