import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { DashBoardCards } from 'src/app/models/dashboard-cards';
import { UserFilterWithPagination } from 'src/app/models/request-models/user-filter-with-pagination-request';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { UserDetails } from 'src/app/models/users-details.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  DashBoardCardsForAdmin(): Observable<DashBoardCards[]> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.AdminDashBoardCard}`)
      .pipe(
        map((data: ApiResponse<DashBoardCards[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  DashBoardCardsForHost(): Observable<DashBoardCards[]> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.DashBoardCardsForHost}`)
      .pipe(
        map((data: ApiResponse<DashBoardCards[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  AdminDashBoardBookingCard(): Observable<DashBoardCards[]> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.AdminDashBoardBookingCard}`)
      .pipe(
        map((data: ApiResponse<DashBoardCards[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetAllUser(request: UserFilterWithPagination): Observable< BaseGridResponse<UserDetails[]>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key] !== null && request[key] !== undefined) {
        params = params.append(key, request[key].toString());
      }
    });
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAllUser}`, {
        params,
      })
      .pipe(
        map((data: BaseGridResponse<UserDetails[]>) => {
          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
