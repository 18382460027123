<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="heading-container" style="margin-top: 10px;">
        <h2 style="font-weight: bold; color: #333;">Payouts</h2>
    </div>
    <section class="listing-section">
        <!-- Payout Request Modal -->
        <div *ngIf="isPayoutModelOpen" class="modal fade loginRegisterModal show d-block" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <button type="button" class="close" (click)="closePopup()">
                        <i class='bx bx-x'></i>
                    </button>
                    <div class="modal-header border-0 ">
                        <h3 class="modal-title fw-bold text-center ">Payout Request</h3>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <form [formGroup]="payoutForm">
                                <div class="form-group row align-items-center">
                                    <label class="col-form-label col-sm-4">Name:</label>
                                    <div class="col-sm-8">
                                        <p class="form-control-plaintext">{{ userName }}</p>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-form-label col-sm-4">Total Balance:</label>
                                    <div class="col-sm-8">
                                        <p class="form-control-plaintext">${{ totalBalance }}</p>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-form-label col-sm-4">Amount:</label>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="amount" placeholder="Enter payout amount" class="form-control">
                                        <div *ngIf="payoutForm.get('amount').touched && payoutForm.get('amount')?.hasError('required')" class="text-danger">
                                            Amount is required.
                                        </div>
                                        <div *ngIf="payoutForm.get('amount').touched && payoutForm.get('amount')?.hasError('amountExceedsBalance')" class="text-danger">
                                            Amount cannot exceed total balance.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-right">
                                        <button type="button" class="btn btn-primary" (click)="createPayoutRequest()" [disabled]="payoutForm.invalid">
                                            Send Request
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    
        <!-- Upload Receipt Modal -->
        <div *ngIf="uploadReceiptModelOpen" class="modal fade loginRegisterModal show d-block" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-3">
                    <button type="button" class="close position-absolute top-0 end-0 m-2" (click)="closeReceiptPopup()">
                        <i class="bx bx-x fs-4"></i>
                    </button>
                    <div class="modal-header border-0">
                        <h5 class="modal-title fw-bold">Upload Receipt</h5>
                    </div>
                    <div class="modal-body">
                        <div class="file-upload-box text-center mb-3">
                            <input type="file" (change)="onFileSelected($event)" accept="image/*" hidden #fileInput>
                            <button mat-raised-button color="primary" (click)="fileInput.click()" class="btn btn-outline-primary">
                                <mat-icon>cloud_upload</mat-icon> Select Image
                            </button>
                        </div>
                        <div *ngIf="selectedFile" class="image-gallery text-center mb-3">
                            <mat-card class="image-card d-inline-block">
                                <img [src]="createImageUrl(selectedFile)" class="image-preview rounded mb-2" alt="Selected Image">
                                <button mat-icon-button color="warn" (click)="removeFile()" class="btn btn-danger btn-sm">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card>
                        </div>
                        <div *ngIf="backendImage" class="image-gallery text-center mb-3">
                            <mat-card class="image-card d-inline-block">
                                <img [src]="backendImage" class="image-preview rounded mb-2" alt="Backend Image">
                                <button mat-icon-button color="warn" (click)="removeBackendImage()" class="btn btn-danger btn-sm">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card>
                        </div>
                    </div>
                    <div class="modal-footer border-0 justify-content-center">
                        <button type="button" class="btn btn-success w-100" (click)="changePayoutStatus('Completed')" [disabled]="!selectedFile">
                            <mat-icon>cloud_upload</mat-icon>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    
        <!-- Filters and Payout Request Button -->
        <div class="row mb-3">
            <div class="col">
                <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="Filter by any field">
            </div>
            <div class="col-auto" *ngIf="role === 'Host'">
                <button class="btn btn-primary" (click)="OpenPopUp()">Payout Request</button>
            </div>
        </div>
    
        <!-- Payout Table -->
        <div *ngIf="payouts.length > 0; else noData">
            <table class="table">
                <thead>
                    <tr>
                        <th (click)="onSortChange('payoutRequesterName')" class="heading">Payout Requester Name</th>
                        <th (click)="onSortChange('phoneNumber')" class="heading">Phone Number</th>
                        <th (click)="onSortChange('email')" class="heading">Email</th>
                        <th (click)="onSortChange('amount')" class="heading">Amount</th>
                        <th  class="heading" *ngIf="role === 'Host'">Receipt</th>
                        <th (click)="onSortChange('status')" class="heading">Status</th>
                        <th (click)="onSortChange('remarks')" class="heading">Remarks</th>
                        <th *ngIf="role === 'Administrator'" class="heading"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let payout of payouts | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }">
                        <td>{{ payout.payoutRequesterName }}</td>
                        <td>{{ payout.phoneNumber }}</td>
                        <td>{{ payout.email }}</td>
                        <td>{{ payout.amount | currency }}</td>
                        <td *ngIf="role === 'Host'">
                            <span (click)="downloadReceipt(payout.receiptUrl, 'Receipt')" class="bx bx-download" style="font-size: 1.5em;"></span>
                        </td>
                        <td [ngStyle]="{ 
                            'color': payout.status === 'Completed' ? '#28a745' : 
                                      (payout.status === 'Pending' ? '#d2a65b' : '#333'),
                            'font-weight': 'bold',
                            'padding': '10px',
                           
                        }">
                            {{ payout.status }}
                        </td>
                        <td>{{ payout.remarks }}</td>
                      <td class="action-column" *ngIf="role === 'Administrator'">
                            <button class="context-btn" (click)="toggleContextMenu(payout)" aria-haspopup="true" aria-expanded="payout.showMenu">&#x22EE;</button>
                            <div *ngIf="payout.showMenu" class="context-menu">
                                <ul class="context-menu-list list-unstyled p-0 m-0">
                                    <li (click)="rejectPayout(payout.id)" class="text-danger">Reject Payout</li>
                                    <li (click)="uploadReceipt(payout.id)" class="text-success">Accept and upload receipt</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <!-- Empty State -->
        <ng-template #noData>
            <div class="empty-state" style="display: flex; justify-content: center; align-items: center; min-height: 75vh;">
                <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
            </div>
        </ng-template>
    
        <!-- Pagination Controls -->
        <div class="pagination-area text-center">
            <pagination-controls id="server"
                                 itemsPerPage="pageSize"
                                 currentPage="currentPage"
                                 totalItems="totalRecords"
                                 (pageChange)="onPageChange($event)">
            </pagination-controls>
        </div>
    </section>
    
</div>