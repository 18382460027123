import { SnappstayBaseComponent } from '../../base-component/base.component';
import { CommonModule } from '@angular/common';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { DashboardSidemenuComponent } from '../../common/dashboard-sidemenu/dashboard-sidemenu.component';
import { DashboardNavbarComponent } from '../../common/dashboard-navbar/dashboard-navbar.component';
import { CopyrightsComponent } from '../dashboard/copyrights/copyrights.component';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { __values } from 'tslib';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CityName } from 'src/app/models/lookup/city-names';
import { UserProfile } from 'src/app/models/user-profile.model';
import { lastValueFrom } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UserBankAccountApiService } from 'src/app/services/api-services/bank-account-api';
import { BankAccountInfo } from 'src/app/models/bank-account.model';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Injector, ChangeDetectorRef } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-update-user-profile',
  standalone: true,
  imports: [
    CommonModule,
    DashboardSidemenuComponent,
    DashboardNavbarComponent,
    CopyrightsComponent,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    ScrollingModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule
  ],
  templateUrl: './update-user-profile.component.html',
  styleUrl: './update-user-profile.component.scss',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
})
export class UpdateUserProfileComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  profileForm: FormGroup;
  filteredCities: CityName[] = [];
  selectedFile: File | null = null; // Single file instead of an array
  backendImage: string | null = null;
  imageUrl: string | null = null;
  imagePreview: string | ArrayBuffer = null;
  uploadProfilePicModelOpen: boolean = false;
  isBankInfoModelOpen: boolean = false;
  bankUpdateForm: FormGroup;
  @ViewChild('fileInput') fileInput!: ElementRef;
  constructor(
    inject: Injector,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private userBankAccountApiService: UserBankAccountApiService
  ) {
    super(inject);
  }

  ngOnInit(): void {
    this.initForm();
    this.userProfile();
    this.getUserBankInfo();
    this.patchValues();
    this.cityFormControlSub();
  }

  OpenPopUp() {
    this.isBankInfoModelOpen = true;
  }
  closePopup() {
    this.isBankInfoModelOpen = !this.isBankInfoModelOpen;
    this.bankUpdateForm.reset();
    this.bankUpdateForm.markAsUntouched();
  }
  private patchValues() {
    const userSub = this.store
      .select(SnappstayState.GetUserProfile)
      .subscribe((values) => {
        if (values) {
          this.profileForm.patchValue({
            id: values.id,
            address: values.address,
            fullName: values.fullName,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            gender: values.gender ?? '',
            dateOfBirth: values.dateOfBirth,
            placeOfResidence: values.placeOfResidence,
            zipCode: values.zipCode ? String(values.zipCode) : '',
            street: values.street,
            city: this.cities.find((x) => x.name == values.city),
            phoneNumber: values.phoneNumber,
            fbLink: values.fbLink,
            instaLink: values.instaLink,
            xLink: values.xLink,
          });
        }
      });
    this.subscriptions.push(userSub);
  }

  initForm() {
    this.profileForm = this.fb.group({
      id: [null],
      fullName: [null, Validators.required],
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      gender: [null, Validators.required],
      dateOfBirth: [null, Validators.required],
      placeOfResidence: [null],
      zipCode: [null, Validators.pattern(/^\d+$/)],
      address: [null],
      street: [null],
      city: [null],
      state: [null],
      country: [null],
      phoneNumber: [null, Validators.pattern(/^[\d()+-]+$/)],
      fbLink: [null],
      instaLink: [null],
      xLink: [null],
    });
    this.bankUpdateForm = this.fb.group({
      id: [null],
      accountTitle: ['', Validators.required],
      accountNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      iban: [''], // Optional field
    });
  }

  private _filter(value: any): CityName[] {
    let filterValue = '';

    if (typeof value === 'object' && value !== null && value.name) {
      filterValue = value.name.toLowerCase();
    } else if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    }

    if (filterValue !== '') {
      const filteredCities = this.cities.filter((city) =>
        city.name.toLowerCase().includes(filterValue)
      );
      console.log('filtered Cities', filteredCities);
      return filteredCities;
    }

    return this.cities;
  }

  cityFormControlSub() {
    const citySub = this.profileForm.controls['city'].valueChanges.subscribe(
      (value) => {
        if (value) {
          if (typeof value == 'object' && value !== null) {
            const country = this.countries.find(
              (x) => x.isoCode == value.countryCode
            );
            const state = this.states.find(
              (x) =>
                x.isoCode == value.stateCode && x.countryCode == country.isoCode
            );
            const zip = this.getZipCodeByCityAndStateCode(
              value.name,
              state.isoCode
            );
            this.profileForm.controls['country'].patchValue(country.name);
            this.profileForm.controls['state'].patchValue(state.name);
          }
          this.filteredCities = this._filter(value || '');
        } else {
          this.filteredCities = this.cities;
          this.profileForm.controls['country'].patchValue(null);
          this.profileForm.controls['state'].patchValue(null);
        }
      }
    );
    this.subscriptions.push(citySub);
  }
  displayCity(city: any): string {
    return city && city.name ? city.name : '';
  }

  async submitForm(): Promise<void> {
    console.log('form Data ', this.profileForm);
    if (this.profileForm.valid) {
      const formData = this.profileForm.getRawValue();

      // Map form values to the UserProfile object
      const userProfile: UserProfile = {
        id: formData.id,
        fullName: formData.fullName,
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        gender: formData.gender,
        dateOfBirth: new Date(formData.dateOfBirth),
        placeOfResidence: formData.placeOfResidence,
        zipCode: formData.zipCode,
        street: formData.street,
        city: formData.city.name,
        state: formData.state,
        country: formData.country,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
        fbLink: formData.fbLink,
        instaLink: formData.instaLink,
        xLink: formData.xLink,
      };

      // Call UpdateProfile with the mapped object
      await this.UpdateProfile(userProfile);
    } else {
      this.profileForm.markAllAsTouched();
    }
  }

  async getUserBankInfo() {
    try {
      const userBankInfo$ = this.userBankAccountApiService.getUserBankInfo();
      const userBankInfo = (await lastValueFrom(userBankInfo$, {
        defaultValue: undefined,
      })) as BankAccountInfo;
      if (userBankInfo) {
        this.patchBankAccountValues(userBankInfo);
      }
    } catch (e: any) {
      let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  patchBankAccountValues(userBankInfo: BankAccountInfo) {
    this.bankUpdateForm.patchValue({
      id: userBankInfo.id,
      accountTitle: userBankInfo.accountTitle,
      accountNumber: userBankInfo.accountNumber,
      bankName: userBankInfo.bankName,
      iban: userBankInfo.iban || '',
    });
  }

  async userBankInfo(request: BankAccountInfo) {
    try {
      const bankAccountInfo$ =
        this.userBankAccountApiService.createUpdateBankInfo(request);
      const bankAccountInfo = await lastValueFrom(bankAccountInfo$, {
        defaultValue: undefined,
      });
      if (bankAccountInfo) {
        this.toastr.success('Bank account info updated successfully', '', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        this.closePopup();
        this.getUserBankInfo();
      }
    } catch (e: any) {
      let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  async UpdateProfile(request: UserProfile) {
    try {
      const userProfile$ = this.accountApiService.UpdateProfile(request);
      const userProfile = await lastValueFrom(userProfile$, {
        defaultValue: undefined,
      });
      if (userProfile) {
        this.toastr.success('Profile updated successfully', '', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } catch (e: any) {
      let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.imageUrl = URL.createObjectURL(this.selectedFile);
      this.cdr.detectChanges();
    }
  }

  removeFile(): void {
    this.selectedFile = null;
    this.imageUrl = null; // Clear the URL if necessary
    this.fileInput.nativeElement.value = ''; // Reset the file input
    this.cdr.detectChanges(); // Trigger change detection
  }

  createImageUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  convertBackendUrlToFile(imageUrl: string) {
    this.urlToFile(imageUrl).then((file) => {
      this.selectedFile = file;
    });
  }

  async urlToFile(imageUrl: string): Promise<File> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const fileName = imageUrl.split('/').pop() || 'image.jpg';
    return new File([blob], fileName, { type: blob.type });
  }

  removeBackendImage(): void {
    this.backendImage = null;
  }

  closeProfilePicPopup() {
    this.uploadProfilePicModelOpen = false;
    this.selectedFile = null;
    this.backendImage = null;
  }
  uploadProfilePic(): void {
    this.uploadProfilePicModelOpen = true;
  }

  async updateProfilePicApi() {
    if (this.selectedFile) {
      try {
        const changeProfilePic$ = this.accountApiService.changeProfilePic(
          this.selectedFile
        );
        const changeProfilePic = await lastValueFrom(changeProfilePic$, {
          defaultValue: undefined,
        })as ApiResponse<string>;
        if (changeProfilePic.successful) {
          this.toastr.success('Profile Pic updated successfully', '', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.closeProfilePicPopup();
          this.userProfile()
        }else{
          let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
          this.toastr.error(message, 'Please try again later', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.closeProfilePicPopup();
        }
      } catch (e: any) {
        let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
        if (e?.error) {
          this.closeProfilePicPopup();
          message = e.error.message ? e.error.message : e.error.errors[0];
          this.toastr.error(message, 'Please try again later', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        }
      } finally {
      }
    }
  }

  updateAccountInfo() {
    if (this.bankUpdateForm.valid) {
      console.log(this.bankUpdateForm.value);
      const request: BankAccountInfo = {
        id: this.bankUpdateForm.value.id || undefined, // Include id if present
        accountTitle: this.bankUpdateForm.value.accountTitle,
        accountNumber: this.bankUpdateForm.value.accountNumber,
        bankName: this.bankUpdateForm.value.bankName,
        iban: this.bankUpdateForm.value.iban || undefined, // Optional field
      };
      this.userBankInfo(request);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
