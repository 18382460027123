import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { BankAccountInfo } from 'src/app/models/bank-account.model';

@Injectable({
  providedIn: 'root',
})
export class UserBankAccountApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createUpdateBankInfo(dto: BankAccountInfo): Observable<any> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.CreateUpdateBankInfo}`, dto)
      .pipe(
        map((data: ApiResponse<any>) => {
          return data.result;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getUserBankInfo(): Observable<BankAccountInfo> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetUserBankInfo}`)
      .pipe(
        map((data: ApiResponse<BankAccountInfo>) => {
          return data.result;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
