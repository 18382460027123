import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { Review } from 'src/app/models/listing-model/review';
import {
  PaginationFilterInput,
  PaginationReviewrRequest,
} from 'src/app/models/request-models/pagination-review-response';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  GetReviewByPropertyProfileId(
    request: PaginationReviewrRequest
  ): Observable<Review[]> {
    let params = new HttpParams();

    for (const key in request) {
      if (request.hasOwnProperty(key) && request[key] !== null) {
        params = params.set(key, request[key]);
      }
    }

    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetReviewByPropertyProfileId}`, {
        params,
      })
      .pipe(
        map((data: ApiResponse<Review[]>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  CreateReview(request: FormData): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.apiUrl}${ApplicationApiUrls.CreateReview}`,
        request
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetAllReview(request: PaginationFilterInput): Observable<BaseGridResponse<Review[]>> {
    let params = new HttpParams();
    Object.keys(request).forEach((key) => {
      if (request[key] !== null && request[key] !== undefined) {
        params = params.append(key, request[key].toString());
      }
    });
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetAllReview}`, {
        params,
      })
      .pipe(
        map((data: BaseGridResponse<Review[]>) => {
          return data;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
