import { CommonModule } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { GetPropertyRequest } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
    

@Component({
    selector: 'app-hometwo-destinations',
    templateUrl: './hometwo-destinations.component.html',
    styleUrls: ['./hometwo-destinations.component.scss'],
    standalone: true,
    imports:[   
        CommonModule,
        RouterLinkWithHref,
        RouterLinkActive,
    ]
})
export class HometwoDestinationsComponent extends SnappstayBaseComponent implements OnInit {

    constructor(injector: Injector) {
        super(injector);
      }

    ngOnInit(): void {
    }

    getPropertyByCategory(name: string) {
        this.router.navigate(['/', 'grid-listings-left-sidebar']).then();
        const GetAllPropertyProfile: GetListingRequest = {
          pageNumber: 1,
          pageSize: 10,
          city: name,
          isLuxury : this.IsLuxury,
        };
        this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile))
        this.getListings(this.store.selectSnapshot(SnappstayState.GetListingRequest));
      }

    sectionTitle = [
        {
            title: 'Discover Popular Destinations for Your Next Adventure',
            paragraph: 'Explore the world’s most captivating places for unforgettable journeys. From vibrant cities rich in culture to serene landscapes, these destinations promise extraordinary experiences and lasting memories. Start planning your future trips to these inspiring locations and discover the beauty and excitement that await!'
        }
    ]
    singleDestinationsBigBox = [
        {
            img: 'assets/img/destinations/destinations1.jpg',
            subTitle: 'THAILAND',
            title: 'Bangkok',
            link: 'grid-listings-left-sidebar'
        }
    ]
    singleDestinationsBox = [
        {
            img: 'assets/img/destinations/destinations2.jpg',
            subTitle: 'UNITED STATES',
            title: 'New York',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/destinations3.jpg',
            subTitle: 'JAPAN',
            title: 'Osaka',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/destinations4.jpg',
            subTitle: 'FRANCE',
            title: 'Paris',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/destinations5.jpg',
            subTitle: 'UNITED KINGDOM',
            title: 'London',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/destinations6.jpg',
            subTitle: 'ABUDABI',
            title: 'Dubai',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/destinations7.jpg',
            subTitle: 'AUSTRALIA',
            title: 'Sydney',
            link: 'grid-listings-left-sidebar'
        }
    ]

}