import { Component, Injector, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { SetIsLuxury } from 'src/app/state/snappstay.action';

@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss'],
  standalone: true,
  imports: [RouterModule],
})
export class FooterStyleTwoComponent
  extends SnappstayBaseComponent
  implements OnInit
{
  constructor(inject: Injector) {
    super(inject);
  }

  ngOnInit(): void {}

  moveToSnappystayLuxury() {
  this.store.dispatch(new SetIsLuxury(true));
   this.moveToMoreListing();
  }
}
