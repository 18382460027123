export class ZipCode {
    zip: string
    latitude: number
    longitude: number
    city: string
    state: string
    country: string
    constructor(zip: string, latitude: number, longitude: number, city: string, state: string, country: string) {
        this.zip = zip;
        this.latitude = latitude;
        this.longitude = longitude;
        this.city = city;
        this.state = state;
        this.country = country;
      }
  }