<app-dashboard-sidemenu></app-dashboard-sidemenu>

<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <style>
        .breadcrumb-title {
            color: #D2A65B;
        }

        .change-password-btn {
            background-color: #D2A65B;
            border-color: #D2A65B;
            font-weight: 500;
            width: 10%;
            color: #ffffff;
        }
    </style>
    <div class="breadcrumb-area">
        <h1>Change Password</h1>
    </div>
    <section class="listing-section">
        <div *ngIf="isChangePasswordModelOpen" class="modal fade loginRegisterModal show d-block" tabindex="-1"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <!-- <button type="button" class="close" (click)="closePopup()">
                        <i class='bx bx-x'></i>
                    </button> -->
                    <button type="button" class="close" (click)="closePopup()" style="position: absolute;
                      right: 0px;
                      top: 0px;
                      opacity: 1 !important;
                      visibility: visible !important;
                      background-color: #ffffff;
                      text-align: center;
                      text-shadow: unset;
                      border-radius: 50%;
                      font-size: 20px;
                      border: none;
                      padding: 0;
                      color: #161515;
                      padding: 0px 0 0;">
                          <i class='bx bx-x'></i>
                    </button>
                    <div class="modal-header d-flex justify-content-center align-items-center">
                        <h3 class="modal-title fw-bold">Change Password</h3>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <form [formGroup]="changePasswordForm">
                                <!-- Current Password -->
                                <div class="form-group row align-items-center mb-3">
                                    <label class="col-form-label col-sm-4">Current Password</label>
                                    <div class="col-sm-8">
                                        <input type="password" formControlName="currentPassword"
                                            placeholder="Enter Current Password" class="form-control" required>
                                        <div *ngIf="changePasswordForm.get('currentPassword')?.touched && changePasswordForm.get('currentPassword')?.hasError('required')"
                                            class="text-danger">
                                            Current Password is required.
                                        </div>
                                    </div>
                                </div>

                                <!-- New Password -->
                                <div class="form-group row align-items-center mb-3">
                                    <label class="col-form-label col-sm-4">New Password</label>
                                    <div class="col-sm-8">
                                        <input type="password" formControlName="newPassword"
                                            placeholder="Enter New Password" class="form-control" required>
                                        <div *ngIf="changePasswordForm.get('newPassword')?.touched && changePasswordForm.get('newPassword')?.hasError('required')"
                                            class="text-danger">
                                            New Password is required.
                                        </div>
                                    </div>
                                </div>

                                <!-- Confirm Password -->
                                <div class="form-group row align-items-center mb-3">
                                    <label class="col-form-label col-sm-4">Confirm Password</label>
                                    <div class="col-sm-8">
                                        <input type="password" formControlName="confirmPassword"
                                            placeholder="Enter Confirm Password" class="form-control" required>
                                        <div *ngIf="changePasswordForm.get('confirmPassword')?.touched && changePasswordForm.get('confirmPassword')?.hasError('required')"
                                            class="text-danger">
                                            Confirm Password is required.
                                        </div>
                                        <div *ngIf="changePasswordForm.errors?.passwordMismatch && changePasswordForm.get('confirmPassword')?.touched"
                                            class="text-danger">
                                            New Password and Confirm Password must match.
                                        </div>
                                    </div>
                                </div>

                                <!-- Submit Button -->
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center" style="padding: 0px 0px 0px 20px;">
                                        <button type="button" style=" background-color: #D2A65B; border-color: #D2A65B;"
                                            class="btn btn-primary custom-button"
                                            [disabled]="changePasswordForm.invalid" (click)="submitForm()">
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>

    </section>
</div>
