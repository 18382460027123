import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { loadStripe } from '@stripe/stripe-js';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { HttpClient } from '@angular/common/http';
import { CountryNames } from 'src/app/models/lookup/country-names';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BookingRequest } from 'src/app/models/request-models/booking-request';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { LuxonDatePipe } from 'src/app/utils/pipes';
import { StripeRequest } from 'src/app/models/request-models/stripe-request';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NavbarStyleTwoComponent,
    SelectDropDownModule,
    FormsModule,
    FooterStyleTwoComponent,
    MatAutocompleteModule,
    MatInputModule,
    ScrollingModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AsyncPipe,
    LuxonDatePipe,
  ],
})
export class CheckoutComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  stripePromise = loadStripe(this.environmentVar.stripKey);
  //stripePromise = loadStripe('pk_live_51MFRKsBBTF7wRIq2MzGoQbPDrBxPEMdK5KhPPZHtEwR54OnTugYl3aPtohAo9MrtuBYp5YCuz50Oo5U0aLXaCfGo00zyhT6yE9');
  profileForm: FormGroup;
  filteredCountries: CountryNames[] = [];
  bookingRequest: BookingRequest | undefined;
  constructor(
    injector: Injector,
    private http: HttpClient,
    private fb: FormBuilder
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.resetOption = [this.options[0]];
    this.profileFormSub();
    this.bookingRequestSub();
  }

  bookingRequestSub() {
    const bookingSub = this.store
      .select(SnappstayState.GetBookingRequest)
      .subscribe((value: BookingRequest) => {
        if (value) {
          this.bookingRequest = value;
        }
      });
    this.subscriptions.push(bookingSub);
  }

  pay() {
    if (this.profileForm.valid) {
      if (this.profileForm.controls['paymentMethod'].value == 'PayPal') {
        this.createPayment(
          this.bookingRequest.totalPrice,
          this.bookingRequest.id
        );
      } else if (
        this.profileForm.controls['paymentMethod'].value == 'Credit Card'
      ) {
        const stripeRequest: StripeRequest = {
          customerEmail: this.store.selectSnapshot(
            SnappstayState.GetUserProfile
          ).email,
          amount: this.bookingRequest.totalPrice,
          bookingId: this.bookingRequest.id,
          currency: 'usd',
        };
        this.checkout(stripeRequest);
      }
    }
  }

  profileFormSub() {
    this.profileForm = this.fb.group({
      paymentMethod: ['', Validators.required],
    });
  }

  displayCountry(country: any): string {
    return country && country.name ? country.name : '';
  }

  pageTitleContent = [
    {
      title: 'Checkout',
      backgroundImage: 'assets/img/banner-img1.png',
    },
  ];

  // Country Select
  singleSelect: any = [];
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  resetOption: any;

  config: any = {
    displayKey: 'name',
    search: true,
  };
  options = [
    {
      name: 'United Arab Emirates',
    },
    {
      name: 'China',
    },
    {
      name: 'United Kingdom',
    },
    {
      name: 'Germany',
    },
    {
      name: 'France',
    },
    {
      name: 'Japan',
    },
  ];
  searchChange($event) {
    console.log($event);
  }
  reset() {
    this.resetOption = [];
  }

  async checkout(request: StripeRequest) {
    const stripe = await this.stripePromise;
    // Create Checkout Session
    this.http
      .post(this.environmentVar.paymentUrl + 'stripe/create-checkout-session', {
        customerEmail: request.customerEmail,
        amount: request.amount, // Amount in smallest currency unit (e.g., cents)
        currency: request.currency,
        bookingId: request.bookingId,
      })
      .subscribe((session: any) => {
        stripe?.redirectToCheckout({ sessionId: session.sessionId });
      });
  }

  // Call your API to create a PayPal payment and redirect to PayPal
  createPayment(amount: number, bookigId: number) {
    this.http
      .post<any>(this.environmentVar.paymentUrl + 'paypal/create-payment', {
        amount: amount,
        bookigId: bookigId,
        currency: 'usd',
        customerEmail: this.store.selectSnapshot(SnappstayState.GetUserProfile)
          .email,
      })
      .subscribe((response) => {
        // Redirect the user to PayPal approval URL
        window.location.href = response.approvalUrl;
      });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
