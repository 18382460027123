import { CommonModule } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';

import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { RouterModule } from '@angular/router';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { Select } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';
import { SnappstayState } from 'src/app/state/snappstay.state';
import {
  GetPropertyRequest,
  SetBookingRequest,
  SetSingleListing,
} from 'src/app/state/snappstay.action';
import { SinglePropertyResponse } from 'src/app/models/listing-model/single-property.model';
import { Review } from 'src/app/models/listing-model/review';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreateReviewRequest } from 'src/app/models/request-models/create-review-request';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { StarRatingModule } from 'angular-star-rating';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { BookingRequest } from 'src/app/models/request-models/booking-request';
import { BookingApiService } from 'src/app/services/api-services/booking-api';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-listings-details',
  templateUrl: './listings-details.component.html',
  styleUrls: ['./listings-details.component.scss'],
  imports: [
    CarouselModule,
    CommonModule,
    NavbarStyleTwoComponent,
    FooterStyleTwoComponent,
    ReactiveFormsModule,
    StarRatingModule,
    RouterModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  standalone: true,
})
export class ListingsDetailsComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  @Select(SnappstayState.SingleListing)
  singleListing$!: Observable<SinglePropertyResponse>;

  @Select(SnappstayState.GetReview)
  propertyReviews$!: Observable<Review[]>;

  singleListing: SinglePropertyResponse;
  reviewFormGroup!: FormGroup;
  isAddReview: boolean = false;
  stars: number[] = [1, 2, 3, 4, 5];
  propertyProfileId: number | undefined;
  singleListingsBox: any[] = [];
  bookingForm: FormGroup;
  today: string;
  propertyCarousel = false;


  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private bookingApiService: BookingApiService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.initLoginFormGroup();
    this.bookNowFormInit();
    this.listingsSub();
  }
  listingsSub() {
    const listingSub = this.listings$.subscribe((item) => {
      if (item) {
        this.singleListingsBox = [];
        item.result.forEach((property: ListingResponse) => {
          if (property.id != this.propertyProfileId) {
            const images: any[] = [];
            if (property.propertyPicture) {
              property.propertyPicture.forEach((image) => {
                images.push({
                  img: `${this.environmentVar.blobUrl}` + image.path,
                });
              });
            }
            const newList = {
              id: property.id,
              mainImg: images,
              categoryLink: 'single-listings',
              category: property.propertyCategory,
              bookmarkLink: 'single-listings',
              location: `${property.city}-${property.country}`,
              title: property.title,
              price: property.pricePerNight,
              detailsLink: 'single-listings',
              authorImg: '',
              openORclose: 'Open Now',
              extraClass: 'status-open',
              authorName: '',
              rating: [],
              ratingCount: '18',
            };
            for (let i = 0; i < property.starRatings; i++) {
              newList.rating.push({
                icon: 'bx bxs-star',
              });
            }
            this.singleListingsBox.push(newList);
          }
        });
      }
    });
    this.subscriptions.push(listingSub);
  }

  initLoginFormGroup() {
    this.reviewFormGroup = this.fb.group({
      email: [null, [Validators.required]],
      name: [null, [Validators.required]],
      comment: [null, [Validators.required]],
      ratings: [null, [Validators.required]],
    });
  }

  bookNowFormInit() {
    this.bookingForm = this.fb.group({
      checkin: ['', Validators.required],
      checkout: ['', Validators.required],
      guests: [null, [Validators.required, Validators.min(1)]],
    });
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }
  subscribeToRouteParams(): void {
    const getJobId = this.activatedRoute.paramMap.subscribe((params: any) => {
      if (params.has('id')) {
        this.propertyProfileId = params.params.id;
        this.getPropertyProfileById(this.propertyProfileId).then((x) => {
          this.subscribeToSingleListing();
          const GetAllPropertyProfile: GetListingRequest = {
            pageNumber: 1,
            pageSize: 10,
            city: this.singleListing.location.city,
            isActive: true,
            isLuxury : this.IsLuxury,
          };
          this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
          this.getListings(
            this.store.selectSnapshot(SnappstayState.GetListingRequest)
          );
        });
      }
    });
    this.subscriptions.push(getJobId);
  }

  moveToSingleListing(routeLink: any, id: number) {
    this.getPropertyProfileById(id).then((x) => {
      this.subscribeToSingleListing();
      const GetAllPropertyProfile: GetListingRequest = {
        pageNumber: 1,
        pageSize: 10,
        city: this.singleListing.location.city,
        isLuxury : this.IsLuxury,
      };
      this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
      this.getListings(
        this.store.selectSnapshot(SnappstayState.GetListingRequest)
      );
    });
  }
  rate(star: number) {
    this.reviewFormGroup.controls['ratings']?.setValue(star);
  }

  onSubmit() {
    if (this.reviewFormGroup.valid) {
      this.addReview(this.reviewFormGroup.getRawValue());
    }
  }

  async addReview(request: any) {
    const createReviewRequest: CreateReviewRequest = {
      comment: request.comment,
      rating: request.ratings,
      reviewerId: this.store.selectSnapshot(SnappstayState.GetUserProfile).id,
      propertyProfileId: this.propertyProfileId,
    };
    const formData = new FormData();
    formData.append(
      'obj',
      JSON.stringify(createReviewRequest).toString() ?? ''
    );
    try {
      const createReview$ = this.reviewApiService.CreateReview(formData);
      const createReview = await lastValueFrom(createReview$, {
        defaultValue: undefined,
      });
      if (createReview) {
        this.toastr.success('Review added successfully');
        this.getPropertyProfileById(this.propertyProfileId).then((x) => {
          this.subscribeToSingleListing();
        });
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  async bookingReservation(request: BookingRequest) {
    try {
      const bookingRequest$ =
        this.bookingApiService.bookingReservation(request);
      const bookingRequest = await lastValueFrom(bookingRequest$, {
        defaultValue: undefined,
      });
      if (bookingRequest) {
        this.store.dispatch(new SetBookingRequest(bookingRequest));
        this.router.navigate(['/', 'checkout']).then();
      }
    } catch (e: any) {
      this.store.dispatch(new SetBookingRequest(undefined));
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }
  moveToBooking() {
    if (this.bookingForm.valid) {
      const bookingValues = this.bookingForm.getRawValue();
      const bookingRequest: BookingRequest = {
        checkInDate: new Date(bookingValues.checkin),
        checkOutDate: new Date(bookingValues.checkout),
        numberOfGuests: bookingValues.guests,
        bookingStatus: 'Pending',
        pricePerNight: this.singleListing.pricePerNight,
        totalPriceAfterTax: this.calculateTotalPrice(
          bookingValues.checkin,
          bookingValues.checkout
        ),
        totalPrice : this.calculateTotalPrice(
          bookingValues.checkin,
          bookingValues.checkout
        ),
        propertyProfileId: this.propertyProfileId,
        guestId : this.store.selectSnapshot(SnappstayState.GetUserProfile).id
      };
      this.bookingReservation(bookingRequest).then(x => {

      });
    }
  }
  calculateTotalPrice(checkin: string, checkout: string): number {
    const checkInDate = new Date(checkin);
    const checkOutDate = new Date(checkout);
    const timeDifference = checkOutDate.getTime() - checkInDate.getTime();
    const numberOfNights = timeDifference / (1000 * 3600 * 24);
    return numberOfNights * this.singleListing.pricePerNight;
  }
  subscribeToSingleListing(): void {
    const singleListingSub = this.singleListing$.subscribe((value) => {
      if (value) {
        this.singleListing = value;
        this.singleListing.ownerProfilePicUrl = `${this.environmentVar.blobUrl}` +this.singleListing.ownerProfilePicUrl
      }
    });
    this.subscriptions.push(singleListingSub);
  }

  getCoverImg() {
    return (
      this.environmentVar.blobUrl +
      this.singleListing.propertyPicture.at(0).path
    );
  }

  getFormattedOpeningHoursForToday(): string {
    const currentDay = new Date().getDay();

    const todayHours = this.singleListing.propertyTimingInfo.find(
      (hours) => hours.dayOfWeek === currentDay
    );

    if (!todayHours) {
      return 'Closed';
    }

    function formatTime(time: string): string {
      const [hour, minute] = time.split(':').map(Number);
      const isPM = hour >= 12;
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      const ampm = isPM ? 'PM' : 'AM';
      return `${formattedHour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')} ${ampm}`;
    }

    const openTimeFormatted = formatTime(todayHours.openTime);
    const closeTimeFormatted = formatTime(todayHours.closeTime);

    return `${openTimeFormatted} - ${closeTimeFormatted}`;
  }

  getLosation(): string {
    return `${this.singleListing.location.city} - ${this.singleListing.location.country}`;
  }

  galleryOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
    },
  };
  singleImageBox = [
    {
      img: 'assets/img/gallery/gallery1.jpg',
    },
    {
      img: 'assets/img/gallery/gallery2.jpg',
    },
    {
      img: 'assets/img/gallery/gallery3.jpg',
    },
    {
      img: 'assets/img/gallery/gallery4.jpg',
    },
    {
      img: 'assets/img/gallery/gallery5.jpg',
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplayHoverPause: true,
    autoplay: true,
    mouseDrag: false,
    items: 1,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
  };

  // Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  openAddReview() {
    this.isAddReview = !this.isAddReview;
  }

  onOpenSlider() {
    this.propertyCarousel = true;
  }

  closePopup() {
    this.propertyCarousel = !this.propertyCarousel;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(new SetSingleListing(undefined));
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
