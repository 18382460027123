import { Component } from '@angular/core';
import { NavbarStyleTwoComponent } from '../navbar-style-two/navbar-style-two.component';

@Component({
  selector: 'app-stripe-cancel',
  standalone: true,
  imports: [NavbarStyleTwoComponent],
  templateUrl: './stripe-cancel.component.html',
  styleUrl: './stripe-cancel.component.scss'
})
export class StripeCancelComponent {

}
