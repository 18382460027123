<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <!-- <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li> -->
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="visitor-reviews-box">
                <!-- Check if there are reviews, otherwise show empty state -->
                <div *ngIf="singleFeedbackBox.length > 0; else noReviews">
                    <div class="user-review" *ngFor="let userReview of singleFeedbackBox | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }">
                        <img [src]="userReview.userImage" class="user" alt="User Image">
                        <div class="review-rating">
                            <div class="review-stars">
                                <i [class]="Star.icon" *ngFor="let Star of userReview.rating"></i>
                            </div>
                            <span class="d-inline-block">{{userReview.userName}} on <a>{{userReview.listingsName}}</a></span>
                        </div>
                        <span class="date"><i class="bx bx-calendar"></i> {{userReview.date | luxonDate: 'yyyy-MM-dd'}}</span>
                        <p>{{userReview.text}}</p>
                    </div>
                </div>
    
                <!-- Empty State -->
                <ng-template #noReviews>
                    <div class="empty-state"
                        style="display: flex; justify-content: center; align-items: center; min-height: 65vh; width: 100%;">
                        <img src="assets/images/no-data.png" alt="No Reviews" class="no-data-image">
                    </div>
                </ng-template>
            </div>
    
            <!-- Pagination Controls -->
            <div class="pagination-area text-center">
                <pagination-controls id="server" (pageChange)="onPageChange($event)"
                    itemsPerPage="pageSize" currentPage="currentPage" totalItems="totalRecords">
                </pagination-controls>
            </div>
        </div>
    </div>
    
    
    <!-- End -->

    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->