import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { lastValueFrom } from 'rxjs';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { NavbarStyleTwoComponent } from 'src/app/components/common/navbar-style-two/navbar-style-two.component';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { Payout } from 'src/app/models/payout.model';
import { ChangePayoutStatusRequest } from 'src/app/models/request-models/change-payout-status-request';
import { PaginationFilterInput } from 'src/app/models/request-models/pagination-review-response';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { SetPayouts } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-dashboard-payouts',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    NavbarStyleTwoComponent,
    DashboardSidemenuComponent,
    DashboardNavbarComponent,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './dashboard-payouts.component.html',
  styleUrl: './dashboard-payouts.component.scss',
})
export class DashboardPayoutsComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  totalRecords = 0;
  pageSize = 10;
  currentPage = 1;
  sortField = 'fullName';
  sortDirection = 'asc';
  filter = '';
  showContextMenu: boolean = false;
  payouts: Payout[] = [];
  payoutForm: FormGroup;
  isPayoutModelOpen: boolean = false;
  uploadReceiptModelOpen: boolean = false;
  selectedReceiptId: number | undefined;
  totalBalance: number = 0;
  userName: string;
  selectedFile: File | null = null; // Single file instead of an array
  backendImage: string | null = null;
  imageUrl: string | null = null;

  @ViewChild('fileInput') fileInput!: ElementRef;
  constructor(
    inject: Injector,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super(inject);
  }

  ngOnInit(): void {
    this.hostRemainingWalletBalance();
    this.initPayoutFormGroup();
    this.userName = this.store.selectSnapshot(
      SnappstayState.GetUserProfile
    ).fullName;
    this.currentPage = 1;
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: 'Id',
      search: this.filter,
    };
    this.role == 'Host'
      ? this.GetAllPayout(request)
      : this.getAllAdminPayout(request);
    this.SetAllUser();
  }

  initPayoutFormGroup() {
    this.payoutForm = this.fb.group({
      amount: [
        null,
        [Validators.required, this.validateAmount(this.totalBalance)],
      ],
    });
  }
  validateAmount(totalBalance: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const amount = control.value;
      if (amount !== null && amount > totalBalance) {
        return { amountExceedsBalance: true }; // Custom error
      }
      return null; // No error
    };
  }
  SetAllUser() {
    const data = this.store
      .select(SnappstayState.GetPayouts)
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.payouts = [];
          this.payouts = value;
        } else {
          this.payouts.push({
            id: 1,
            userId: 1,
            payoutRequesterName: 'John Doe',
            phoneNumber: '123-456-7890',
            email: 'johndoe@example.com',
            amount: 100.0,
            receiptUrl:
              'https://snappstay.blob.core.windows.net/media-file…d5ad3ed-bcbd-48be-a086-772546ad57cb%20-%20aws.png',
            status: 'Pending',
            remarks: 'First payout request',
            showMenu: false,
          });
        }
      });

    this.subscriptions.push(data);
  }

  applyFilter(event: Event) {
    this.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.currentPage = 1;
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: 'Id',
      search: this.filter,
    };
    this.role == 'Host'
      ? this.GetAllPayout(request)
      : this.getAllAdminPayout(request);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: 'Id',
    };
    this.role == 'Host'
      ? this.GetAllPayout(request)
      : this.getAllAdminPayout(request);
  }

  onSortChange(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: this.sortField,
      sortOrder: this.sortDirection,
    };
    this.role == 'Host'
      ? this.GetAllPayout(request)
      : this.getAllAdminPayout(request);
  }

  async GetAllPayout(request: PaginationFilterInput) {
    try {
      const allUser$ = this.payoutsApiService.getAllPayout(request);
      const allUser = await lastValueFrom(allUser$, {
        defaultValue: undefined,
      });
      if (allUser) {
        this.store.dispatch(new SetPayouts(allUser));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetPayouts(undefined));
    } finally {
    }
  }

  async hostRemainingWalletBalance() {
    try {
      const totalBalance$ = this.walletApiService.hostRemainingWalletBalance();
      const totalBalance = await lastValueFrom(totalBalance$, {
        defaultValue: undefined,
      });
      if (totalBalance) {
        this.totalBalance = totalBalance;
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        this.totalBalance = 0;
      }
    } finally {
    }
  }

  async getAllAdminPayout(request: PaginationFilterInput) {
    try {
      const allUser$ = this.payoutsApiService.getAllAdminPayout(request);
      const allUser = await lastValueFrom(allUser$, {
        defaultValue: undefined,
      });
      if (allUser) {
        this.store.dispatch(new SetPayouts(allUser));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetPayouts(undefined));
    } finally {
    }
  }

  downloadReceipt(url: string, filename: string) {
    this.payoutsApiService.downloadRecepit(url, filename);
  }

  createPayoutRequest() {
    if (this.payoutForm.valid) {
      const request: Payout = {
        amount: this.payoutForm.controls['amount'].value,
      };
      this.createPayout(request).then((x) => {
        this.closePopup();
        const request: PaginationFilterInput = {
          pageNumber: this.currentPage,
          pageSize: 10,
          sortColumn: 'Id',
        };
        this.GetAllPayout(request);
      });
    }
  }

  rejectPayout(id: number) {
    this.selectedReceiptId = id;
    this.changePayoutStatus('Cancelled');
  }

  async changePayoutStatus(status: string): Promise<void> {
    const request: ChangePayoutStatusRequest = {
      id: this.selectedReceiptId,
      status: status,
    };
    if (this.selectedFile) {
      try {
        const payout$ = this.payoutsApiService.changePayoutStatus(
          this.selectedFile,
          request
        );
        const payout = (await lastValueFrom(payout$, {
          defaultValue: undefined,
        })) as ApiResponse<boolean>;
        if (payout.successful) {
          if (status == 'Cancelled') {
            this.toastr.success('Payout cancelled successfully', '', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            this.closeReceiptPopup();
          } else {
            this.toastr.success('Receipt Uploaded successfully', '', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            this.closeReceiptPopup();
          }
        } else {
          this.toastr.error(payout.message, 'Please try again later', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.closeReceiptPopup();
        }
      } catch (e: any) {
        this.closeReceiptPopup();
        const message =
          e?.error?.message ||
          e?.error?.errors[0] ||
          ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        this.store.dispatch(new SetPayouts(undefined));
      }
    }
  }

  async createPayout(request: Payout) {
    try {
      const payout$ = this.payoutsApiService.createPayout(request);
      const payout = (await lastValueFrom(payout$, {
        defaultValue: undefined,
      })) as ApiResponse<Payout>;
      if (payout.successful) {
        this.toastr.success(
          'Payout request submitted successfully',
          'Please wait for the admin approval',
          {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          }
        );
        this.closePopup();
      } else {
        this.toastr.error(payout.message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetPayouts(undefined));
    } finally {
    }
  }

  onContextMenu(action: string) {
    // Handle context menu actions like 'view', 'edit', 'delete'
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.imageUrl = URL.createObjectURL(this.selectedFile);
    }
  }

  removeFile(): void {
    this.selectedFile = null;
    this.imageUrl = null; // Clear the URL if necessary
    this.fileInput.nativeElement.value = ''; // Reset the file input
    this.cdr.detectChanges(); // Trigger change detection
  }

  createImageUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  convertBackendUrlToFile(imageUrl: string) {
    this.urlToFile(imageUrl).then((file) => {
      this.selectedFile = file;
    });
  }

  async urlToFile(imageUrl: string): Promise<File> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const fileName = imageUrl.split('/').pop() || 'image.jpg';
    return new File([blob], fileName, { type: blob.type });
  }

  removeBackendImage(): void {
    this.backendImage = null;
  }
  closeReceiptPopup() {
    this.uploadReceiptModelOpen = !this.uploadReceiptModelOpen;
    this.selectedReceiptId = null;
    this.selectedFile = null;
    this.backendImage = null;
  }
  uploadReceipt(id: number): void {
    this.uploadReceiptModelOpen = true;
    this.selectedReceiptId = id;
  }
  OpenPopUp() {
    this.isPayoutModelOpen = true;
  }
  closePopup() {
    this.isPayoutModelOpen = !this.isPayoutModelOpen;
    this.payoutForm.controls['amount'].patchValue(null);
    this.payoutForm.markAsUntouched();
  }

  toggleContextMenu(payout: any): void {
    this.payouts.forEach((p) => (p.showMenu = false));
    payout.showMenu = !payout.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.context-menu') && !target.closest('.context-btn')) {
      this.payouts.forEach((payout) => (payout.showMenu = false));
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());}
}
