import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpDefaultInterceptor implements HttpInterceptor {
    
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    const request = req.clone({
      setHeaders: {
        accept: 'application/json',
      },
    });

    return next.handle(request);
  }
}