import { Component } from '@angular/core';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-terms-of-service',
  standalone: true,
  imports: [FooterStyleTwoComponent,  CommonModule, NavbarStyleTwoComponent],
  templateUrl: './terms-of-service.component.html',
  styleUrl: './terms-of-service.component.scss'
})
export class TermsOfServiceComponent {
  ngOnInit(): void {
  }
  
  pageTitleContent = [
      {
          title: 'Terms Of Services',
          backgroundImage: 'assets/images/terms-of-services.webp'
      }
  ]
  aboutContent = [
      {
          title: 'How We Were Established',
          subTitle: 'Check video presentation to find out more about us.',
          paragraph: [
              {
                  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
              },
              {
                  text: "Every month they moved their money the old way – which wasted their time and money. So they invented a beautifully simple workaround that became a billion-dollar business."
              }
          ]
      }
  ]
  aboutImage = [
      {
          img: 'assets/img/about-img.jpg',
          videoLink: 'https://www.youtube.com/watch?v=bk7McNUjWgw'
      }
  ]
}
